import * as actions from "../../actions/upload";

const initialState = {
	loading: false,
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.UPLOAD:
			return {
				...state,
				loading: true,
				errors: {}
			};
		case actions.UPLOAD_SUCCESS:
			return {
				...state,
				loading: false
			};
		case actions.UPLOAD_ERROR:
			return {
				...state,
				loading: false,
				errors: payload
			};
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
