import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const CREATE_CLIENTS = "[CLIENTS CREATE] CREATE_CLIENTS";
export const CREATE_CLIENTS_ERROR = "[CLIENTS CREATE] CREATE_CLIENTS_ERROR";
export const CREATE_CLIENTS_SUCCESS = "[CLIENTS CREATE] CREATE_CLIENTS_SUCCESS";

export const SET_VALUE = "[CLIENTS CREATE] SET_VALUE";
export const CLEAR_VALUES = "[CLIENTS CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createCompany(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_CLIENTS
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/company`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_CLIENTS_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: CREATE_CLIENTS_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error, null);

			return dispatch({
				type: CREATE_CLIENTS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
