import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import login from "./login";
import notification from "./notification";
import theme from "./theme";
import sidebar from "./sidebar";

import users from "containers/User/containers/Report/reducer";
import userCreate from "containers/User/containers/Create/reducer";
import userDetails from "containers/User/containers/Detail/reducer";
import changePassword from "containers/ChangePassword/reducer";

import company from "containers/Company/containers/Report/reducer";
import companyCreate from "containers/Company/containers/Create/reducer";
import companyDetails from "containers/Company/containers/Detail/reducer";

import serviceProvider from "containers/ServiceProvider/containers/Report/reducer";
import serviceProviderCreate from "containers/ServiceProvider/containers/Create/reducer";
import serviceProviderDetails from "containers/ServiceProvider/containers/Detail/reducer";

import builder from "containers/Builder/containers/Report/reducer";
import builderDetails from "containers/Builder/containers/Detail/reducer";

import news from "containers/News/containers/Report/reducer";
import newsCreate from "containers/News/containers/Create/reducer";
import newsDetails from "containers/News/containers/Detail/reducer";

import event from "containers/Events/containers/Report/reducer";
import eventCreate from "containers/Events/containers/Create/reducer";
import eventDetails from "containers/Events/containers/Detail/reducer";

import upload from "./upload";

import lead from "containers/Lead/containers/Report/reducer";
import leadFiltered from "containers/User/containers/Detail/Report/reducer";

import leadDetails from "containers/Lead/containers/Detail/reducer";

import productCategories from "containers/ProductCategory/containers/Report/reducer";
import productCategoryCreate from "containers/ProductCategory/containers/Create/reducer";
import productCategoryEdit from "containers/ProductCategory/containers/Edit/reducer";

import products from "containers/Product/containers/Report/reducer";
import productDetails from "containers/Product/containers/Detail/reducer";
import productCreate from "containers/Product/containers/Create/reducer";

import budget from "containers/Lead/containers/Detail/ReportBudget/reducer";
import budgetCreate from "containers/Lead/containers/Create/reducer";
import budgetDetails from "containers/Budgets/containers/Detail/reducer";

import transaction from "containers/Transaction/containers/Report/reducer";
import transactionDetails from "containers/Transaction/containers/Detail/reducer";

import cashback from "containers/Cashback/containers/Report/reducer";
import cashbackCreate from "containers/Cashback/containers/Create/reducer";
import cashbackDetails from "containers/Cashback/containers/Detail/reducer";

import video from "containers/Courses/containers/Report/reducer";
import videoCreate from "containers/Courses/containers/Create/reducer";
import videoEdit from "containers/Courses/containers/Report/reducer";

import consumption from "containers/Builder/containers/Consumption/reducer";
import consumptionCreate from "containers/Builder/containers/Consumption/Create/reducer";

import consumptionItem from "containers/Consumption/containers/reducer";
import consumptionItemCreate from "containers/Consumption/containers/Create/reducer";

import attendeeEvent from "containers/Events/containers/Detail/ReportAttendeeEvent/reducer";

import lane from "containers/Lead/containers/Kanban/reducer";

import dashboard from "containers/Dashboard/reducer";

import register from "containers/Register/reducer";
import { USER_LOGGED_OUT } from "redux/actions/login";

const appReducer = combineReducers({
	form,
	attendeeEvent,
	budget,
	budgetCreate,
	budgetDetails,
	builder,
	builderDetails,
	cashback,
	cashbackCreate,
	cashbackDetails,
	changePassword,
	company,
	companyCreate,
	companyDetails,
	dashboard,
	event,
	eventCreate,
	eventDetails,
	lane,
	lead,
	leadDetails,
	leadFiltered,
	login,
	news,
	newsCreate,
	newsDetails,
	notification,
	productCategories,
	productCategoryCreate,
	productCategoryEdit,
	productCreate,
	productDetails,
	products,
	register,
	serviceProvider,
	serviceProviderCreate,
	serviceProviderDetails,
	sidebar,
	theme,
	transaction,
	transactionDetails,
	upload,
	userCreate,
	userDetails,
	users,
	video,
	videoCreate,
	videoEdit,
	consumption,
	consumptionCreate,
	consumptionItem,
	consumptionItemCreate
});

const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === USER_LOGGED_OUT) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
