import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { MdClose } from "react-icons/md";

const GlobalModal = ({ title, toggle, isOpen, children, className, size }) => {
	const closeButton = (
		<Button color="danger" size="sm">
			<MdClose color="#000" onClick={toggle} />
		</Button>
	);

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			className={
				className ? className : "theme-light ltr-support login-modal-form"
			}
			size={size ? size : "md"}
		>
			<ModalHeader close={closeButton}>{title}</ModalHeader>
			<ModalBody>{children}</ModalBody>
		</Modal>
	);
};

export default GlobalModal;
