/* eslint-disable no-undef */
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal";
import EditForm from "../../components/EditForm";
import { upload } from "redux/actions/upload";
import { editEvent, setValue } from "../Report/actions";
import eventTypeOptions from "models/eventTypeOptions";
import ls from "Localization";

function Edit({ load }) {
	const dispatch = useDispatch();

	const { loading, item, showEditForm } = useSelector(s => s.event);

	if (!item) return null;

	const onSubmit = async values => {
		const data = {
			...values,
			title: values.title,
			description: values.description,
			video: values.video,
			when: values.when,
			until: values.until,
			capacity: values.capacity,
			type: values.type?.value,
			bannerFile: undefined
		};

		if (values.bannerFile && values.bannerFile[0]) {
			try {
				data.banner = await new Promise((res, rej) =>
					dispatch(
						upload("event", values.bannerFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				return;
			}
		}

		dispatch(
			editEvent(item._id, data, err => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					closeModal();
					load && load();
				}
			})
		);
	};

	const closeModal = () => {
		dispatch(setValue({ showEditForm: false }));
	};

	const typeOptions = useMemo(
		() =>
			Object.keys(eventTypeOptions).map(c => ({
				value: c,
				label: ls[eventTypeOptions[c]]
			})),
		[]
	);

	const initialValues = {
		title: item.title,
		description: item.description,
		banner: item.banner,
		video: item.video,
		when: item.when,
		until: item.until,
		capacity: item.capacity,
		type: typeOptions.find(c => c.value === item.type)
	};

	return (
		<Modal toggle={closeModal} isOpen={showEditForm} size="xl" title="Editar">
			<EditForm
				closeModal={closeModal}
				initialValues={initialValues}
				typeOptions={typeOptions}
				onSubmit={onSubmit}
				loading={loading}
			/>
		</Modal>
	);
}

export default Edit;
