import React from "react";
import { Route, Switch } from "react-router-dom";
import Report from "./containers";
import CreateConsumption from "./containers/Create";
const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/consumption/item" component={Report} />
				<Route
					exact
					path="/consumption/item/new"
					component={CreateConsumption}
				/>
			</Switch>
		</React.Fragment>
	);
};

export default routes;
