import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Card, CardBody, ButtonToolbar, ButtonGroup, Button } from "reactstrap";

import { FaRegFileExcel } from "react-icons/fa";

import { exportExcel } from "./export";
import TooltipComponent from "components/TooltipComponent";
import { useHistory } from "react-router-dom";

function Report({
	title,
	data,
	columns,
	tableRef,
	onRowClicked,
	headerRightComponent,
	hideExport,
	// visibleColumns,
	...rest
}) {
	const table = useRef(null);
	const [rColumns] = useState(columns);
	const [exportComponent, setExport] = useState(null);
	const history = useHistory()


	const handleExport = () => {
		exportExcel(tableRef || table, rColumns, title, setExport);
	};

	// const handleRouteAddVideos = () => {
	// 	history.push("/courses/create");
	// };


	const renderHeaderRightComponent = () => {

		let component = hideExport ? null : (
			<>
				<Button color="export" onClick={handleExport} id="export">
					<FaRegFileExcel style={{ margin: 0 }} />
				</Button>
				<TooltipComponent title="Exportar" target="export" />

				{/* <Button color="export" onClick={handleRouteAddVideos} id="add">
					<IoMdAdd style={{ margin: 0 }} />
				</Button>
				<TooltipComponent title="Adicionar vídeos" target="add" /> */}
			</>
		);

		if (headerRightComponent) {
			component = (
				<ButtonGroup className="btn-group--icons" dir="ltr">
					{headerRightComponent}
					{component}
				</ButtonGroup>
			);
		}

		return (
			<div
				style={{
					alignItems: "center",
					display: "flex",
					marginBottom: 9
				}}
			>
				<ButtonToolbar>{component}</ButtonToolbar>
			</div>
		);
	};

	return (
		<Card>
			<CardBody style={{ padding: 0 }}>
				<div
					className="card__title"
					style={{
						marginBottom: 0,
						padding: 16,
						justifyContent: "space-between"
					}}
				>
					<h5 className="bold-text">{title || "Relatório"}</h5>
					<div style={{ display: "flex", alignItems: "flex-end" }}>
						{renderHeaderRightComponent()}
					</div>
				</div>
				<ReactTable
					className="-striped -highlight"
					data={data}
					ref={tableRef || table}
					columns={columns}
					defaultPageSize={15}
					pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
					getTdProps={(state, rowInfo, column) => {
						return {
							style: {
								display: "flex",
								alignItems: "center",
								cursor:
									onRowClicked && !(column && column.id === "actions")
										? "pointer"
										: undefined
							},
							onClick: (e, handleOriginal) => {
								if (rowInfo && !(column && column.id === "actions")) {
									if (onRowClicked) {
										onRowClicked(rowInfo.original);
									} else {
										handleOriginal && handleOriginal(e);
									}
								}
							}
						};
					}}
					{...rest}
				/>
				{exportComponent}
			</CardBody>
		</Card>
	);
}

Report.propTypes = {
	title: PropTypes.string,
	data: PropTypes.array,
	columns: PropTypes.array,
	tableRef: PropTypes.any
};

export default Report;
