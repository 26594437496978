/* eslint-disable no-undef */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal";
import { setValue } from "../../Report/actions";
import DetailsBuilder from "../../../../Builder/components/detail";

function Details() {
	const dispatch = useDispatch();

	const { item, showDetailForm, loading } = useSelector(s => s.company);

	if (!item) return null;

	const closeModal = () => {
		dispatch(setValue({ showDetailForm: false }));
	};

	return (
		<Modal toggle={closeModal} isOpen={showDetailForm} size="xl" title="">
			<DetailsBuilder item={item} loading={loading} />
		</Modal>
	);
}

export default Details;
