/* eslint-disable no-undef */
import validate from "containers/Company/components/validate";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal";
import { SubmissionError } from "redux-form";
import EditForm from "../../components/editForm";
import { editClient, setValue } from "../Report/actions";
import states from "assets/states.json";
import companyStatus from "models/companyStatus";
import ls from "Localization";
import { upload } from "redux/actions/upload";

function Edit({ load }) {
	const dispatch = useDispatch();

	const { loading, item, showEditForm } = useSelector(s => s.company);

	if (!item) return null;

	const onSubmit = async values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		const data = {
			...values,
			status: values.status.value,
			document: String(values.document || "")
				.match(/\d/g)
				.join(""),
			cashbackFee: String(values.cashbackFee || "0")
				.match(/\d/g)
				.join(""),
			phoneNumber: values.phoneNumber
				? `+55${values.phoneNumber.match(/\d/g).join("")}`
				: undefined,
			address: values.address
				? {
						...item.address,
						...values.address,
						state: values.address.state.value
				  }
				: null,
			logoFile: undefined,
			bannerFile: undefined,
			catalogFile: undefined
		};

		if (values.logoFile && values.logoFile[0]) {
			try {
				data.logo = await new Promise((res, rej) =>
					dispatch(
						upload("company", values.logoFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				alert(error);

				return;
			}
		}

		if (values.bannerFile && values.bannerFile[0]) {
			try {
				data.banner = await new Promise((res, rej) =>
					dispatch(
						upload("company", values.bannerFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				alert(error);

				return;
			}
		}

		if (values.catalogFile && values.catalogFile[0]) {
			try {
				data.catalog = await new Promise((res, rej) =>
					dispatch(
						upload("company", values.catalogFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				alert(error);

				return;
			}
		}

		dispatch(
			editClient(item._id, data, err => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					closeModal();
					load && load();
				}
			})
		);

	};

	const closeModal = () => {
		dispatch(setValue({ showEditForm: false }));
	};

	const statusOptions = Object.keys(companyStatus).map(c => ({
		value: c,
		label: ls[companyStatus[c]]
	}));

	const initialValues = {
		name: item.name,
		socialName: item.socialName,
		document: item.document,
		cashbackFee: item.cashbackFee ? item.cashbackFee / 100.0 : undefined,
		email: item.email,
		stand: item.stand,
		segment: item.segment,
		logo: item.logo,
		banner: item.banner,
		catalog: item.catalog,
		about: item.about,
		phoneNumber: item.nationalPhoneNumber,
		status: statusOptions.find(c => c.value === String(item.status)),
		address: item.address
			? {
					street: item.address.street,
					number: item.address.number,
					complement: item.address.complement,
					neighborhood: item.address.neighborhood,
					city: item.address.city,
					zipCode: item.address.zipCode,
					state: states.find(c => c.value === item.address.state)
			  }
			: undefined
	};

	return (
		<Modal
			toggle={closeModal}
			isOpen={showEditForm}
			size="xl"
			title="Editar Cliente"
		>
			<EditForm
				closeModal={closeModal}
				initialValues={initialValues}
				onSubmit={onSubmit}
				states={states}
				loading={loading}
				statusOptions={statusOptions}
			/>
		</Modal>
	);
}

export default Edit;
