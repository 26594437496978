import * as api from "../../../../../services/api";
import { getErrors } from "../../../../../helpers/error";

export const GET_ATTENDEE_EVENTS = "[ATTENDEE EVENT] GET_ATTENDEE_EVENTS";
export const GET_ATTENDEE_ERROR = "[ATTENDEE EVENT] GET_ATTENDEE_ERROR";
export const GET_ATTENDEE_SUCCESS = "[ATTENDEE EVENT] GET_ATTENDEE_SUCCESS";

export const SET_VALUE = "[ATTENDEE EVENT] SET_VALUE";
export const CLEAR_VALUES = "[ATTENDEE EVENT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getAttendeeEvent(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_ATTENDEE_EVENTS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/event/attendee?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_ATTENDEE_SUCCESS,
						payload: result.model
					});
				}

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_ATTENDEE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_ATTENDEE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
