import React, { /*useCallback*/ useRef } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import dayjs from "dayjs";
import ls from "Localization";
import { MdDelete, MdEdit, MdAdd } from "react-icons/md";
import TooltipComponent from "components/TooltipComponent";
import budgetStatus from "models/budgetStatus";
import { useHistory } from "react-router";
import Delete from "containers/Budgets/containers/Delete";
import Edit from "containers/Budgets/containers/Edit";
import { toStringCurrency } from "helpers/string";
import { useMemo } from "react";
import DateSelectFilter from "../../../../../components/Report/DateSelectorFilter";
import { isInRole } from "helpers/auth";

let fetchDataDebounced = null;

function Report({ user, setShowModal, showUser }) {
	const dispatch = useDispatch();
	const table = useRef(null);
	const history = useHistory();

	const state = useSelector(s => s.budget);
	const login = useSelector(s => s.login);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		if (user) {
			filtered.push({ id: "user", value: user._id });
		}

		let sort = sorted[0] || {};

		const createdAt = filtered.find(c => c.id === "createdAt");

		if (createdAt?.value) {
			filtered = filtered.filter(c => c.id !== "createdAt");

			if (createdAt?.value.startDate) {
				filtered.push({
					id: "startDate",
					value: createdAt.value.startDate
				});
			}

			if (createdAt?.value.endDate)
				filtered.push({
					id: "endDate",
					value: createdAt.value.endDate
				});
		}

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getBudget(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	const handleEditClick = item => {
		dispatch(
			actions.setValue({
				showEditForm: true,
				item
			})
		);
	};

	const handleDeleteClick = data => {
		dispatch(
			actions.setValue({
				showDeleteForm: data
			})
		);
	};

	const handleShowModal = () => {
		setShowModal(true);
	};

	const isAdmin = useMemo(() => {
		return isInRole(login.user, ["admin"]);
	}, [login.user]);

	const columns = useMemo(() => {
		return [
			{
				Header: "Data criação",
				id: "createdAt",
				accessor: c =>
					c.createdAt ? dayjs(c.createdAt).format(ls.dateTimeFormat) : "",
				width: 140,
				Filter: p => <DateSelectFilter {...p} />
			},
			showUser
				? {
						Header: "Usuário",
						id: "user",
						accessor: c => c.user?.fullName
				  }
				: null,
			isAdmin
				? {
						Header: "Expositor",
						accessor: d => (d.company ? d.company.name : "Não definido"),
						id: "company"
				  }
				: null,
			{
				Header: "Valor",
				id: "value",
				accessor: c => toStringCurrency(c.value / 100.0)
			},

			{
				Header: "Descrição",
				accessor: "description"
			},

			{
				Header: "Status",
				id: "status",
				accessor: c => ls[budgetStatus[c.status]],
				width: 140,
				Filter: ({ filter, onChange }) => {
					return (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter ? filter.value : "all"}
						>
							<option value="">Todos</option>
							{Object.keys(budgetStatus).map(status => (
								<option key={status} value={status}>
									{ls[budgetStatus[status]]}
								</option>
							))}
						</select>
					);
				}
			},
			{
				Header: "Ações",
				width: 80,
				filterable: false,
				id: "actions",
				Cell: props => {
					return (
						<ButtonToolbar>
							<ButtonGroup className="btn-group--icons" dir="ltr">
								<Button
									color="primary"
									onClick={() => handleEditClick(props.original)}
								>
									<MdEdit style={{ margin: 0 }} />
								</Button>

								<Button
									color="cancel"
									onClick={() => handleDeleteClick(props.original)}
								>
									<MdDelete style={{ margin: 0 }} />
								</Button>
							</ButtonGroup>
						</ButtonToolbar>
					);
				}
			}
		].filter(c => c);
	}, [showUser]);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Orçamentos"
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						onRowClicked={row => history.push(`/budget/` + row._id)}
						headerRightComponent={
							<>
								{history.location.pathname === "/budget" ? (
									""
								) : (
									<>
										<ButtonToolbar>
											<ButtonGroup className="btn-group--icons" dir="ltr">
												<Button
													color="primary"
													id="product-import"
													onClick={handleShowModal}
													size="md"
												>
													<MdAdd style={{ margin: 0 }} />
												</Button>
												<TooltipComponent
													title="Novo orçamento"
													target="product-import"
												/>
											</ButtonGroup>
										</ButtonToolbar>
									</>
								)}
							</>
						}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={columns}
					/>
				</Col>
			</Row>
			<Delete load={load} />
			<Edit load={load} />
		</Container>
	);
}

export default Report;
