import React, { useRef } from "react";
import Input from "../../../components/ReduxForm/Input";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import TextArea from "components/ReduxForm/TextArea";
import { MONEY } from "components/NumberFormat";
import renderSelectField from "shared/components/form/Select";
import renderCheckBoxField from "shared/components/form/CheckBox";

const editForm = ({
	categoriesOptions,
	handleSubmit,
	initialValues,
	reset,
	onChange,
	loading
}) => {
	const form = useRef(null);

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={4} md={3} lg={2} className="form__form-group">
						<span className="form__form-group-label">Código</span>
						<div className="form__form-group-field">
							<Field name="code" component={Input} type="text" />
						</div>
					</Col>
					<Col xs={12} sm={8} md={6} lg={5} className="form__form-group">
						<span className="form__form-group-label">Nome</span>
						<div className="form__form-group-field">
							<Field name="name" component={Input} type="text" />
						</div>
					</Col>
					<Col xs={12} sm={6} md={3} lg={2} className="form__form-group">
						<span className="form__form-group-label">Preço de Custo</span>
						<div className="form__form-group-field">
							<Field name="costPrice" component={MONEY} type="text" />
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Modelo</span>
						<div className="form__form-group-field">
							<Field name="modelProduct" component={Input} type="text" />
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Marca</span>
						<div className="form__form-group-field">
							<Field name="brand" component={Input} type="text" />
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Fabricante</span>
						<div className="form__form-group-field">
							<Field name="manufacturer" component={Input} type="text" />
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Origem</span>
						<div className="form__form-group-field">
							<Field name="origin" component={Input} type="text" />
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Código do Inmetro</span>
						<div className="form__form-group-field">
							<Field name="inmetroNumber" component={Input} type="text" />
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Categoria</span>
						<div className="form__form-group-field">
							<Field
								name="category"
								component={renderSelectField}
								type="text"
								options={categoriesOptions}
								placeholder="Selecione uma categoria"
								defaultValue={initialValues.category}
								onChange={onChange}
								onInputChange={categorySearch => onChange({ categorySearch })}
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">É Importado?</span>
						<div className="form__form-group-field">
							<Field
								name="imported"
								component={renderCheckBoxField}
								type="text"
								defaultChecked={initialValues.imported}
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Código Alfandegário</span>
						<div className="form__form-group-field">
							<Field name="customsCode" component={Input} type="text" />
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field name="description" component={TextArea} type="text" />
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "product_edit",
	validate
})(editForm);
