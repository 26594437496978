import React from "react";
import { Route, Switch } from "react-router-dom";
import ShowVideo from "../ShowVideos";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/cursos/user" component={ShowVideo} />
				<Route exact path="/cursos/user/sales" component={ShowVideo} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
