import * as actions from "./actions";

const initialState = {
	items: [],
	item: [],
	filter: [],
	pages: null,
	count: 0,
	loading: {},
	showDeleteForm: false,
	showEditForm: false
};

const company = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_CLIENTS: {
			return {
				...state,
				loading: { getAll: true }
			};
		}
		case actions.GET_CLIENTS_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { getAll: false }
			};
		}
		case actions.GET_CLIENTS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { getAll: false }
			};
		}

		case actions.GET_BUILDER_CLIENT: {
			return {
				...state,
				loading: { getBuilder: true }
			};
		}
		case actions.GET_BUILDER_CLIENT_SUCCESS: {
			return {
				...state,
				builder: payload,
				loading: { getBuilder: false }
			};
		}
		case actions.GET_BUILDER_CLIENT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { getBuilder: false }
			};
		}

		case actions.DELETE_CLIENTS: {
			return {
				...state,
				loading: { delete: true }
			};
		}
		case actions.DELETE_CLIENTS_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { delete: false }
			};
		}
		case actions.DELETE_CLIENTS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { delete: false }
			};
		}

		case actions.EDIT_CLIENTS: {
			return {
				...state,
				loading: { edit: true }
			};
		}
		case actions.EDIT_CLIENTS_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { edit: false }
			};
		}
		case actions.EDIT_CLIENTS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { edit: false }
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default company;
