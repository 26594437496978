import Input from "components/ReduxForm/Input";
import React, { useRef } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import TextArea from "components/ReduxForm/TextArea";
import renderSelectField from "shared/components/form/Select";
import videoStatus from "models/videoStatus";
import ls from "Localization";

const createForm = ({ handleSubmit, reset, loading }) => {
	const form = useRef(null);

	const genderOptions = Object.keys(videoStatus).map(c => ({
		value: c,
		label: ls[videoStatus[c]]
	}));

	
	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} md={6} lg={6} className="form__form-group">
						{" "}
						<span className="form__form-group-label">Título do vídeo</span>
						<div className="form__form-group-field">
							<Field
								name="title"
								component={Input}
								type="text"
								placeholder="Ex.: Vídeo 1"
							/>
						</div>
					</Col>
					<Col xs={12} md={6} lg={6} className="form__form-group">
						{" "}
						<span className="form__form-group-label">Link</span>
						<div className="form__form-group-field">
							<Field
								name="link"
								component={Input}
								type="text"
								placeholder="Ex.: https://www.youtube.com/watch?v=..."
							/>
						</div>
					</Col>
					<Col xs={12} md={6} lg={6} className="form__form-group">
						<span className="form__form-group-label">Categoria</span>
						<div className="form__form-group-field">
							<Field name="category" component={renderSelectField} options={genderOptions} type="select" />
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field
								name="description"
								component={TextArea}
								type="text"
								autoFocus
								placeholder="Ex.: Nessas aulas você aprenderá a fazer..."
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Adicionar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "video_create",
	validate
})(createForm);
