import * as api from "../../../services/api";
import { getErrors } from "../../../helpers/error";
import _ from "lodash";

export const GET_CONSUMPTION_ITEM = "[CONSUMPTION REPORT] GET_CONSUMPTION_ITEM";
export const GET_CONSUMPTION_ITEM_ERROR =
	"[CONSUMPTION REPORT] GET_CONSUMPTION_ITEM_ERROR";
export const GET_CONSUMPTION_ITEM_SUCCESS =
	"[CONSUMPTION REPORT] GET_CONSUMPTION_ITEM_SUCCESS";

export const SET_VALUE = "[CONSUMPTION REPORT] SET_VALUE";
export const CLEAR_VALUES = "[CONSUMPTION REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getConsumptionItems(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CONSUMPTION_ITEM
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/consumption/item?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_CONSUMPTION_ITEM_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_CONSUMPTION_ITEM_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_CONSUMPTION_ITEM_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const DELETE_CONSUMPTION = "[CONSUMPTION REPORT] DELETE_CONSUMPTION";
export const DELETE_CONSUMPTION_ERROR =
	"[CONSUMPTION REPORT] DELETE_CONSUMPTION_ERROR";
export const DELETE_CONSUMPTION_SUCCESS =
	"[CONSUMPTION REPORT] DELETE_CONSUMPTION_SUCCESS";

export function deleteConsumption(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_CONSUMPTION
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/consumption/item/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_CONSUMPTION_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_CONSUMPTION_ERROR,
					payload: getErrors(result.errors)
				});

				callback && callback(result.errors, null);
			}
		} catch (error) {
			dispatch({
				type: DELETE_CONSUMPTION_ERROR,
				payload: {
					error: "Connection error"
				}
			});

			callback && callback(error, null);
		}
	};
}
