import React, { useCallback, useRef } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import dayjs from "dayjs";
import ls from "Localization";
import Delete from "../Delete";
import Edit from "../Edit";
import { MdDelete, MdEdit, MdImportContacts } from "react-icons/md";
import { removeDiacritics, toStringCurrency } from "helpers/string";
import TooltipComponent from "components/TooltipComponent";
import XLSX from "xlsx";
import { extractProductsFromJson } from "./helpers";
import DateSelectFilter from "components/Report/DateSelectorFilter";

let fetchDataDebounced = null;

function Report({ history }) {
	const inputRef = useRef(null);

	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.products);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getProducts(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	const handleEditClick = item => {
		dispatch(
			actions.setValue({
				showEditForm: true,
				item
			})
		);
	};

	const handleDeleteClick = data => {
		dispatch(
			actions.setValue({
				showDeleteForm: data
			})
		);
	};

	const handleImportClick = useCallback(() => {
		inputRef.current.click();
	}, []);

	const handleFilesChange = useCallback(e => {
		const file = e.target.files[0];

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = e => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const wsName = wb.SheetNames[0];
			const sheet = wb.Sheets[wsName];

			const ref = XLSX.utils.decode_range(sheet["!ref"]);

			for (let C = ref.s.c; C <= ref.e.c; ++C) {
				const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
				if (cell && cell.t === "s") {
					cell.v = removeDiacritics(cell.v.trim().toLowerCase());
					if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());
				}
			}

			const products = extractProductsFromJson(
				XLSX.utils.sheet_to_json(sheet, {})
			);

			dispatch(
				actions.importProducts(products, err => {
					if (err) {
						console.log(err);
					} else {
						load();
					}
				})
			);
		};

		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);
	}, []);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Produtos"
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						onRowClicked={row => history.push(`/product/` + row._id)}
						headerRightComponent={
							<>
								<ButtonToolbar>
									<ButtonGroup className="btn-group--icons" dir="ltr">
										<Button
											color="primary"
											id="product-import"
											onClick={handleImportClick}
											size="md"
										>
											<MdImportContacts style={{ margin: 0 }} />
										</Button>
										<TooltipComponent
											title="Importar"
											target="product-import"
										/>
									</ButtonGroup>
								</ButtonToolbar>
							</>
						}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateTimeFormat)
										: "",
								width: 120,
								Filter: p => <DateSelectFilter {...p} />
							},
							{
								Header: "Código",
								accessor: "code"
							},
							{
								Header: "Nome",
								accessor: "name"
							},
							{
								Header: "Preço de Custo",
								id: "costPrice",
								accessor: c =>
									c.costPrice
										? toStringCurrency(c.costPrice / 100)
										: "Não definido",
								width: 120
							},
							{
								Header: "Modelo",
								accessor: "modelProduct"
							},
							{
								Header: "Fabricante",
								accessor: "manufacturer"
							},
							{
								Header: "Ações",
								width: 80,
								filterable: false,
								id: "actions",
								Cell: props => {
									return (
										<ButtonToolbar>
											<ButtonGroup className="btn-group--icons" dir="ltr">
												<Button
													color="primary"
													onClick={() => handleEditClick(props.original)}
												>
													<MdEdit style={{ margin: 0 }} />
												</Button>

												<Button
													color="cancel"
													onClick={() => handleDeleteClick(props.original)}
												>
													<MdDelete style={{ margin: 0 }} />
												</Button>
											</ButtonGroup>
										</ButtonToolbar>
									);
								}
							}
						]}
					/>
				</Col>
			</Row>
			<Delete load={load} />
			<Edit load={load} />

			<input
				ref={inputRef}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFilesChange}
			/>
		</Container>
	);
}

export default Report;
