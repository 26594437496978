import { CPF, PHONE } from "components/NumberFormat";
import React, { useRef } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderDatePickerField from "shared/components/form/DatePicker";
import renderSelectField from "shared/components/form/Select";
import Input from "../../../components/ReduxForm/Input";
import validate from "./validate";
import UploadFile from "components/ReduxForm/UploadFile";
import AlertComponent from "shared/components/Alert";

const createForm = ({
	roleOptions,
	statusOptions,
	genderOptions,
	companiesOptions,
	professionOptions,
	initialValues,
	onChange,
	handleSubmit,
	reset,
	isAdmin,
	isReceptionist,
	isBuilder,
	loading,
	handleEmailBlur,
	handleCPFBlur
}) => {
	const form = useRef(null);

	const handleReset = () => {
		onChange({ reset: true });

		reset();
	};

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Nome*</span>
						<div className="form__form-group-field">
							<Field name="firstName" component={Input} type="text" required />
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Sobrenome*</span>
						<div className="form__form-group-field">
							<Field name="lastName" component={Input} type="text" required />
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">
							CPF{isReceptionist ? "*" : ""}
						</span>
						<div className="form__form-group-field">
							<Field
								name="document"
								component={CPF}
								type="text"
								onBlur={handleCPFBlur}
								required={isReceptionist}
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">E-mail*</span>
						<div className="form__form-group-field">
							<Field
								name="email"
								component={Input}
								type="text"
								required
								onChange={event => onChange({ email: event.target.value })}
								onBlur={handleEmailBlur}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">
							Telefone{isReceptionist ? "*" : ""}
						</span>
						<div className="form__form-group-field">
							<Field
								name="phoneNumber"
								component={PHONE}
								type="text"
								required={isReceptionist}
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">
							Data de Nascimento{isReceptionist ? "*" : ""}
						</span>
						<div className="form__form-group-field">
							<Field
								name="birthDate"
								component={renderDatePickerField}
								type="text"
								required={isReceptionist}
							/>
						</div>
					</Col>

					{(isAdmin || isReceptionist) && (
						<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
							<span className="form__form-group-label">Expositor</span>
							<div className="form__form-group-field">
								<Field
									name="company"
									component={renderSelectField}
									type="text"
									options={companiesOptions}
									placeholder="Selecione um expositor"
									defaultValue={initialValues.company}
									onChange={onChange}
									onInputChange={companySearch => onChange({ companySearch })}
								/>
							</div>
						</Col>
					)}

					<Col xs={12} sm={6} md={6} lg={3} className="form__form-group">
						<span className="form__form-group-label">
							Cargo{isReceptionist ? "*" : ""}
						</span>
						<div className="form__form-group-field">
							{/* <Field
								name="profession"
								component={renderSelectProfessionField}
								type="text"
								options={professionOptions}
								defaultValue={initialValues.profession}
								placeholder="Selecione"
							/> */}
							<Field
								name="profession"
								component={Input}
								type="text"
								required={isReceptionist}
							/>
						</div>
					</Col>

					{(isAdmin || isReceptionist) && (
						<Col xs={12} sm={6} md={6} lg={3} className="form__form-group">
							<span className="form__form-group-label">Empresa</span>
							<div className="form__form-group-field">
								<Field
									name="companyName"
									component={Input}
									type="text"
									placeholder="Nome da empresa"
								/>
							</div>
						</Col>
					)}

					{isAdmin ? (
						<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
							<span className="form__form-group-label">Função*</span>
							<div className="form__form-group-field">
								<Field
									name="role"
									component={renderSelectField}
									type="text"
									options={roleOptions}
									placeholder="Selecione"
									required
								/>
							</div>
						</Col>
					) : null}

					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">
							Sexo{isReceptionist ? "*" : ""}
						</span>
						<div className="form__form-group-field">
							<Field
								name="gender"
								component={renderSelectField}
								type="text"
								options={genderOptions}
								placeholder="Selecione"
								required={isReceptionist}
							/>
						</div>
					</Col>

					{isAdmin && (
						<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
							<span className="form__form-group-label">Status*</span>
							<div className="form__form-group-field">
								<Field
									name="status"
									component={renderSelectField}
									type="text"
									options={statusOptions}
									placeholder="Selecione"
									required
								/>
							</div>
						</Col>
					)}
				</Row>

				{isBuilder && (
					<Row>
						<Col xs={12} md={6} lg={4} className="form__form-group">
							<span className="form__form-group-label">
								Documentação do profissional*
							</span>
							<div className="form__form-group-field">
								<Field
									name="professionalFile"
									component={UploadFile}
									type="file"
									multiple
								/>
							</div>
						</Col>
						<br />
						<AlertComponent
							color="info"
							className="alert--bordered"
							icon
							hideDismiss
						>
							<p>
								<span className="bold-text">
									*É obrigatório o envio de certificado que comprove a
									habilitação do profissional em trabalho em altura e/ou
									elétrica. Caso não haja comprovação, o profissional não poderá
									executar o serviço, além de ser penalizado com multa, conforme
									especificado no Termo de Responsabilidade, assinado pela
									montadora.
								</span>
							</p>
						</AlertComponent>
					</Row>
				)}
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={handleReset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "user_create",
	enableReinitialize: true,
	validate
})(createForm);
