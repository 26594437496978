import React from "react";
import { Button, Row } from "reactstrap";
import Model36 from "../../../../../assets/booth/36.jpg";

const BuilderProfile36 = () => {
	const handleContact = () => {
		window.open(
			"https://api.whatsapp.com/send?phone=5541996445703&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Estande%2036.",
			"_blank"
		);
	};

	return (
		<Row>
			<div style={{ position: "relative", width: "100%", marginTop: -40 }}>
				<img
					src={Model36}
					alt="Modelo do Estande 36"
					style={{ width: "100%", height: "auto" }}
				/>
				<Button
					style={{
						position: "absolute",
						bottom: -30,
						left: 0,
						right: 0,
						padding: "18px 100px",
						fontSize: "1.2em"
					}}
					color="primary"
					onClick={handleContact}
				>
					Entrar em Contato
				</Button>
			</div>
		</Row>
	);
};

export default BuilderProfile36;
