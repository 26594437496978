import * as actions from "./actions";

const initialState = {
	items: [],
	filter: [],
	pages: null,
	count: 0,
	loading: {},
	showDeleteForm: false,
	showEditForm: false
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_BUDGET: {
			return {
				...state,
				loading: { getAll: true }
			};
		}
		case actions.GET_BUDGET_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { getAll: false }
			};
		}
		case actions.GET_BUDGET_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { getAll: false }
			};
		}

		case actions.EDIT_BUDGET_EDIT: {
			return {
				...state,
				loading: { edit: true }
			};
		}
		case actions.EDIT_BUDGET_EDIT_SUCCESS: {
			return {
				...state,
				loading: { edit: false }
			};
		}
		case actions.EDIT_BUDGET_EDIT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { edit: false }
			};
		}

		case actions.DELETE_BUDGET_DELETE: {
			return {
				...state,
				loading: { delete: true }
			};
		}
		case actions.DELETE_BUDGET_DELETE_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { delete: false }
			};
		}
		case actions.DELETE_BUDGET_DELETE_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { delete: false }
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
