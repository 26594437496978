import * as api from "../../../../../services/api";
import { getErrors } from "../../../../../helpers/error";

export const CREATE_CONSUMPTION = "[CONSUMPTION CREATE] CREATE_CONSUMPTION";
export const CREATE_CONSUMPTION_ERROR =
	"[CONSUMPTION CREATE] CREATE_CONSUMPTION_ERROR";
export const CREATE_CONSUMPTION_SUCCESS =
	"[CONSUMPTION CREATE] CREATE_CONSUMPTION_SUCCESS";

export const SET_VALUE = "[CONSUMPTION CREATE] SET_VALUE";
export const CLEAR_VALUES = "[CONSUMPTION CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createConsumption(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_CONSUMPTION
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/consumption`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_CONSUMPTION_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: CREATE_CONSUMPTION_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error, null);

			return dispatch({
				type: CREATE_CONSUMPTION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
