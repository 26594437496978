import states from "assets/states.json";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { reset } from "redux-form";
import CreateUserForm from "../../components/createForm";
import { createVideo } from "./actions";

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.videoCreate);

	const onSubmit = async values => {
		const data = {
			...values,
			title: values.title,
			link: values.link,
			description: values.description,
			status: values.category.value
		};

		dispatch(
			createVideo(data, (err) => {
				if (err) {
					console.log(err);
					// alert(err && JSON.stringify(err));
					history.push(`/courses`);
				} else {
					dispatch(reset("video_create"));
					history.push(`/courses`);
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Adicionar obra</h5>
							</div>
							<CreateUserForm
								onSubmit={onSubmit}
								loading={loading}
								states={states}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
