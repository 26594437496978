import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";

import { clearValues, getClient } from "./actions";
import { setValue } from "../Report/actions";

import Edit from "../Edit";
import DetailsPerson from "../../components/detail";
import { isInRole } from "helpers/auth";
import userRole from "models/userRole";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();
	const { item, loading } = useSelector(state => state.companyDetails);
	const { user } = useSelector(state => state.login);

	const load = () => {
		dispatch(getClient(id));
	};

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, [dispatch]);

	const handleEditClick = () => {
		dispatch(
			setValue({
				showEditForm: true,
				item
			})
		);
	};

	if (!item) return null;

	const isAdmin = isInRole(user, [userRole.ADMIN]);

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">
										Detalhes do Prestador de Serviço
									</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
								{isAdmin && (
									<div>
										<Button onClick={handleEditClick} color="primary">
											Editar
										</Button>
									</div>
								)}
							</Row>
							<DetailsPerson company={item} loading={loading} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Edit load={load} />
		</Container>
	);
}

export default Details;
