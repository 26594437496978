import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_ID = "GET_ID";
export const GET_ID_SUCCESS = "GET_ID_SUCCESS";
export const GET_ID_ERROR = "GET_ID_ERROR";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function findUser(key, callback) {
	return async dispatch => {
		dispatch({
			type: GET_ID
		});

		try {
			let response = await api.sendGet(`/SelfService/${key}`);

			let result = await response.json();

			if (result.isValid) {
				dispatch({ type: GET_ID_SUCCESS, payload: result.model });
				callback && callback(null, result.model);
			} else {
				callback && callback(result.errors, null);
				dispatch({ type: GET_ID_ERROR, payload: getErrors(result.errors) });
			}
		} catch (error) {
			console.error("ERROR", error);
			callback && callback(error.errors, null);
			dispatch({ type: GET_ID_ERROR, payload: { error: "Connection error" } });
		}
	};
}

export function checkInOut(event, key, type, callback) {
	return async dispatch => {
		dispatch({
			type: GET_ID
		});

		try {
			let response = await api.sendPost(
				`/event/${event}/attendee/${key}?type=${type}`,
				null
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({ type: GET_ID_SUCCESS, payload: result.model });
				callback && callback(null, result.model);
			} else {
				callback && callback(result.errors, null);
				dispatch({ type: GET_ID_ERROR, payload: getErrors(result.errors) });
			}
		} catch (error) {
			console.error("ERROR", error);
			callback && callback(error.errors, null);
			dispatch({ type: GET_ID_ERROR, payload: { error: "Connection error" } });
		}
	};
}
