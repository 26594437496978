import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row, Spinner } from "reactstrap";

import { saveAs } from "file-saver";

const BudgetDetail = ({ budgetDetails, loading }) => {
	if (!budgetDetails) return null;

	const downloadFile = uri => {
		saveAs(uri, uri.split("/").pop());
	};

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{budgetDetails.createdAt
												? dayjs(budgetDetails.createdAt).format(
														ls.dateTimeFormat
												  )
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Descrição: </strong>
										<br />
										<span>
											{budgetDetails.description
												? budgetDetails.description
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Valor: </strong>
										<br />
										<span>
											{budgetDetails.value
												? toStringCurrency(budgetDetails.value / 100.0)
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Lead: </strong>
										<br />
										<span>
											{budgetDetails.user ? (
												<Link to={`/user/${budgetDetails.user._id}`}>
													{budgetDetails.user.fullName}
												</Link>
											) : (
												"Não definido"
											)}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Criado por: </strong>
										<br />
										<span>
											{budgetDetails.createdBy ? (
												<Link to={`/user/${budgetDetails.createdBy._id}`}>
													{budgetDetails.createdBy.fullName}
												</Link>
											) : (
												"Não definido"
											)}
										</span>
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12}>
									<p>
										<strong>Anexos: </strong>
										<br />
										<span>
											{budgetDetails.files
												? budgetDetails.files.map(f => {
														return (
															<div key={f}>
																<a
																	href={f}
																	onClick={e => {
																		e.preventDefault();
																		downloadFile(f);
																	}}
																>
																	{f.split("/").pop()}
																</a>
															</div>
														);
												  })
												: "Nenhum anexo"}
										</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default BudgetDetail;
