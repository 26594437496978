// export function readFile(file) {
// 	return new Promise((resolve, reject) => {
// 		const reader = new FileReader();

// 		// Read the image via FileReader API and save image result in state.
// 		reader.onload = function(e) {
// 			// Add the file name to the data URL
// 			let dataURL = e.target.result;
// 			dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
// 			resolve({ file, dataURL });
// 		};

// 		reader.readAsDataURL(file);
// 	});
// }
export function readFileAsArrayBuffer(file) {
	return new Promise((resolve, reject) => {
		// Create file reader
		let reader = new FileReader();
		// Register event listeners
		reader.addEventListener("loadend", e => resolve(e.target.result));
		reader.addEventListener("error", reject);
		// Read file
		reader.readAsArrayBuffer(file);
	});
}
