import dayjs from "dayjs";
import ls from "Localization";
import eventTypeOptions from "models/eventTypeOptions";
import React from "react";
import { Card, Col, Container, Row, Spinner } from "reactstrap";

const EventsDetails = ({ event, loading }) => {
	if (!event) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{event.createdAt
												? dayjs(event.createdAt).format(ls.dateTimeFormat)
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Titulo</strong>
										<br />
										<span>{event.title ? event.title : "Não definido"}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={6}>
									<p>
										<strong>Descrição</strong>
										<br />
										<span>
											{event.description ? event.description : "Não definido"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Quando</strong>
										<br />
										<span>
											{event.when
												? dayjs(event.when).format(ls.dateTimeFormat)
												: "Não definido"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Termino</strong>
										<br />
										<span>
											{event.until
												? dayjs(event.until).format(ls.dateTimeFormat)
												: "Não definido"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Tipo</strong>
										<br />
										<span>
											{ls[eventTypeOptions[event.type]] || "Não definido"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Capacidade</strong>
										<br />
										<span>{event.capacity || "Não definido"}</span>
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={6}>
									<p>
										<strong>Imagem</strong>
										<br />
										<span>{event.banner ? event.banner : "Não definido"}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={6}>
									<p>
										<strong>Video</strong>
										<br />
										<span>{event.video ? event.video : "Não definido"}</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default EventsDetails;
