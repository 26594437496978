import React from "react";
import ReactExport from "react-export-excel";
import dayjs from "dayjs";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export const getExcelData = (columns, data) => {
	const dataExcel = [];

	for (const d of data) {
		let row = [];
		for (const c of columns) {
			if (c.id !== "actions") {
				const id = typeof c.accessor === "string" ? c.accessor : c.id;

				let value = "";

				if (typeof c.accessor === "function")
					value = c.accessor(d._original || d);
				else value = d[id];

				if (typeof value === "object" || c.format) {
					value = c.format
						? c.format(d._original || d)
						: (d._original || d)[id];
				}

				row.push(value);
			}
		}
		dataExcel.push(row);
	}

	return [
		{
			columns: columns.filter(c => c.id !== "actions").map(c => c.Header),
			data: dataExcel
		}
	];
};

export const exportExcel = async (table, columns, title, setExport) => {
	let resolvedState = table.current.getResolvedState();

	const data = table.current.getSortedData(resolvedState).sortedData;

	const { pages, pageSize, onFetchData, resolveData, manual } = resolvedState;

	const callback = (err, data) => {
		if (err) alert(err);
		else {
			const dataSet = getExcelData(columns, data);

			if (dataSet)
				setExport(
					<ExcelFile
						filename={title + "_" + dayjs().format("YYYY-MM-DD-kk-mm-ss")}
						hideElement
					>
						<ExcelSheet dataSet={dataSet} name={title} />
					</ExcelFile>
				);

			setTimeout(() => {
				setExport(null);
			}, 1000);
		}
	};

	if (manual) {
		const data2 = [];

		let next = true;

		while (next && data2.length < pages * pageSize) {
			next = await new Promise(resolve => {
				onFetchData({
					...resolvedState,
					page: data2.length / 1000,
					pageSize: 1000,
					toExport: true,
					callback: (err, d) => {
						data2.push(...resolveData(d || []));

						resolve((d || []).length === 1000);
					}
				});
			});
		}

		callback(null, data2);
	} else {
		callback(null, data);
	}
};
