import * as login from "./login";
import * as register from "containers/Register/actions";
import * as notification from "./notification";
import * as theme from "./theme";
import * as sidebar from "./sidebar";

export default {
	login,
	register,
	theme,
	sidebar,
	notification
};
