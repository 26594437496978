import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_CLIENTS = "[CLIENTS REPORT] GET_CLIENTS";
export const GET_CLIENTS_ERROR = "[CLIENTS REPORT] GET_CLIENTS_ERROR";
export const GET_CLIENTS_SUCCESS = "[CLIENTS REPORT] GET_CLIENTS_SUCCESS";

export const SET_VALUE = "[CLIENTS REPORT] SET_VALUE";
export const CLEAR_VALUES = "[CLIENTS REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getCompanies(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CLIENTS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/company?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					""}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_CLIENTS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_CLIENTS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_CLIENTS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const DELETE_CLIENTS = "[CLIENTS DELETE] DELETE_CLIENTS";
export const DELETE_CLIENTS_ERROR = "[CLIENTS DELETE] DELETE_CLIENTS_ERROR";
export const DELETE_CLIENTS_SUCCESS = "[CLIENTS DELETE] DELETE_CLIENTS_SUCCESS";

export function deleteClient(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_CLIENTS
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/company/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_CLIENTS_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_CLIENTS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_CLIENTS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_CLIENTS = "[CLIENTS EDIT] EDIT_CLIENTS";
export const EDIT_CLIENTS_ERROR = "[CLIENTS EDIT] EDIT_CLIENTS_ERROR";
export const EDIT_CLIENTS_SUCCESS = "[CLIENTS EDIT] EDIT_CLIENTS_SUCCESS";

export function editClient(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_CLIENTS
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/company/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_CLIENTS_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_CLIENTS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_CLIENTS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_BUILDER_CLIENT = "[CLIENTS EDIT] EDIT_BUILDER_CLIENT";
export const EDIT_BUILDER_CLIENT_ERROR =
	"[CLIENTS EDIT] EDIT_BUILDER_CLIENT_ERROR";
export const EDIT_BUILDER_CLIENT_SUCCESS =
	"[CLIENTS EDIT] EDIT_BUILDER_CLIENT_SUCCESS";

export function editBuilderClient(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_BUILDER_CLIENT
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/builder/tagEdit/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_BUILDER_CLIENT_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_BUILDER_CLIENT_ERROR,
					payload: getErrors(result.errors)
				});

				callback && callback(result.errors, null);
			}
		} catch (error) {
			return dispatch({
				type: EDIT_BUILDER_CLIENT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const GET_BUILDER_CLIENT = "[CLIENT BUILDER] GET_BUILDER_CLIENT";
export const GET_BUILDER_CLIENT_ERROR =
	"[CLIENT BUILDER] GET_BUILDER_CLIENT_ERROR";
export const GET_BUILDER_CLIENT_SUCCESS =
	"[CLIENT BUILDER] GET_BUILDER_CLIENT_SUCCESS";

export function getBuilderClient(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_BUILDER_CLIENT
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/builder/company/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUILDER_CLIENT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_BUILDER_CLIENT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_BUILDER_CLIENT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
