import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import TotalPeople from "./components/TotalPeople";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import ls from "Localization";

function Dashboard() {
	const dispatch = useDispatch();
	const { user } = useSelector(s => s.login);

	console.log(user);

	const {
		today,
		lastSevenDays,
		lastThirtyDays,
		total,
		totalUsers,
		totalCompanies,
		totalCheckIns,
		todayCheckIns,
		lastSevenDaysCheckIns,
		yesterdayCheckIns
	} = useSelector(s => s.dashboard?.dashboardData || {});

	useEffect(() => {
		dispatch(actions.getLeadDashboard());
	}, [dispatch]);

	return (
		<Container className="dashboard">
			<img
				src={require("../../assets/Avisos-Dashboard-APP.png")}
				alt="Aviso"
				style={{ width: "100%", height: "auto" }}
			/>
			<Row>
				<Col md={12}>
					<h3 className="page-title">Dashboard</h3>
				</Col>
			</Row>
			<Row>
				{user.role === "ADMIN" ? (
					<>
						<TotalPeople title={ls.TodaysCheckIns} count={todayCheckIns} />
						<TotalPeople
							title={ls.YesterdayCheckIns}
							count={yesterdayCheckIns}
						/>
						<TotalPeople
							title={ls.Last7DaysCheckIns}
							count={lastSevenDaysCheckIns}
						/>
						<TotalPeople title={ls.TotalCheckIns} count={totalCheckIns} />
						<TotalPeople title={ls.TotalUsers} count={totalUsers} />
						<TotalPeople title={ls.TotalExpositors} count={totalCompanies} />
					</>
				) : (
					(user.role === "MANAGER" || user.role === "USER") && (
						<>
							<TotalPeople title={ls.TodaysLeads} count={today} />
							<TotalPeople title={ls.Last7DaysLeads} count={lastSevenDays} />
							<TotalPeople title={ls.Last30DaysLeads} count={lastThirtyDays} />
							<TotalPeople title={ls.TotalLeads} count={total} />
						</>
					)
				)}
			</Row>
		</Container>
	);
}

export default Dashboard;
