import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const CREATE_USERS = "[USERS CREATE] CREATE_USERS";
export const CREATE_USERS_ERROR = "[USERS CREATE] CREATE_USERS_ERROR";
export const CREATE_USERS_SUCCESS = "[USERS CREATE] CREATE_USERS_SUCCESS";

export const VERIFY_DATA = "[USERS] VERIFY_DATA";
export const VERIFY_DATA_SUCCESS = "[USERS] VERIFY_DATA_SUCCESS";
export const VERIFY_DATA_ERROR = "[USERS] VERIFY_DATA_ERROR";

export const SET_VALUE = "[USERS CREATE] SET_VALUE";
export const CLEAR_VALUES = "[USERS CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createUser(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_USERS
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/user`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_USERS_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: CREATE_USERS_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error, null);

			return dispatch({
				type: CREATE_USERS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function createUserForCompany(id, data, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_USERS
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/user/company/${id}`, data, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_USERS_SUCCESS
				});

				callback && callback(null, result.data);
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: CREATE_USERS_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error, null);

			return dispatch({
				type: CREATE_USERS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function verifyUser(email, cpf, cnpj, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: VERIFY_DATA
		});

		try {
			const { login } = getState();

			console.log(cnpj)
			
			const endpoint = email
				? `/verify?email=${email}`
				: cpf
				? `/verify?cpf=${cpf}`
				: `/verify?cnpj=${cnpj}`;

			let response = await api.sendGet(endpoint, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (response.status === 200 && result.isValid === true) {
				dispatch({
					type: VERIFY_DATA_SUCCESS,
					payload: { data: email || cpf || cnpj, available: true }
				});

				if (typeof callback === "function") {
					callback(null, true);
				}
			} else if (response.status === 409 && result.isValid === false) {
				dispatch({
					type: VERIFY_DATA_ERROR,
					payload: {
						data: email || cpf || cnpj,
						available: false,
						userDetails: result.model
					}
				});

				if (typeof callback === "function") {
					callback(null, false, result.model);
				}
			} else {
				dispatch({
					type: VERIFY_DATA_ERROR,
					payload: { error: result.errors?.default || "Unexpected response" }
				});

				if (typeof callback === "function") {
					callback(
						new Error(result.errors?.default || "Unexpected response"),
						null
					);
				}
			}
		} catch (error) {
			dispatch({
				type: VERIFY_DATA_ERROR,
				payload: { error: "Connection error" }
			});

			if (typeof callback === "function") {
				callback(error, null);
			}
		}
	};
}
