const validate = values => {
	const errors = {};

	const requiredFields = ["title", "link", "description"];

	for (let field of requiredFields) {
		if (!values[field]) errors[field] = `Campo obrigatório`;
	}

	if (
		values.link &&
		!/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/i.test(values.link)
	) {
		errors.link = `Link do vídeo inválido`;
	}

	return errors;
};

export default validate;