import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_CLIENT = "[CLIENT DETAIL] GET_CLIENT";
export const GET_CLIENT_ERROR = "[CLIENT DETAIL] GET_CLIENT_ERROR";
export const GET_CLIENT_SUCCESS = "[CLIENT DETAIL] GET_CLIENT_SUCCESS";

export const UPDATE_RESPONSIBILITY_STATUS =
	"[CLIENT DETAIL] UPDATE_RESPONSIBILITY_STATUS";
export const UPDATE_RESPONSIBILITY_STATUS_ERROR =
	"[CLIENT DETAIL] UPDATE_RESPONSIBILITY_STATUS_ERROR";
export const UPDATE_RESPONSIBILITY_STATUS_SUCCESS =
	"[CLIENT DETAIL] UPDATE_RESPONSIBILITY_STATUS_SUCCESS";

export const UPDATE_FILE_STATUS = "[CLIENT DETAIL] UPDATE_FILE_STATUS";
export const UPDATE_FILE_STATUS_ERROR =
	"[CLIENT DETAIL] UPDATE_FILE_STATUS_ERROR";
export const UPDATE_FILE_STATUS_SUCCESS =
	"[CLIENT DETAIL] UPDATE_FILE_STATUS_SUCCESS";

export const SET_VALUE = "[CLIENT DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[CLIENT DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getClient(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CLIENT
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/company/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CLIENT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_CLIENT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_CLIENT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function updateResponsibilityStatus(id, status, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPDATE_RESPONSIBILITY_STATUS
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/company/${id}/responsibility/status/${status}`,
				null,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_RESPONSIBILITY_STATUS_SUCCESS
				});

				callback && callback(null);
			} else {
				dispatch({
					type: UPDATE_RESPONSIBILITY_STATUS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_RESPONSIBILITY_STATUS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function updateFileStatus(id, file, status, reason, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPDATE_FILE_STATUS
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/company/builder/${id}/file/${file}/status/${status}?reason=${reason || ""}`,
				null,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_FILE_STATUS_SUCCESS
				});

				callback && callback(null);
			} else {
				dispatch({
					type: UPDATE_FILE_STATUS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_FILE_STATUS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
