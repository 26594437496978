import { validateEmail } from "helpers/email";
import { CPFValidation, dateValidation } from "helpers/validators";
import { isValidPhoneNumber } from "libphonenumber-js/min";

const validate = (values, step) => {
	const errors = {};

	if (step === 1) {
		if (!values.name) {
			errors["name"] = "Informe seu nome";
		}

		if (!values.lastname) {
			errors["lastname"] = "Informe seu sobrenome";
		}

		if (!values.email) {
			errors["email"] = "Informe seu e-mail";
		} else if (!validateEmail(String(values["email"]))) {
			errors["email"] = "Informe um e-mail válido";
		}
	}

	if (step === 2) {
		if (!values.cpf) {
			errors["cpf"] = "Informe seu cpf";
		} else if (!CPFValidation(String(values["cpf"]))) {
			errors["cpf"] = "Informe um cpf válido";
		}

		if (!values.phone) {
			errors["phone"] = "Informe seu telefone";
		} else {
			let number = values.phone?.match(/\d/g)?.join("");

			if (number.length < 10 || !isValidPhoneNumber(number, "BR"))
				errors["phone"] = "Informe um telefone válido";
		}

		if (!values.birthDate) {
			errors["birthDate"] = "Informe sua data de nascimento";
		} else if (!dateValidation(values["birthDate"])) {
			errors["birthDate"] = "Informe uma data de nascimento válida";
		}
	}

	if (step === 3 && values.type !== "provider") {
		if (!values.profession) {
			errors["profession"] = "Informe sua profissão";
		}

		if (!values.gender) {
			errors["gender"] = "Informe seu gênero";
		}

		if (!values.professionalFiles || values.professionalFiles.length === 0) {
			errors["professionalFiles"] = "Informe ao menos um arquivo";
		}
	}

	return errors;
};

export default validate;
