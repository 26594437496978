import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import { CPF, PHONE } from "components/NumberFormat";
import renderDatePickerField from "shared/components/form/DatePicker";

const FormTwo = props => {
	const {
		handleSubmit,
		pristine,
		submitting,
		previousPage,
		onFormTwoSubmit
	} = props;

	const handleNextClick = e => {
		e.preventDefault(); 
		handleSubmit(onFormTwoSubmit); 
	};

	return (
		<form className="form" onSubmit={handleNextClick}>
			<div className="form__form-group">
				<span className="form__form-group-label">CPF</span>
				<div className="form__form-group-field">
					<Field
						name="cpf"
						type="text"
						placeholder="Seu CPF"
						component={CPF}
					/>
				</div>
				<br />
				<span className="form__form-group-label">Telefone</span>
				<div className="form__form-group-field">
					<Field
						name="phone"
						type="text"
						placeholder="Seu Telefone"
						component={PHONE}
					/>
				</div>
				<br />
				<span className="form__form-group-label">Data de Nascimento</span>
				<div className="form__form-group-field">
					<Field
						name="birthDate"
						placeholder="Data de Nascimento"
						component={renderDatePickerField}
					/>
				</div>
			</div>
			<div className="account__btn__group">
				<button
					type="button"
					className="btn btn-primary account__btn account__btn--small"
					onClick={previousPage}
				>
					Voltar
				</button>
				<button
					type="submit"
					className="btn btn-primary account__btn account__btn--small"					
					disabled={pristine || submitting}
				>
					Próximo
				</button>
			</div>
		</form>
	);
};

export default reduxForm({
	form: 'expositor_form',
	destroyOnUnmount: false, // Preserve form data
	forceUnregisterOnUnmount: true, // Unregister fields on unmount
	validate: (v) => validate(v, 2)
})(FormTwo);
