export default {
	1: "Lecture",
	2: "Course",
	3: "Training",
	4: "Workshop",
	5: "RoundTable",
	6: "Symposium",
	7: "Seminar",
	8: "Congress",
	9: "Fair",
	10: "Exhibition",
	11: "Vernissage",
	12: "Festival",
	13: "Show",
	99: "Others"
};
