import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";
import _ from "lodash";

export const GET_VIDEOS = "[VIDEOS REPORT] GET_VIDEOS";
export const GET_VIDEOS_ERROR = "[VIDEOS REPORT] GET_VIDEOS_ERROR";
export const GET_VIDEOS_SUCCESS = "[VIDEOS REPORT] GET_VIDEOS_SUCCESS";

export const SET_VALUE = "[VIDEOS REPORT] SET_VALUE";
export const CLEAR_VALUES = "[VIDEOS REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getVideos(skip, take, filter, sort, desc, toExport, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_VIDEOS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/video?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_VIDEOS_SUCCESS,
						payload: result
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_VIDEOS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_VIDEOS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_VIDEOS_EDIT = "[VIDEOS EDIT] EDIT_VIDEOS_EDIT";
export const EDIT_VIDEOS_EDIT_ERROR = "[VIDEOS EDIT] EDIT_VIDEOS_EDIT_ERROR";
export const EDIT_VIDEOS_EDIT_SUCCESS =
	"[VIDEOS EDIT] EDIT_VIDEOS_EDIT_SUCCESS";

export function editVideo(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_VIDEOS_EDIT
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/video/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_VIDEOS_EDIT_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_VIDEOS_EDIT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_VIDEOS_EDIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const DELETE_VIDEOS_DELETE = "[VIDEOS DELETE] DELETE_VIDEOS_DELETE";
export const DELETE_VIDEOS_DELETE_ERROR =
	"[VIDEOS DELETE] DELETE_VIDEOS_DELETE_ERROR";
export const DELETE_VIDEOS_DELETE_SUCCESS =
	"[VIDEOS DELETE] DELETE_VIDEOS_DELETE_SUCCESS";

export function deleteVideo(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_VIDEOS_DELETE
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/video/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_VIDEOS_DELETE_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_VIDEOS_DELETE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_VIDEOS_DELETE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
