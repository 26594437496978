import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import PropTypes from "prop-types";
import validate from "./validate";

import Input from "../../../components/ReduxForm/Input";
import { Link } from "react-router-dom";

class LogInForm extends PureComponent {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired
	};

	showPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({ showPassword: !prevState.showPassword }));
	};

	render() {
		const { handleSubmit } = this.props;

		return (
			<form className="form" onSubmit={handleSubmit}>
				<div className="form__form-group">
					<span className="form__form-group-label">E-mail</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<AccountOutlineIcon />
						</div>
						<Field
							name="email"
							component={Input}
							type="email-address"
							placeholder="Seu e-mail"
						/>
					</div>
				</div>

				<button
					className="btn btn-primary account__btn account__btn--small"
					type="submit"
				>
					Recuperar senha
				</button>

				<Link className="btn btn-outline-primary account__btn" to="/login">
					Voltar
				</Link>
			</form>
		);
	}
}

export default reduxForm({
	form: "log_in_form",
	validate
})(LogInForm);
