import { CNPJ, CPF } from "components/NumberFormat";
import ls from "Localization";
import approvedStatus from "models/approvedStatus";
import React from "react";
import { FiLink } from "react-icons/fi";
import { Button, Card, Col, Container, Row, Spinner } from "reactstrap";

const BuilderDetail = ({ item, loading }) => {
	if (!item) return null;

	const builder = item.builder?._id ? item.builder : item.company;

	if (!builder) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Razão Social: </strong>
										<br />
										<span>{builder.name ? builder.name : "Não definido"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nome Fantasia: </strong>
										<br />
										<span>
											{builder.socialName ? builder.socialName : "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									{builder.document ? (
										<CNPJ
											displayType="text"
											value={builder.document}
											renderText={v => {
												return (
													<p>
														<strong>CNPJ: </strong>
														<br />
														<span>{v}</span>
													</p>
												);
											}}
										/>
									) : (
										<p>
											<strong>CNPJ: </strong>
											<br />
											<span>Não definido</span>
										</p>
									)}
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Email: </strong>
										<br />
										<span>
											{builder.email ? builder.email : "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Telefone: </strong>
										<br />
										<span>
											{builder.nationalPhoneNumber
												? builder.nationalPhoneNumber
												: "Não definido"}
										</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row>
						<Col className="mt-3" xs={12} md={6}>
							<p>
								<strong>Necessita de ponto de água e deságue?</strong>
								<br />
								<span>
									{item.needWaterAndDrain === true
										? "Sim"
										: item.needWaterAndDrain === false
										? "Não"
										: "Não informado"}
								</span>
							</p>
						</Col>
						<Col className="mt-3" xs={12} md={6}>
							<p>
								<strong>
									Necessita de ponto elétrico trifásico adicional?
								</strong>
								<br />
								<span>
									{item.needAdditionalThreePhasePower === true
										? "Sim"
										: item.needAdditionalThreePhasePower === false
										? "Não"
										: "Não informado"}
								</span>
							</p>
						</Col>
						<Col className="mt-3" xs={12} md={6}>
							<p>
								<strong>ART/RRT Execução</strong>
								<br />
								{item.artExecution ? (
									<>
										{ls[approvedStatus[item.artExecution.status]]}
										<a
											href={item.artExecution.url}
											target="_blank"
											rel="noopener noreferrer"
											className="d-flex align-items-center"
										>
											<Button color="primary" size="sm" id="view-link">
												<FiLink />
											</Button>
											<span className=" m-lg-2">Visualizar</span>
										</a>
									</>
								) : (
									<span>Não enviado</span>
								)}
							</p>
						</Col>

						<Col className="mt-3" xs={12} md={6}>
							<p>
								<strong>ART/RRT Projeto</strong>
								<br />
								{item.artProject ? (
									<>
										{ls[approvedStatus[item.artProject.status]]}
										<a
											href={item.artProject.url}
											target="_blank"
											rel="noopener noreferrer"
											className="d-flex align-items-center"
										>
											<Button color="primary" size="sm">
												<FiLink />
											</Button>
											<span className=" m-lg-2">Visualizar</span>
										</a>
									</>
								) : (
									<span>Não enviado</span>
								)}
							</p>
						</Col>

						<Col className="mt-3" xs={12} md={6}>
							<p>
								<strong>Planta baixa</strong>
								<br />
								{item.blueprint ? (
									<>
										{ls[approvedStatus[item.blueprint.status]]}
										<a
											href={item.blueprint.url}
											target="_blank"
											rel="noopener noreferrer"
											className="d-flex align-items-center"
										>
											<Button color="primary" size="sm">
												<FiLink />
											</Button>
											<span className=" m-lg-2">Visualizar</span>
										</a>
									</>
								) : (
									<span>Não enviado</span>
								)}
							</p>
						</Col>

						<Col className="mt-3" xs={12} md={6}>
							<p>
								<strong>Vista</strong>
								<br />
								{item.view ? (
									<>
										{ls[approvedStatus[item.view.status]]}
										<a
											href={item.view.url}
											target="_blank"
											rel="noopener noreferrer"
											className="d-flex align-items-center"
										>
											<Button color="primary" size="sm">
												<FiLink />
											</Button>
											<span className=" m-lg-2">Visualizar</span>
										</a>
									</>
								) : (
									<span>Não enviado</span>
								)}
							</p>
						</Col>

						<Col className="mt-3" xs={12} md={6}>
							<p>
								<strong>3D Volumetria</strong>
								<br />
								{item.volumetric3D ? (
									<>
										{ls[approvedStatus[item.volumetric3D.status]]}
										<a
											href={item.volumetric3D.url}
											target="_blank"
											rel="noopener noreferrer"
											className="d-flex align-items-center"
										>
											<Button color="primary" size="sm">
												<FiLink />
											</Button>
											<span className=" m-lg-2">Visualizar</span>
										</a>
									</>
								) : (
									<span>Não enviado</span>
								)}
							</p>
						</Col>

						<Col className="mt-3" xs={12} md={6}>
							<p>
								<strong>Apólice de seguro</strong>
								<br />
								{item.insurancePolicy ? (
									<>
										{ls[approvedStatus[item.insurancePolicy.status]]}
										<a
											href={item.insurancePolicy.url}
											target="_blank"
											rel="noopener noreferrer"
											className="d-flex align-items-center"
										>
											<Button color="primary" size="sm">
												<FiLink />
											</Button>
											<span className=" m-lg-2">Visualizar</span>
										</a>
									</>
								) : (
									<span>Não enviado</span>
								)}
							</p>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default BuilderDetail;
