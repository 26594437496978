import { validateEmail } from "helpers/email";
import { CNPJValidation } from "helpers/validators";
import { isValidPhoneNumber } from "libphonenumber-js/min";

const validate = values => {
	const errors = {};

	const requiredFields = ["name", "socialName", "document"];

	for (let field of requiredFields) {
		if (!values[field]) errors[field] = `Campo obrigatório`;
	}

	if (values["document"] && !CNPJValidation(String(values["document"]))) {
		errors["document"] = `Campo CNPJ Inválido`;
	}

	if (values["email"] && !validateEmail(String(values["email"]))) {
		errors["email"] = `Campo Email Inválido`;
	}

	if (values.phoneNumber) {
		let number = values.phoneNumber?.match(/\d/g)?.join("");

		if (number.length < 10 || !isValidPhoneNumber(number, "BR")) {
			errors["phoneNumber"] = `Campo Telefone Inválido`;
		}
	}

	if (values.address) {
		errors.address = {};
		if (
			values.address.zipCode &&
			String(values.address.zipCode?.match(/\d/g)?.join("")).length !== 8
		) {
			errors.address["zipCode"] = `Campo CEP Inválido`;
		} else if (values.address.zipCode) {
			if (!values.address.state) {
				errors.address["state"] = `Campo obrigatório`;
			}

			if (!values.address.city) {
				errors.address["city"] = `Campo obrigatório`;
			}
		}

		if (!Object.keys(errors.address).length) {
			delete errors.address;
		}
	}

	return errors;
};

export default validate;
