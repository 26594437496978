import React, { useRef } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import dayjs from "dayjs";
import ls from "Localization";
import Delete from "../Delete";
import Edit from "../Edit";
import { MdRemoveRedEye } from "react-icons/md";

import TooltipComponent from "components/TooltipComponent";
import videoStatus from "models/videoStatus";

let fetchDataDebounced = null;

function Report({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.video);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getVideos(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () => {
		handleFetchData(table.current ? table.current.state : undefined);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Vídeos"
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Título",
								accessor: "title",
								width: 200
							},
							{
								Header: "Link",
								accessor: "link",
								Cell: props => (
									<a
										href={props.value}
										target="_blank"
										rel="noopener noreferrer"
									>
										{props.value}
									</a>
								),
								width: 250
							},
							{
								Header: "Descrição",
								accessor: "description"
							},
							{
								Header: "Status",
								id: "status",
								accessor: c => ls[videoStatus[c.status]],
								width: 140,
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(videoStatus).map(status => (
												<option key={status} value={status}>
													{ls[videoStatus[status]]}
												</option>
											))}
										</select>
									);
								}
							},
							{
								Header: "Ações",
								width: 120,
								filterable: false,
								Cell: props => (
									<ButtonToolbar>
										<ButtonGroup className="btn-group--icons" dir="ltr">
											<Button
												color="primary"
												id={`view-video-tooltip-${props.original._id}`}
												onClick={() =>
													window.open(props.original.link, "_blank")
												}
											>
												<MdRemoveRedEye style={{ margin: 0 }} />
											</Button>
											<TooltipComponent
												target={`view-video-tooltip-${props.original._id}`}
												title="Ver vídeo"
											/>
											{/* <Button
												color="export"
												onClick={handleRouteAddVideos}
												id="add-videos"
											>
												<IoMdAdd style={{ margin: 0 }} />
											</Button>
											<TooltipComponent
												title="Adicionar vídeos"
												target="add-videos"
											/> */}
										</ButtonGroup>
									</ButtonToolbar>
								)
							}
						]}
					/>
				</Col>
			</Row>
			<Delete load={load} />
			<Edit load={load} />
		</Container>
	);
}

export default Report;
