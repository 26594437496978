import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { reset } from "redux-form";

import { submitChangePassword } from "./actions";
import ChangeForm from "./components/changeForm";
import Modal from "components/Modal";

const ChangePassword = () => {
	const dispatch = useDispatch();
	const { loading } = useSelector(s => s.userCreate);

	const [message, setMessage] = useState("");
	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const onSubmit = values => {
		dispatch(
			submitChangePassword(values.password, values.confirmPassword, err => {
				if (err) {
					console.log(err);
					setMessage("Ocorreu um erro ao alterar a senha!", err);
					setShowModal(true);
				} else {
					dispatch(reset("change_password"));
					setMessage("Senha alterada com sucesso!");
					setShowModal(true);
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Cadastrar Usuário</h5>
							</div>
							<ChangeForm onSubmit={onSubmit} loading={loading} />
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Modal
				title="Alterar Senha"
				isOpen={showModal}
				toggle={toggleModal}
				size="md"
			>
				{message}
			</Modal>
		</Container>
	);
};

export default ChangePassword;
