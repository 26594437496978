import ReportTable from "components/Report";
import dayjs from "dayjs";
import ls from "Localization";
import _ from "lodash";
import React, { useRef } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import Delete from "../Delete";
import * as actions from "./actions";
import DateSelectFilter from "components/Report/DateSelectorFilter";

let fetchDataDebounced = null;

function Report({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.productCategories);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getProductCategories(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	const handleDeleteClick = data => {
		dispatch(
			actions.setValue({
				showDeleteForm: data
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Categorias de produto"
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateTimeFormatWithoutSeconds)
										: "",
								width: 120,
								Filter: p => <DateSelectFilter {...p} />
							},
							{
								Header: "Nome",
								accessor: "name"
							},
							{
								Header: "Ações",
								width: 80,
								filterable: false,
								id: "actions",
								Cell: props => {
									return (
										<ButtonToolbar>
											<ButtonGroup className="btn-group--icons" dir="ltr">
												<Button
													color="primary"
													onClick={() =>
														history.push(
															`/productCategory/${props.original._id}/edit`
														)
													}
												>
													<MdEdit style={{ margin: 0 }} />
												</Button>

												<Button
													color="cancel"
													onClick={() => handleDeleteClick(props.original)}
												>
													<MdDelete style={{ margin: 0 }} />
												</Button>
											</ButtonGroup>
										</ButtonToolbar>
									);
								}
							}
						]}
					/>
				</Col>
			</Row>
			<Delete load={load} />
		</Container>
	);
}

export default Report;
