import React from "react";
import { Route, Switch } from "react-router-dom";
import Report from "./containers/Report";
import Detail from "./containers/Detail";
import Create from "./containers/Create";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/user" component={Report} />
				<Route exact path="/user/new" component={Create} />
				<Route exact path="/user/:id" component={Detail} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
