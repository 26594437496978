import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_LANE = "[LANE REPORT] GET_LANE";
export const GET_LANE_ERROR = "[LANE REPORT] GET_LANE_ERROR";
export const GET_LANE_SUCCESS = "[LANE REPORT] GET_LANE_SUCCESS";

export const SET_VALUE = "[LANE REPORT] SET_VALUE";
export const CLEAR_VALUES = "[LANE REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getLane(take, toExport, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LANE
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/lane`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_LANE_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_LANE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_LANE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_LANE = "[LANE EDIT] EDIT_LANE";
export const EDIT_LANE_ERROR = "[LANE EDIT] EDIT_LANE_ERROR";
export const EDIT_LANE_SUCCESS = "[LANE EDIT] EDIT_LANE_SUCCESS";

export function editlane(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_LANE
		});

		console.log(model, "model");

		try {
			const { login } = getState();

			let response = await api.sendPut(`/lane/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_LANE_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_LANE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_LANE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
