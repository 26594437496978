const validate = values => {
	const errors = {};

	const requiredFields = ["product", "quantity"];

	for (let field of requiredFields) {
		if (!values[field]) errors[field] = `Campo obrigatório`;
	}

	if (values["product"] === "add" && !values["productName"]) {
		errors["productName"] = `Campo obrigatório`;
	}

	if (
		values["product"] === "add" &&
		!Number(
			String(values["consumption"])
				.match(/\d/g)
				?.join("")
		)
	) {
		errors["consumption"] = `Campo obrigatório`;
	}

	return errors;
};

export default validate;
