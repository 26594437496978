import ReportTable from "components/Report";
import React, { useRef } from "react";
import { useMemo } from "react";
import { Col, Container, Row } from "reactstrap";

function Report({ items }) {
	const table = useRef(null);

	const columns = useMemo(() => {
		return [
			{
				Header: "Link",
				id: "url",
				accessor: c => (
					<a href={c.url} target="_blank" rel="noreferrer">
						{c.url}
					</a>
				)
			}
		];
	}, []);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						title="Documentação profissional"
						tableRef={table}
						data={items}
						filterable={false}
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						columns={columns}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Report;
