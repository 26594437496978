/* eslint-disable no-undef */
//import validate from "containers/Company/components/validate";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal";
//import { SubmissionError } from "redux-form";
import EditForm from "../../components/editForm";
import { editCashback, setValue } from "../Report/actions";
import { toStringCurrency } from "helpers/string";
function Edit({ load }) {
	const dispatch = useDispatch();

	const { loading, item, showEditForm } = useSelector(s => s.cashback);

	if (!item) return null;

	const onSubmit = values => {
		console.log(values);
		const data = {
			...values,
			value: values.value.match(/\d/g).join(""),
			document: values.description
		};

		dispatch(
			editCashback(item._id, data, err => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					closeModal();
					load && load();
				}
			})
		);
	};

	const closeModal = () => {
		dispatch(setValue({ showEditForm: false }));
	};

	const initialValues = {
		description: item.description,
		value: toStringCurrency(item.value / 100.0)
	};

	return (
		<Modal
			toggle={closeModal}
			isOpen={showEditForm}
			size="xl"
			title="Editar Orçamento"
		>
			<EditForm
				closeModal={closeModal}
				initialValues={initialValues}
				onSubmit={onSubmit}
				loading={loading}
			/>
		</Modal>
	);
}

export default Edit;
