import React, { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Report from "./containers/Report";
import Detail from "./containers/Detail";
import Create from "./containers/Create";
import Builder from "./containers/Builder";
import Responsibility from "./containers/Responsibility";
import { isInRole } from "helpers/auth";
import { useSelector } from "react-redux";
import userRole from "models/userRole";

const routes = () => {
	const { user } = useSelector(s => s.login);

	const isBuilder = useMemo(() => {
		if (!user) return false;

		return isInRole(user, [userRole.BUILDER]);
	}, [user]);

	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/company" component={Report} />
				<Route exact path="/company/builder" component={Builder} />
				<Route exact path="/company/new" component={Create} />
				<Route
					exact
					path="/company/responsibility"
					component={Responsibility}
				/>
				<Route exact path="/company/:id" component={Detail} />
				{isBuilder && <Redirect to="/company/builder" />}
			</Switch>
		</React.Fragment>
	);
};

export default routes;
