import { CNPJ } from "components/NumberFormat";
import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { FiLink } from "react-icons/fi";
import {
	Button,
	Card,
	Col,
	Container,
	Row,
	Spinner,
	Tooltip
} from "reactstrap";

const BuilderDetail = ({ item, loading, changeFileStatus }) => {
	const [tooltipOpen, setTooltipOpen] = useState("");

	const toggle = file => setTooltipOpen(s => (s === file ? "" : file));

	const handleChangeFileStatus = (file, status) => {
		if (status === 2) {
			const reason = prompt("Qual o motivo da reprovação?");

			if (reason) {
				changeFileStatus(file, status, reason);
			}
		} else {
			changeFileStatus(file, status);
		}
	};

	const renderDocument = () => {
		if (loading.getBuilder) return null;

		if (!item?.builder) return <div>Montadora não cadastrada</div>;

		const { builder } = item;

		return (
			<Container>
				<Row>
					<Col>
						<Row>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Razão Social: </strong>
									<br />
									<span>{builder.name ? builder.name : "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Nome Fantasia: </strong>
									<br />
									<span>
										{builder.socialName ? builder.socialName : "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								{builder.document ? (
									<CNPJ
										displayType="text"
										value={builder.document}
										renderText={v => {
											return (
												<p>
													<strong>CNPJ: </strong>
													<br />
													<span>{v}</span>
												</p>
											);
										}}
									/>
								) : (
									<p>
										<strong>CNPJ: </strong>
										<br />
										<span>Não definido</span>
									</p>
								)}
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Email: </strong>
									<br />
									<span>{builder.email ? builder.email : "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Telefone: </strong>
									<br />
									<span>
										{builder.nationalPhoneNumber
											? builder.nationalPhoneNumber
											: "Não definido"}
									</span>
								</p>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col className="mt-3" xs={12} md={6}>
						<p>
							<strong>Necessita de ponto de água e deságue?</strong>
							<br />
							<span>
								{item.needWaterAndDrain === true
									? "Sim"
									: item.needWaterAndDrain === false
									? "Não"
									: "Não informado"}
							</span>
						</p>
					</Col>
					<Col className="mt-3" xs={12} md={6}>
						<p>
							<strong>Necessita de ponto elétrico trifásico adicional?</strong>
							<br />
							<span>
								{item.needAdditionalThreePhasePower === true
									? "Sim"
									: item.needAdditionalThreePhasePower === false
									? "Não"
									: "Não informado"}
							</span>
						</p>
					</Col>
					<Col className="mt-3" xs={12} md={6}>
						<p>
							<strong>ART/RRT Execução</strong>
							<br />
							{item.artExecution ? (
								<>
									<a
										href={item.artExecution.url}
										target="_blank"
										rel="noopener noreferrer"
										className="d-flex align-items-center"
									>
										<Button color="primary" size="sm" id="view-link">
											<FiLink />
										</Button>
										<span className=" m-lg-2">Visualizar</span>
									</a>
									<Button
										color="primary"
										id="art-execution-reason"
										disabled={item.artExecution.status > 0}
										onClick={() => handleChangeFileStatus("artExecution", 1)}
									>
										{item.artExecution.status === 1
											? "Aprovado"
											: item.artExecution.status === 2
											? "Reprovado"
											: "Aprovar"}
									</Button>
									{item.artExecution.reason && (
										<Tooltip
											placeholder="top"
											isOpen={tooltipOpen === "art-execution-reason"}
											target="art-execution-reason"
											toggle={() => toggle("art-execution-reason")}
										>
											{item.artExecution.reason}
										</Tooltip>
									)}
									{item.artExecution.status === 0 && (
										<Button
											color="secondary"
											onClick={() => handleChangeFileStatus("artExecution", 2)}
										>
											Reprovar
										</Button>
									)}
								</>
							) : (
								<span>Não enviado</span>
							)}
						</p>
					</Col>

					<Col className="mt-3" xs={12} md={6}>
						<p>
							<strong>ART/RRT Projeto</strong>
							<br />
							{item.artProject ? (
								<>
									{item.artProject.reason && (
										<>
											<AiFillEye
												style={{ margin: 0 }}
												id="art-project-reason"
											/>

											<Tooltip
												placeholder="top"
												isOpen={tooltipOpen === "art-project-reason"}
												target="art-project-reason"
												toggle={() => toggle("art-project-reason")}
											>
												{item.artProject.reason}
											</Tooltip>
										</>
									)}
									<a
										href={item.artProject.url}
										target="_blank"
										rel="noopener noreferrer"
										className="d-flex align-items-center"
									>
										<Button color="primary" size="sm">
											<FiLink />
										</Button>
										<span className=" m-lg-2">Visualizar</span>
									</a>

									<Button
										color="primary"
										disabled={item.artProject.status > 0}
										onClick={() => handleChangeFileStatus("artProject", 1)}
									>
										{item.artProject.status === 1
											? "Aprovado"
											: item.artProject.status === 2
											? "Reprovado"
											: "Aprovar"}
									</Button>
									{item.artProject.status === 0 && (
										<Button
											color="secondary"
											onClick={() => handleChangeFileStatus("artProject", 2)}
										>
											Reprovar
										</Button>
									)}
								</>
							) : (
								<span>Não enviado</span>
							)}
						</p>
					</Col>

					<Col className="mt-3" xs={12} md={6}>
						<p>
							<strong>Planta baixa</strong>
							<br />
							{item.blueprint ? (
								<>
									<a
										href={item.blueprint.url}
										target="_blank"
										rel="noopener noreferrer"
										className="d-flex align-items-center"
									>
										<Button color="primary" size="sm">
											<FiLink />
										</Button>
										<span className=" m-lg-2">Visualizar</span>
									</a>

									<Button
										color="primary"
										disabled={item.blueprint.status > 0}
										onClick={() => handleChangeFileStatus("blueprint", 1)}
									>
										{item.blueprint.status === 1
											? "Aprovado"
											: item.blueprint.status === 2
											? "Reprovado"
											: "Aprovar"}
									</Button>
									{item.blueprint.status === 0 && (
										<Button
											color="secondary"
											onClick={() => handleChangeFileStatus("blueprint", 2)}
										>
											Reprovar
										</Button>
									)}
								</>
							) : (
								<span>Não enviado</span>
							)}
						</p>
					</Col>

					<Col className="mt-3" xs={12} md={6}>
						<p>
							<strong>Vista</strong>
							<br />
							{item.view ? (
								<>
									<a
										href={item.view.url}
										target="_blank"
										rel="noopener noreferrer"
										className="d-flex align-items-center"
									>
										<Button color="primary" size="sm">
											<FiLink />
										</Button>
										<span className=" m-lg-2">Visualizar</span>
									</a>

									<Button
										color="primary"
										disabled={item.view.status > 0}
										onClick={() => handleChangeFileStatus("view", 1)}
									>
										{item.view.status === 1
											? "Aprovado"
											: item.view.status === 2
											? "Reprovado"
											: "Aprovar"}
									</Button>
									{item.view.status === 0 && (
										<Button
											color="secondary"
											onClick={() => handleChangeFileStatus("view", 2)}
										>
											Reprovar
										</Button>
									)}
								</>
							) : (
								<span>Não enviado</span>
							)}
						</p>
					</Col>

					<Col className="mt-3" xs={12} md={6}>
						<p>
							<strong>3D Volumetria</strong>
							<br />
							{item.volumetric3D ? (
								<>
									<a
										href={item.volumetric3D.url}
										target="_blank"
										rel="noopener noreferrer"
										className="d-flex align-items-center"
									>
										<Button color="primary" size="sm">
											<FiLink />
										</Button>
										<span className=" m-lg-2">Visualizar</span>
									</a>

									<Button
										color="primary"
										disabled={item.volumetric3D.status > 0}
										onClick={() => handleChangeFileStatus("volumetric3D", 1)}
									>
										{item.volumetric3D.status === 1
											? "Aprovado"
											: item.volumetric3D.status === 2
											? "Reprovado"
											: "Aprovar"}
									</Button>
									{item.volumetric3D.status === 0 && (
										<Button
											color="secondary"
											onClick={() => handleChangeFileStatus("volumetric3D", 2)}
										>
											Reprovar
										</Button>
									)}
								</>
							) : (
								<span>Não enviado</span>
							)}
						</p>
					</Col>

					<Col className="mt-3" xs={12} md={6}>
						<p>
							<strong>Apólice de seguro</strong>
							<br />
							{item.insurancePolicy ? (
								<>
									<a
										href={item.insurancePolicy.url}
										target="_blank"
										rel="noopener noreferrer"
										className="d-flex align-items-center"
									>
										<Button color="primary" size="sm">
											<FiLink />
										</Button>
										<span className=" m-lg-2">Visualizar</span>
									</a>

									<Button
										color="primary"
										disabled={item.insurancePolicy.status > 0}
										onClick={() => handleChangeFileStatus("insurancePolicy", 1)}
									>
										{item.insurancePolicy.status === 1
											? "Aprovado"
											: item.insurancePolicy.status === 2
											? "Reprovado"
											: "Aprovar"}
									</Button>
									{item.insurancePolicy.status === 0 && (
										<Button
											color="secondary"
											onClick={() =>
												handleChangeFileStatus("insurancePolicy", 2)
											}
										>
											Reprovar
										</Button>
									)}
								</>
							) : (
								<span>Não enviado</span>
							)}
						</p>
					</Col>
				</Row>
			</Container>
		);
	};
	return (
		<div>
			{loading.getBuilder && <Spinner type="grow" size="sm" color="dark" />}
			<Card>{renderDocument()}</Card>
		</div>
	);
};

export default BuilderDetail;
