import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { reset } from "redux-form";
import { upload } from "redux/actions/upload";
import CreateEventForm from "../../components/CreateForm";
import { createEvents } from "./actions";
import eventTypeOptions from "../../../../models/eventTypeOptions";
import ls from "Localization";

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.eventCreate);

	const onSubmit = async values => {
		const data = {
			title: values.title,
			description: values.description,
			video: values.video,
			when: values.when,
			until: values.until,
			capacity: values.capacity,
			type: values.type?.value
		};

		if (values.bannerFile && values.bannerFile[0]) {
			try {
				data.banner = await new Promise((res, rej) =>
					dispatch(
						upload("event", values.bannerFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				return;
			}
		}

		dispatch(
			createEvents(data, (err, event) => {
				if (err) {
					console.log(err);
					alert(err && JSON.stringify(err));
				} else {
					dispatch(reset("event_create"));
					history.push("/event/" + event._id);
				}
			})
		);
	};

	const typeOptions = useMemo(
		() =>
			Object.keys(eventTypeOptions).map(c => ({
				value: c,
				label: ls[eventTypeOptions[c]]
			})),
		[]
	);

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Cadastrar</h5>
							</div>
							<CreateEventForm
								onSubmit={onSubmit}
								loading={loading}
								initialValues={{ capacity: 0 }}
								typeOptions={typeOptions}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
export default Create;
