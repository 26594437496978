import dayjs from "dayjs";
import ls from "Localization";
import React from "react";
import { Card, Col, Container, Row, Spinner } from "reactstrap";

const NewsDatails = ({ news, loading }) => {
	if (!news) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{news.createdAt
												? dayjs(news.createdAt).format(ls.dateTimeFormat)
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Titulo</strong>
										<br />
										<span>{news.title ? news.title : "Não definido"}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Descrição</strong>
										<br />
										<span>
											{news.description ? news.description : "Não definido"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Imagem</strong>
										<br />
										<span>{news.banner ? news.banner : "Não definido"}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Video</strong>
										<br />
										<span>{news.video ? news.video : "Não definido"}</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default NewsDatails;
