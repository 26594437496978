import actions from "../../actions";

const initialState = {
	show: false,
	collapse: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.sidebar.CHANGE_SIDEBAR_VISIBILITY:
			return { ...state, collapse: !state.collapse };
		case actions.sidebar.CHANGE_MOBILE_SIDEBAR_VISIBILITY:
			return { ...state, show: !state.show };
		default:
			return state;
	}
}
