import { validateEmail } from "helpers/email";
import { CPFValidation } from "helpers/validators";

const validate = values => {
	const errors = {};

	const requiredFields = ["user", "value"];

	for (let field of requiredFields) {
		if (!values[field]) errors[field] = `Campo obrigatório`;
	}

	if (values.user) {
		if (!CPFValidation(values.user) && !validateEmail(values.user)) {
			errors.user = "Informe um CPF ou E-mail válido";
		}
	}

	const value =
		typeof values.value === "string" ? values.value : values.value?.toFixed(2);

	if (Number(value?.match(/\d/g)?.join("") || 0) < 500) {
		errors.value = "O valor mínimo para cashback é R$ 5,00";
	}

	return errors;
};

export default validate;
