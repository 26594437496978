import * as actions from "./actions";

const initialState = {
	items: [],
	filter: [],
	pages: null,
	count: 0,
	loading: {},
	showDeleteForm: false,
	showEditForm: false
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_VIDEOS: {
			return {
				...state,
				loading: { ...state.loading, getAll: true }
			};
		}
		case actions.GET_VIDEOS_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { ...state.loading, getAll: false }
			};
		}
		case actions.GET_VIDEOS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { ...state.loading, getAll: false }
			};
		}

		case actions.EDIT_VIDEOS_EDIT: {
			return {
				...state,
				loading: { ...state.loading, edit: true }
			};
		}
		case actions.EDIT_VIDEOS_EDIT_SUCCESS: {
			// Assuming payload contains the updated video item
			const updatedItems = state.items.map(item =>
				item.id === payload.id ? payload : item
			);
			return {
				...state,
				items: updatedItems,
				loading: { ...state.loading, edit: false }
			};
		}
		case actions.EDIT_VIDEOS_EDIT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { ...state.loading, edit: false }
			};
		}

		case actions.DELETE_VIDEOS_DELETE: {
			return {
				...state,
				loading: { ...state.loading, delete: true }
			};
		}
		case actions.DELETE_VIDEOS_DELETE_SUCCESS: {
			// Assuming payload is the ID of the deleted video
			const filteredItems = state.items.filter(item => item.id !== payload);
			return {
				...state,
				items: filteredItems,
				loading: { ...state.loading, delete: false }
			};
		}
		case actions.DELETE_VIDEOS_DELETE_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { ...state.loading, delete: false }
			};
		}

		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUES: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
