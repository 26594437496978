import * as api from "services/api";
import { getErrors } from "helpers/error";

export const CHANGE_PASSWORD = "[USERS] CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "[USERS] CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "[USERS] CHANGE_PASSWORD_FAILED";

export const SET_VALUE = "[USERS] SET_VALUE";
export const CLEAR_VALUES = "[USERS] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function submitChangePassword(password, confirmPassword, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_PASSWORD
		});

		const { token, user } = getState().login;

		if (!token) {
			return dispatch(setValue({ loading: { change: false } }));
		}

		try {
			let email = user.email;
			let response = await api.sendPut(
				`/account/${user._id}/changePassword`,
				{
					email,
					password,
					confirmPassword
				},
				{
					Authorization: `Bearer ${token}`
				}
			);

			if (response.ok) {
				dispatch({
					type: CHANGE_PASSWORD_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: CHANGE_PASSWORD_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			dispatch({
				type: CHANGE_PASSWORD_FAILED,
				payload: {
					error: "Connection error"
				}
			});

			callback && callback(error);
		}
	};
}
