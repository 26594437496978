import { MONEY } from "components/NumberFormat";
import Input from "components/ReduxForm/Input";
import React, { useRef } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import TextArea from "components/ReduxForm/TextArea";

const createForm = ({ handleSubmit, reset, loading }) => {
	const form = useRef(null);

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} md={8} lg={9} className="form__form-group">
						<span className="form__form-group-label">
							CPF ou E-mail cadastrado no sistema
						</span>
						<div className="form__form-group-field">
							<Field
								name="user"
								component={Input}
								type="text"
								placeholder="557.563.587-64 ou nome@gmail.com"
							/>
						</div>
					</Col>
					<Col xs={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Cashback</span>
						<div className="form__form-group-field">
							<Field
								name="value"
								component={MONEY}
								type="text"
								placeholder="30,00"
							/>
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field
								name="description"
								component={TextArea}
								type="text"
								autoFocus
								placeholder="Ex.: Cashback referente a compra..."
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "cashback_create",
	validate
})(createForm);
