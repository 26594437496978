export function extractProductsFromJson(data) {
	const products = [];

	for (const p of data) {
		products.push({
			code: p.cod,
			name: p.chave.trim(),
			category: p.item,
			utility: p.utilidade,
			size: p.medida,
			brand: p.marca,
			costPrice: p.preco || 0,
			canvas: p.lonas,
			specification: p.especificacao,
			indice: p.indice,
			modelProduct: p.modelo,
			manufacturer: String(p.fabricante || "")
				.replace("Fabricante:", "")
				.trim(),
			origin: p.origem,
			customsCode: p["cod. alfandegario"]
		});
	}

	return products;
}
