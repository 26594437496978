import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";

import { clearValues, getNews } from "./actions";
import { setValue } from "../Report/actions";

import DetailsPerson from "../../components/detailForm";
import Edit from "../Edit";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();
	const { item, loading } = useSelector(state => state.newsDetails);

	const load = () => {
		dispatch(getNews(id));
	};

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, [dispatch]);

	const handleEditClick = () => {
		dispatch(
			setValue({
				showEditForm: true,
				item
			})
		);
	};

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes da noticia</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
								<div>
									<Button onClick={handleEditClick} color="primary">
										Editar
									</Button>
								</div>
							</Row>
							<DetailsPerson news={item} loading={loading} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Edit load={load} />
		</Container>
	);
}

export default Details;
