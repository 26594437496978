import React, { Component } from "react";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import getIcon from "./Icon";
import { isInRole } from "helpers/auth";

export default class SidebarCategory extends Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		icon: PropTypes.any,
		isNew: PropTypes.bool,
		children: PropTypes.arrayOf(PropTypes.element).isRequired
	};

	static defaultProps = {
		icon: "",
		isNew: false
	};

	constructor() {
		super();
		this.state = {
			collapse: false
		};
	}

	toggle = () => {
		this.setState(prevState => ({ collapse: !prevState.collapse }));
	};

	render() {
		const { title, icon, isNew, children, roles, user } = this.props;
		const { collapse } = this.state;
		const categoryClass = classNames({
			"sidebar__category-wrap": true,
			"sidebar__category-wrap--open": collapse
		});

		if (roles && roles.length) {
			if (!isInRole(user, roles)) return null;
		}

		return (
			<div className={categoryClass}>
				<button
					type="button"
					className="sidebar__link sidebar__category"
					onClick={this.toggle}
				>
					{getIcon(icon)}
					<p className="sidebar__link-title">
						{title}
						{isNew && <span className="sidebar__category-new" />}
					</p>
					<span className="sidebar__category-icon lnr lnr-chevron-right" />
				</button>
				<Collapse isOpen={collapse} className="sidebar__submenu-wrap">
					<ul className="sidebar__submenu">
						<div>{children}</div>
					</ul>
				</Collapse>
			</div>
		);
	}
}
