import { CPF, PERCENTAGE2 } from "components/NumberFormat";
import dayjs from "dayjs";
import ls from "Localization";
import React from "react";
import { Button, Card, Col, Container, Row, Spinner } from "reactstrap";

const CompanyDetail = ({
	company,
	changeResponsibilityStatus,
	loading,
	isAdmin
}) => {
	if (!company) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{company.createdAt
												? dayjs(company.createdAt).format(ls.dateTimeFormat)
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Razão Social: </strong>
										<br />
										<span>{company.name ? company.name : "Não definido"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nome Fantasia: </strong>
										<br />
										<span>
											{company.socialName ? company.socialName : "Não definido"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={8} md={4} xl={2}>
									<p>
										<strong>Segmento: </strong>
										<br />
										<span>
											{company.segment ? company.segment : "Não definido"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={4} md={3} xl={1}>
									<p>
										<strong>Stand: </strong>
										<br />
										<span>
											{company.stand ? company.stand : "Não definido"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									{company.document ? (
										<CPF
											displayType="text"
											value={company.document}
											renderText={v => {
												return (
													<p>
														<strong>CNPJ: </strong>
														<br />
														<span>{v}</span>
													</p>
												);
											}}
										/>
									) : (
										<p>
											<strong>CNPJ: </strong>
											<br />
											<span>Não definido</span>
										</p>
									)}
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Email: </strong>
										<br />
										<span>
											{company.email ? company.email : "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Telefone: </strong>
										<br />
										<span>
											{company.nationalPhoneNumber
												? company.nationalPhoneNumber
												: "Não definido"}
										</span>
									</p>
								</Col>
								{isAdmin && (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>Taxa de cashback: </strong>
											<br />
											<span>
												<PERCENTAGE2
													displayType="text"
													value={company.cashbackFee / 100.0}
												/>
											</span>
										</p>
									</Col>
								)}
							</Row>
						</Col>
					</Row>
					{isAdmin && (
						<>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Sobre: </strong>
										<br />
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={12}>
									<p>
										<span>{company.about || "Não definido"}</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Endereço: </strong>
										<br />
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={12}>
									<p>
										<span>
											{company.address
												? company.address.fullAddress
												: "Não definido"}
										</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} md={6}>
									<p>
										<strong>Logo</strong>
										<br />
										<span>{company.logo ? company.logo : "Não definido"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} md={6}>
									<p>
										<strong>Banner</strong>
										<br />
										<span>
											{company.banner ? company.banner : "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} md={6}>
									<p>
										<strong>Catalogo</strong>
										<br />
										<span>
											{company.catalog ? company.catalog : "Não definido"}
										</span>
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} md={6}>
									<p>
										<strong>Termo de responsabilidade</strong>
										<br />
										<span>
											{company.responsibilityFile ? (
												<a
													href={company.responsibilityFile.url}
													target="_blank"
												>
													Abrir termo
												</a>
											) : (
												"Não enviado"
											)}
										</span>
										<br />
										{company.responsibilityFile && (
											<>
												<Button
													color="primary"
													disabled={company.responsibilityFile?.status > 0}
													onClick={() => changeResponsibilityStatus(1)}
												>
													{company.responsibilityFile?.status === 1
														? "Aprovado"
														: company.responsibilityFile?.status === 2
														? "Reprovado"
														: "Aprovar"}
												</Button>
												{company.responsibilityFile?.status === 0 && (
													<Button
														color="secondary"
														onClick={() => changeResponsibilityStatus(2)}
													>
														Reprovar
													</Button>
												)}
											</>
										)}
									</p>
								</Col>
							</Row>
						</>
					)}
				</Container>
			</Card>
		</div>
	);
};

export default CompanyDetail;
