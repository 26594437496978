import React, { useEffect, useMemo, useRef } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import dayjs from "dayjs";
import ls from "Localization";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import Delete from "./Delete";
import TooltipComponent from "components/TooltipComponent";

import DateSelectFilter from "components/Report/DateSelectorFilter";
import { isInRole } from "helpers/auth";

let fetchDataDebounced = null;

function ConsumptionItems({ history, companyId }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const { user } = useSelector(s => s.login);
	const state = useSelector(s => s.consumptionItem);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getConsumptionItems(
					page * pageSize,
					pageSize,
					filtered.reduce(
						(p, c) => p + `&filters[${c.id}]=${c.value}`,
						companyId ? `&filters[company]=${companyId}` : ""
					),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	const handleDeleteClick = data => {
		console.log(data);
		dispatch(
			actions.setValue({
				showDeleteForm: data
			})
		);
	};

	useEffect(() => {
		dispatch(actions.clearValues());
		load();
	}, [companyId]);

	const handleEditClick = data => {
		dispatch(
			actions.setValue({
				showEditForm: true,
				item: data
			})
		);
	};

	const addNewItem = () => {
		history.push("/consumption/item/new");
	};

	const isAdmin = useMemo(() => {
		return isInRole(user, ["admin"]);
	}, [user]);

	const columns = useMemo(() => {
		return [
			// {
			// 	Header: "Data criação",
			// 	id: "createdAt",
			// 	accessor: c =>
			// 		c.createdAt ? dayjs(c.createdAt).format(ls.dateTimeFormat) : "",
			// 	width: 120,
			// 	Filter: p => <DateSelectFilter {...p} />
			// },
			isAdmin
				? {
						Header: "Expositor",
						accessor: d => (d.company ? d.company.name : "Não definido"),
						id: "company"
				  }
				: null,
			{
				Header: "Descrição",
				accessor: "name"
			},
			{
				Header: "Consumo (kva)",
				accessor: "consumption",
				width: 150
			},
			{
				Header: "Ações",
				width: 80,
				filterable: false,
				id: "actions",
				Cell: props => (
					<ButtonToolbar>
						<ButtonGroup className="btn-group--icons" dir="ltr">
							<Button
								color="cancel"
								onClick={() => handleDeleteClick(props.original)}
							>
								<MdDelete style={{ margin: 0 }} />
							</Button>
						</ButtonGroup>
					</ButtonToolbar>
				)
			}
		].filter(Boolean);
	}, [user, isAdmin]);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title={"Itens de consumo"}
						tableRef={table}
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						headerRightComponent={
							isAdmin ? (
								<>
									<Button
										style={{
											marginLeft: "auto"
										}}
										id="add-new-item"
										color="primary"
										onClick={addNewItem}
									>
										<FaPlus />
									</Button>
									<TooltipComponent
										target="add-new-item"
										title="Adicionar novo item"
									/>
								</>
							) : null
						}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={columns}
					/>
				</Col>
			</Row>
			<Delete load={load} />
		</Container>
	);
}

export default ConsumptionItems;
