import * as actions from "./actions";

const initialState = {
	loading: { register: false },
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.REGISTER: {
			return {
				...state,
				loading: { ...state.loading, register: true },
				errors: initialState.errors
			};
		}
		case actions.REGISTER_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.REGISTER_ERROR: {
			return {
				...state,
				loading: { ...state.loading, register: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
