import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_NOTIFICATIONS = "[NOTIFICATION REPORT] GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_ERROR =
	"[NOTIFICATION REPORT] GET_NOTIFICATIONS_ERROR";
export const GET_NOTIFICATIONS_SUCCESS =
	"[NOTIFICATION REPORT] GET_NOTIFICATIONS_SUCCESS";

export const EDIT_NOTIFICATIONS = "[NOTIFICATION REPORT] EDIT_NOTIFICATIONS";
export const EDIT_NOTIFICATIONS_ERROR =
	"[NOTIFICATION REPORT] EDIT_NOTIFICATIONS_ERROR";
export const EDIT_NOTIFICATIONS_SUCCESS =
	"[NOTIFICATION REPORT] EDIT_NOTIFICATIONS_SUCCESS";

export const SET_VALUE = "[NOTIFICATION REPORT] SET_VALUE";
export const CLEAR_VALUES = "[NOTIFICATION REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getNotifications(skip, take, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_NOTIFICATIONS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/Notification?skip=${skip}&take=${take}&sort=createdAt&desc=true`,
				{
					Authorization: "Bearer " + login.token
				}
			);
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_NOTIFICATIONS_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_NOTIFICATIONS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_NOTIFICATIONS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function editNotification(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_NOTIFICATIONS
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/Notification/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_NOTIFICATIONS_SUCCESS
				});

				callback && callback(null);
			} else {
				dispatch({
					type: EDIT_NOTIFICATIONS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_NOTIFICATIONS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
