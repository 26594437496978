import React from "react";
import { Button, Row } from "reactstrap";
import viagensImage from "../../../../../assets/trip/inova_page-01.jpg";

const BuilderTrip = () => {
	const handleContact = () => {
		window.open(
			"https://api.whatsapp.com/send?phone=5547991767060&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20feita%20Construir%20Ai.",
			"_blank"
		);
	};

	return (
		<Row>
			<div style={{ position: "relative", width: "100%", marginTop: -40 }}>
				<img
					src={viagensImage}
					alt="Viagens"
					style={{ width: "100%", height: "auto" }}
				/>
				<Button
					style={{
						position: "absolute",
						bottom: -30,
						left: 0,
						right: 0,
						padding: "18px 100px",
						fontSize: "1.2em"
					}}
					color="primary"
					onClick={handleContact}
				>
					Reserve agora
				</Button>
			</div>
		</Row>
	);
};

export default BuilderTrip;
