import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import React, { useRef, useState } from "react";
import renderSelectField from "shared/components/form/Select";
import UploadFile from "components/ReduxForm/UploadFile";

import validate from "./validate";

const EditForm = ({ handleSubmit, reset, loading, yesNoOptions, onSubmit }) => {
	const form = useRef(null);

	return (
		<form ref={form} className="form" onSubmit={handleSubmit(onSubmit)}>
			<Container>
				<Row>
					<Col xs={12} sm={6} className="form__form-group">
						<span className="form__form-group-label">
							Necessita de ponto de água e deságue?
						</span>
						<div className="form__form-group-field">
							<Field
								name="needWaterAndDrain"
								component={renderSelectField}
								type="text"
								options={yesNoOptions}
								placeholder="Selecione"
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} className="form__form-group">
						<span className="form__form-group-label">
							Necessita de ponto elétrico trifásico adicional?
						</span>
						<div className="form__form-group-field">
							<Field
								name="needAdditionalThreePhasePower"
								component={renderSelectField}
								type="text"
								options={yesNoOptions}
								placeholder="Selecione"
							/>
						</div>
					</Col>
				</Row>
				<Row>
				
					<Col xs={12} md={6} lg={4} className="form__form-group">
						<span className="form__form-group-label">ART/RRT Execução</span>
						<div className="form__form-group-field">
							<Field
								name="artExecutionFile"
								component={UploadFile}
								accept="application/pdf"
							/>
						</div>
					</Col>
					<Col xs={12} md={6} lg={4} className="form__form-group">
						<span className="form__form-group-label">ART/RRT Projeto</span>
						<div className="form__form-group-field">
							<Field
								name="artProjectFile"
								component={UploadFile}
								accept="application/pdf"
							/>
						</div>
					</Col>
					<Col xs={12} md={6} lg={4} className="form__form-group">
						<span className="form__form-group-label">Planta baixa</span>
						<div className="form__form-group-field">
							<Field
								name="blueprintFile"
								component={UploadFile}
								accept="application/pdf"
							/>
						</div>
					</Col>
					<Col xs={12} md={6} lg={4} className="form__form-group">
						<span className="form__form-group-label">Vista</span>
						<div className="form__form-group-field">
							<Field
								name="viewFile"
								component={UploadFile}
								accept="application/pdf"
							/>
						</div>
					</Col>
					<Col xs={12} md={6} lg={4} className="form__form-group">
						<span className="form__form-group-label">3D Volumetria</span>
						<div className="form__form-group-field">
							<Field
								name="volumetric3DFile"
								component={UploadFile}
								accept="application/pdf"
							/>
						</div>
					</Col>
					<Col xs={12} md={6} lg={4} className="form__form-group">
						<span className="form__form-group-label">Apólice de seguro</span>
						<div className="form__form-group-field">
							<Field
								name="insurancePolicyFile"
								component={UploadFile}
								accept="application/pdf"
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={loading.edit}
				>
					{loading.edit ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "editBuilderForm",
	validate
})(EditForm);
