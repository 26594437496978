import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const CREATE_BUILDER = "[BUILDER CREATE] CREATE_BUILDER";
export const CREATE_BUILDER_ERROR = "[BUILDER CREATE] CREATE_BUILDER_ERROR";
export const CREATE_BUILDER_SUCCESS = "[BUILDER CREATE] CREATE_BUILDER_SUCCESS";

export const SET_VALUE = "[BUILDER CREATE] SET_VALUE";
export const CLEAR_VALUES = "[BUILDER CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createBuilder(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_BUILDER
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/builder`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				api
					.sendPost(
						`/builder/tag`,
						{
							id: result.model._id
						},
						{
							Authorization: "Bearer " + login.token
						}
					)
					.then(res => res.json())
					.then(data => {
						if (data.isValid) {
							dispatch({
								type: CREATE_BUILDER_SUCCESS
							});

							callback && callback(null, result.model);
						}
					})
					.catch(err => {
						console.log(err);
					});
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: CREATE_BUILDER_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error, null);

			return dispatch({
				type: CREATE_BUILDER_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
