import { CPFValidation } from "../../../helpers/validators";
import { parsePhoneNumber } from "libphonenumber-js/max";
import { isValidNumber } from "libphonenumber-js";

const validate = values => {
	const errors = {};

	if (!values.firstName) errors.name = "Informe seu nome";
	if (!values.lastName) errors.lastName = "Informe seu sobrenome";
	if (!values.document) errors.document = "Informe seu CPF";
	else if (!CPFValidation(values.document.match(/\d/g).join("")))
		errors.document = "CPF inválido";

	if (!values.phoneNumber) errors.phoneNumber = "Informe seu nº de celular";
	else {
		if (!isValidNumber("+55" + values.phoneNumber)) {
			errors.phoneNumber = "Celular inválido";
		} else {
			let phoneNumberParsed = parsePhoneNumber(
				`+55${values.phoneNumber.match(/\d/g).join("")}`
			);

			if (phoneNumberParsed.getType() !== "MOBILE") {
				errors.phoneNumber = "Celular inválido";
			}
		}
	}

	if (!values.email) {
		errors.email = "Informe seu e-mail";
	} else if (
		!new RegExp("^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$", "i").test(
			values.email
		)
	) {
		errors.email = "Informe um e-mail válido";
	}

	if (!values.password) errors.password = "Informe sua senha";
	else if (values.password.length < 6)
		errors.password = "Informe pelo menos 6 caracteres";
	else if (values.confirmPassword && values.password !== values.confirmPassword)
		errors.password = "Senhas não conferem";

	return errors;
};

export default validate;
