import React from "react";
import { Field, reduxForm } from "redux-form";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import EmailIcon from "mdi-react/EmailIcon";
import TicketPercentIcon from "mdi-react/TicketPercentIcon";
import AccountCardDetailsIcon from "mdi-react/AccountCardDetailsIcon";
import validate from "./validate";

import Input from "../../../components/ReduxForm/Input";
import { useState } from "react";
import { PHONE } from "components/NumberFormat";
import { CPF } from "components/NumberFormat";
import { Spinner } from "reactstrap";

function RegisterForm({ handleSubmit, loading }) {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<form className="form" onSubmit={handleSubmit}>
			<div className="form__form-group">
				<span className="form__form-group-label">Nome</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<AccountOutlineIcon />
					</div>
					<Field name="firstName" component={Input} placeholder="Seu nome" />
				</div>
			</div>
			<div className="form__form-group">
				<span className="form__form-group-label">Sobrenome</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<AccountOutlineIcon />
					</div>
					<Field
						name="lastName"
						component={Input}
						placeholder="Seu sobrenome"
					/>
				</div>
			</div>

			<div className="form__form-group">
				<span className="form__form-group-label">CPF</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<AccountCardDetailsIcon />
					</div>
					<Field name="document" component={CPF} placeholder="Seu CPF" />
				</div>
			</div>
			<div className="form__form-group">
				<span className="form__form-group-label">E-mail</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<EmailIcon />
					</div>
					<Field
						name="email"
						component={Input}
						type="email-address"
						placeholder="Seu e-mail"
					/>
				</div>
			</div>
			<div className="form__form-group">
				<span className="form__form-group-label">Celular</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<PhoneIcon />
					</div>
					<Field
						name="phoneNumber"
						component={PHONE}
						placeholder="Nº do seu celular"
					/>
				</div>
			</div>

			<div className="form__form-group">
				<span className="form__form-group-label">Senha</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<KeyVariantIcon />
					</div>
					<Field
						name="password"
						component={Input}
						type={showPassword ? "text" : "password"}
						placeholder="Sua senha"
					/>
					<button
						className={`form__form-group-button${
							showPassword ? " active" : ""
						}`}
						onClick={() => setShowPassword(c => !c)}
						type="button"
					>
						<EyeIcon />
					</button>
				</div>
			</div>
			<div className="form__form-group">
				<span className="form__form-group-label">Confirme a senha</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<KeyVariantIcon />
					</div>
					<Field
						name="confirmPassword"
						component={Input}
						type={showPassword ? "text" : "password"}
						placeholder="Confirme sua senha"
					/>
					<button
						className={`form__form-group-button${
							showPassword ? " active" : ""
						}`}
						onClick={e => setShowPassword(c => !c)}
						type="button"
					>
						<EyeIcon />
					</button>
				</div>
			</div>

			<button
				className="btn btn-primary account__btn account__btn--small"
				type="submit"
			>
				{loading ? (
					<Spinner type="grow" size="sm" color="dark" />
				) : (
					"Criar conta"
				)}
			</button>
		</form>
	);
}

export default reduxForm({
	form: "register_form",
	validate
})(RegisterForm);
