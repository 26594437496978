import React, { useState, useRef } from "react";
import { reduxForm, formValueSelector, reset } from "redux-form";
import validate from "./validate";
import FormOne from "./PageFormOne";
import FormTwo from "./PageFormTwo";
import FormThree from "./PageFormThree";
import { useSelector } from "react-redux";

const successMessageStyle = {
	padding: "20px",
	backgroundColor: "#d4edda",
	color: "#155724",
	marginTop: "20px",
	borderRadius: "5px",
	textAlign: "center"
};

const Create = ({ onSubmit, reset, history, type }) => {
	const [page, setPage] = useState(1);
	const [formData, setFormData] = useState({});

	const state = useSelector(state => state);

	const selector = formValueSelector("expositor_form");

	const done = selector(state, "done");

	const onFormOneSubmit = data => {
		setFormData(prevData => ({ ...prevData, ...data }));
		setPage(2);
	};

	const onFormTwoSubmit = data => {
		setFormData(prevData => ({ ...prevData, ...data }));
		setPage(3);
	};

	const onFormThreeSubmit = data => {
		const finalData = { ...formData, ...data };
		onSubmit(finalData); // Submeter todos os dados acumulados para o backend
	};

	const previousPage = () => {
		setPage(page => Math.max(page - 1, 1));
	};

	const handleReset = () => {
		reset();
		setFormData({});
		setPage(1);
	};

	const handleLogin = () => {
		history.push("/login");
	};

	if (done) {
		return (
			<form className="form">
				<div className="form__form-group" style={successMessageStyle}>
					<p>Cadastro efetuado com sucesso!</p>
					{["builder", "provider"].includes(type) ? (
						<p>Retire sua credencial na entrada da feira</p>
					) : (
						<p>Você receberá um email com os dados de login em breve.</p>
					)}
				</div>
				{["builder", "provider"].includes(type) ? null : (
					<div className="account__btn__group">
						<button
							type="button"
							className="btn btn-primary account__btn account__btn--small"
							onClick={handleLogin}
						>
							Entrar
						</button>
					</div>
				)}
				<div className="account__btn__group">
					<button
						type="button"
						className="btn btn-secondary account__btn account__btn--small"
						onClick={handleReset}
					>
						Realizar novo cadastro
					</button>
				</div>
			</form>
		);
	}

	return (
		<div>
			{page === 1 && <FormOne onSubmit={onFormOneSubmit} />}
			{page === 2 && (
				<FormTwo
					onSubmit={onFormTwoSubmit}
					previousPage={previousPage}
					type={type}
				/>
			)}
			{page === 3 && (
				<FormThree
					onSubmit={onFormThreeSubmit}
					previousPage={previousPage}
					type={type}
				/>
			)}
		</div>
	);
};

export default reduxForm({
	form: "expositor_form", // Nome do formulário no Redux Form
	validate
})(Create);
