import React from "react";
import NumberFormat from "react-number-format";

const AutocompleteField = ({ input, fetchSuggestions, customFormat }) => {
	const handleInputChange = e => {
		const inputText = e.target.value.replace(/[^0-9]/g, "");
		if (inputText && inputText.length == 14) {
			fetchSuggestions(inputText);
		}
	};

	return (
		<>
			{customFormat ? (
				<NumberFormat
					{...input}
					format={customFormat} // Custom format prop for NumberFormat component
					onBlur={handleInputChange}
				/>
			) : (
				<input {...input} onChange={handleInputChange} />
			)}
			{/* {suggestions && suggestions.length > 0 ? (
				<div className="form__form-group-suggestions-autocomplete">
					<ul
						aria-labelledby="cnpj"
						className="form__form-group-suggestions-autocomplete-list"
						role="listbox"
						tabIndex="-1"
					>
						{isLoading ? (
							<li
								className="form__form-group-suggestions-autocomplete-suggestion"
								role="option"
								tabIndex="0"
							>
								<span>Carregando...</span>
							</li>
						) : (
							suggestions.map((suggestion, index) => (
								<li
									key={index}
									className="form__form-group-suggestions-autocomplete-suggestion"
									role="option"
									tabIndex="0"
								>
									{customFormat ? (
										<NumberFormat
											displayType="text"
											value={suggestion}
											format={customFormat}
										/>
									) : (
										<span>{suggestion}</span>
									)}
								</li>
							))
						)}
					</ul>
				</div>
			) : null} */}
		</>
	);
};

export default AutocompleteField;
