import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { SubmissionError, reset } from "redux-form";
import { createConsumption, clearValues } from "./actions";
import { getConsumptionItems } from "containers/Consumption/containers/actions";
import CreateConsumption from "containers/Builder/components/createConsumption";
import validate from "../../../components/validateConsumption";

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.consumptionCreate);
	const { items } = useSelector(s => s.consumptionItem);
	const user = useSelector(s => s.login);

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getConsumptionItems(0, 100, "", "createdAt", false, false));
	}, []);

	const onSubmit = async values => {
		const errors = validate(values);

		console.log(values, errors);

		if (Object.keys(errors).length > 0) {
			console.log(errors);
			throw new SubmissionError(errors);
		}

		const data = {
			...values,
			item:
				values.product.value === "add"
					? {
							name: values.productName,
							consumption: values.consumption
					  }
					: items.find(c => c._id === values.product.value),
			product: undefined,
			productName: undefined
		};

		dispatch(
			createConsumption(data, (err, model) => {
				if (err) {
					console.log(err);
					alert(err && JSON.stringify(err));
				} else {
					dispatch(reset("create_consumption"));
					history.push(`/builder/consumption`);
				}
			})
		);
	};

	const productOptions = useMemo(() => {
		return [
			{ value: "add", label: "Adicionar novo" },
			...items.map(c => ({
				value: c._id,
				label: c.name,
				consumption: c.consumption
			}))
		];
	}, [items]);

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Adicionar Consumo do estande</h5>
							</div>
							<CreateConsumption
								onSubmit={onSubmit}
								loading={loading}
								productOptions={productOptions}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
