import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row, Spinner } from "reactstrap";

const TransactionDetail = ({ transactionDetails, loading }) => {
	if (!transactionDetails) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{transactionDetails.createdAt
												? dayjs(transactionDetails.createdAt).format(
														ls.dateTimeFormat
												  )
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Descrição: </strong>
										<br />
										<span>
											{transactionDetails.description
												? transactionDetails.description
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Valor: </strong>
										<br />
										<span>
											{transactionDetails.value
												? toStringCurrency(transactionDetails.value / 100.0)
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Lead: </strong>
										<br />
										<span>
											{transactionDetails.user ? (
												<Link to={`/user/${transactionDetails.user._id}`}>
													{transactionDetails.user.fullName}
												</Link>
											) : (
												"Não definido"
											)}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Vendedor: </strong>
										<br />
										<span>
											{transactionDetails.createdBy ? (
												<Link to={`/user/${transactionDetails.createdBy._id}`}>
													{transactionDetails.createdBy.fullName}
												</Link>
											) : (
												"Não definido"
											)}
										</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default TransactionDetail;
