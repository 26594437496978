/* eslint-disable no-undef */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import Modal from "components/Modal";
import EditForm from "../../../components/builder/editForm";
import { editBuilderClient, setValue } from "../../Report/actions";
import ls from "Localization";
import { upload } from "redux/actions/upload";

function Edit({ load }) {
	const dispatch = useDispatch();
	const [isUploading, setIsUploading] = useState(false);

	const { item, showEditForm } = useSelector(s => s.company);

	if (!item) return null;

	const FileStatus = {
		Pending: 0,
		Approved: 1,
		Reproved: 2
	};

	const uploadFile = async (type, file) => {
		setIsUploading(true);
		return new Promise((res, rej) => {
			dispatch(
				upload(type, file, (error, url) => {
					if (error) {
						rej(error);
					} else {
						res({ url, status: FileStatus.Pending });
					}
				})
			);
		});
	};

	const onSubmit = async values => {
		setIsUploading(true);

		const data = {
			...values,
			needWaterAndDrain: values.needWaterAndDrain.value,
			needAdditionalThreePhasePower: values.needAdditionalThreePhasePower.value,
			artExecutionFile: undefined,
			artProjectFile: undefined,
			blueprintFile: undefined,
			viewFile: undefined,
			volumetric3DFile: undefined,
			insurancePolicyFile: undefined
		};

		try {
			if (values.artExecutionFile && values.artExecutionFile[0]) {
				data.artExecution = await uploadFile(
					"builder",
					values.artExecutionFile[0]
				);
			}

			if (values.artProjectFile && values.artProjectFile[0]) {
				data.artProject = await uploadFile("builder", values.artProjectFile[0]);
			}

			if (values.blueprintFile && values.blueprintFile[0]) {
				data.blueprint = await uploadFile("builder", values.blueprintFile[0]);
			}

			if (values.viewFile && values.viewFile[0]) {
				data.view = await uploadFile("builder", values.viewFile[0]);
			}

			if (values.volumetric3DFile && values.volumetric3DFile[0]) {
				data.volumetric3D = await uploadFile(
					"builder",
					values.volumetric3DFile[0]
				);
			}

			if (values.insurancePolicyFile && values.insurancePolicyFile[0]) {
				data.insurancePolicy = await uploadFile(
					"builder",
					values.insurancePolicyFile[0]
				);
			}

			dispatch(
				editBuilderClient(item._id, data, (err, model) => {
					setIsUploading(false);
					if (err) {
						console.log(err);
						alert(err);
					} else {
						closeModal();
						load && load();
					}
				})
			);
		} catch (error) {
			console.log(error);
			alert(error);
			setIsUploading(false);
		}
	};

	const closeModal = () => {
		dispatch(setValue({ showEditForm: false }));
	};

	const yesNoOptions = [
		{ value: 0, label: ls.no },
		{ value: 1, label: ls.yes }
	];

	const initialValues = {
		...item,
		needWaterAndDrain:
			item.needAdditionalThreePhasePower === undefined
				? undefined
				: item.needWaterAndDrain
				? yesNoOptions[1]
				: yesNoOptions[0],
		needAdditionalThreePhasePower:
			item.needAdditionalThreePhasePower === undefined
				? undefined
				: item.needAdditionalThreePhasePower
				? yesNoOptions[1]
				: yesNoOptions[0]
	};

	return (
		<Modal
			toggle={closeModal}
			isOpen={showEditForm}
			size="xl"
			title="Anexar documentos"
		>
			<EditForm
				closeModal={closeModal}
				onSubmit={onSubmit}
				initialValues={initialValues}
				loading={{ edit: isUploading }}
				yesNoOptions={yesNoOptions}
			/>
		</Modal>
	);
}

const mapStateToProps = state => {
	return {
		values: getFormValues("editBuilderForm")(state)
	};
};

export default connect(mapStateToProps)(Edit);
