import React, { useState } from "react";
import LogInForm from "./components/LogInForm";
import { SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { submitLogin } from "redux/actions/login";
import Alert from "../../shared/components/Alert";
import ls from "Localization";
import actions from "redux/actions";

const LogIn = ({ history }) => {
	const dispatch = useDispatch();

	const [error, setError] = useState("");

	const { message } = useSelector(s => s.login);

	const handleDismiss = () => {
		dispatch(actions.login.setValue({ message: "" }));
	};

	const onSubmit = data => {
		return dispatch(
			submitLogin(data, err => {
				if (err) {
					console.log("err", err);
					throw new SubmissionError({
						...err
					});
				} else {
					history.push("/");
				}
			})
		);
	};

	return (
		<div className="account">
			{message && (
				<div
					style={{
						position: "absolute",
						right: 16,
						top: 16
					}}
				>
					<Alert
						color="info"
						className="alert--bordered"
						icon
						onDismiss={handleDismiss}
					>
						<p>
							<span className="bold-text">{message}</span>
						</p>
					</Alert>
				</div>
			)}
			{error && (
				<div
					style={{
						position: "absolute",
						right: 16,
						top: 16
					}}
				>
					<Alert
						color="warning"
						className="alert--bordered"
						icon
						onDismiss={handleDismiss}
					>
						<p><span className="bold-text">{error}</span></p>
					</Alert>
				</div>
			)}
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<div className="account__logo" />
						{/* <div className="flex-center">
							<h3 className="account__title">Construir Aí</h3>

							<h4 className="account__subhead subhead">
								Painel administrativo
							</h4>
						</div> */}
					</div>
					<LogInForm onSubmit={onSubmit} />
				</div>
			</div>
		</div>
	);
};

export default LogIn;
