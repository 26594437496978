import React, { PureComponent, useEffect, useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import ls from "Localization";

class SelectField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		options: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string
			})
		),
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string
			})
		]).isRequired,
		onInputChange: PropTypes.func
	};

	static defaultProps = {
		placeholder: "",
		options: []
	};

	handleChange = selectedOption => {
		const { onChange } = this.props;
		onChange(selectedOption);
	};

	// const isOther = value?.value === 'Other';
	// useEffect(() => {
	// 	if (isOther)
	// 		setSelected('Other');
	// }, [value])

	render() {
		const {
			value,
			name,
			placeholder,
			options,
			onInputChange,
			defaultValue,
			isDisabled
		} = this.props;
		// const [lastSelected, setSelected] = useState();

		return (
			<>
				<Select
					name={name}
					value={value || defaultValue}
					onChange={this.handleChange}
					options={options}
					clearable={false}
					className="react-select"
					isDisabled={isDisabled}
					placeholder={placeholder}
					classNamePrefix="react-select"
					onInputChange={onInputChange}
				/>
			</>
		);
	}
}

const renderSelectField = (props, field) => {
	const {
		input,
		meta,
		options,
		placeholder,
		className,
		onInputChange,
		defaultValue,
		isDisabled
	} = props;
	const [selectedOption, setSelected] = useState();

	console.log(input.value);

	useEffect(() => {
		if (defaultValue && !options.find(c => c.value === defaultValue?.value)) {
			setSelected("Other");
			input.onChange(defaultValue);
		}
	}, [defaultValue]);

	const isOther = selectedOption !== "Other" && input.value?.value === "Other";
	if (isOther) {
		setSelected("Other");
		input.onChange("");
	}

	return (
		<div className={`form__form-group-input-wrap ${className}`}>
			{selectedOption === "Other" ? (
				<>
					<SelectField
						value={{
							value: "Other",
							label: "Outro"
						}}
						options={options}
						placeholder={placeholder}
						onInputChange={onInputChange}
						onChange={event => {
							input.onChange(event);
							setSelected(event.value);
						}}
						defaultValue={defaultValue}
					>
						{field.children}
					</SelectField>
					<input
						{...input}
						defaultValue={""}
						autoFocus
						isDisabled={isDisabled}
						onInputChange={onInputChange}
						placeholder={ls["Function"]}
						type="text"
					/>
				</>
			) : (
				<SelectField
					{...input}
					options={options}
					placeholder={placeholder}
					onInputChange={onInputChange}
					defaultValue={defaultValue}
					isDisabled={isDisabled}
				>
					{field.children}
				</SelectField>
			)}
			{meta.touched && meta.error && (
				<span className="form__form-group-error">{meta.error}</span>
			)}
		</div>
	);
};

renderSelectField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string
	}).isRequired,
	onInputChange: PropTypes.func,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string
	}),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string
		})
	),
	placeholder: PropTypes.string,
	className: PropTypes.string
};

renderSelectField.defaultProps = {
	meta: null,
	options: [],
	placeholder: "",
	className: ""
};

export default renderSelectField;
