import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";

import { clearValues, getEvent } from "./actions";
import { setValue } from "../Report/actions";

import EventDetails from "../../components/DetailsForm";
import ReportAttendeeEvents from "./ReportAttendeeEvent";
import Edit from "../Edit";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();
	const { item, loading } = useSelector(state => state.eventDetails);

	const load = () => {
		dispatch(getEvent(id));
	};

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, [dispatch]);

	const handleEditClick = () => {
		dispatch(
			setValue({
				showEditForm: true,
				item
			})
		);
	};

	const handleCheckInClick = () => {
		window.open(`${process.env.REACT_APP_APP_URL}/check/in/${id}`, "_blank");
	};

	const handleCheckOutClick = () => {
		window.open(`${process.env.REACT_APP_APP_URL}/check/out/${id}`, "_blank");
	};

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
								<div>
									<Button onClick={handleCheckInClick} color="secondary">
										Check-in
									</Button>
									<Button onClick={handleCheckOutClick} color="info">
										Check-out
									</Button>
									<Button onClick={handleEditClick} color="primary">
										Editar
									</Button>
								</div>
							</Row>
							<EventDetails event={item} loading={loading} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<ReportAttendeeEvents id={id} />
			<Edit load={load} />
		</Container>
	);
}

export default Details;
