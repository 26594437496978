import * as actions from "./actions";

const initialState = {
	dashboardData: {
		today: 0,
		lastSevenDays: 0,
		lastThirtyDays: 0,
		total: 0,
		totalUsers: 0,
		totalCompanies: 0,
		totalCheckIns: 0,
		todayCheckIns: 0,
		lastSevenDaysCheckIns: 0,
		yesterdayCheckIns: 0

	},
	loading: false,
	errors: null
};

const dashboard = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_LEAD_DASHBOARD: {
			return {
				...state,
				loading: true,
				errors: null
			};
		}
		case actions.GET_LEAD_DASHBOARD_SUCCESS: {
			return {
				...state,
				dashboardData: payload,
				loading: false
			};
		}
		case actions.GET_LEAD_DASHBOARD_ERROR: {
			return {
				...state,
				errors: payload,
				loading: false
			};
		}
		case actions.SET_VALUE: {
			return {
				...state,
				dashboardData: {
					...state.dashboardData,
					...payload
				}
			};
		}
		case actions.CLEAR_VALUES: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default dashboard;
