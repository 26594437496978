import * as actions from "./actions";

const initialState = {
	items: [],
	item: [],
	filter: [],
	pages: null,
	count: 0,
	loading: {},
	showDeleteForm: false,
	showEditForm: false
};

const lane = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_LANE: {
			return {
				...state,
				loading: { getAll: true }
			};
		}
		case actions.GET_LANE_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { getAll: false }
			};
		}
		case actions.GET_LANE_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { getAll: false }
			};
		}

		case actions.EDIT_LANE: {
			return {
				...state,
				loading: { edit: true }
			};
		}
		case actions.EDIT_LANE_SUCCESS: {
			return {
				...state,
				//	items: payload ? payload : [],
				loading: { edit: false }
			};
		}
		case actions.EDIT_LANE_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { edit: false }
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default lane;
