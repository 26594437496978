import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_LEAD = "[LEAD REPORT] GET_LEAD";
export const GET_LEAD_ERROR = "[LEAD REPORT] GET_LEAD_ERROR";
export const GET_LEAD_SUCCESS = "[LEAD REPORT] GET_LEAD_SUCCESS";

export const SET_VALUE = "[EVENTS REPORT] SET_VALUE";
export const CLEAR_VALUES = "[EVENTS REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getLead(skip, take, filter, sort, desc, toExport, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LEAD
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/lead?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_LEAD_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_LEAD_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_LEAD_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_LEAD = "[LEAD EDIT] EDIT_LEAD";
export const EDIT_LEAD_ERROR = "[LEAD EDIT] EDIT_LEAD_ERROR";
export const EDIT_LEAD_SUCCESS = "[LEAD EDIT] EDIT_LEAD_SUCCESS";

export function editlead(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_LEAD
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/lead/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_LEAD_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_LEAD_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_LEAD_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
