import * as actions from "./actions";

const initialState = {
	items: [],
	item: null,
	filter: [],
	pages: null,
	count: 0,
	loading: {},
	showDeleteForm: false,
	showEditForm: false
};

const consumption = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_CONSUMPTION_ITEM: {
			return {
				...state,
				loading: { getAll: true }
			};
		}
		case actions.GET_CONSUMPTION_ITEM_SUCCESS: {
			return {
                ...state,
                items: payload ? payload : [],
                loading: { getAll: false }
			};
		}
		case actions.GET_CONSUMPTION_ITEM_ERROR: {
			return {
                ...state,
                errors: payload,
                loading: { getAll: false }
			};
		}

		case actions.DELETE_CONSUMPTION: {
			return {
				...state,
				loading: { delete: true }
			};
		}
		case actions.DELETE_CONSUMPTION_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { delete: false }
			};
		}
		case actions.DELETE_CONSUMPTION_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { delete: false }
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			return {
				...initialState
			};

		default: {
			return state;
		}
	}
};

export default consumption;
