import * as actions from "../ReportAttendeeEvent/actions";

const initialState = {
	items: [],
	item: [],
	filter: [],
	pages: null,
	count: 0,
	loading: {},
	showDeleteForm: false,
	showEditForm: false
};

const attendeeEvent = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_ATTENDEE_EVENTS: {
			return {
				...state,
				loading: { getAll: true }
			};
		}
		case actions.GET_ATTENDEE_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { getAll: false }
			};
		}
		case actions.GET_ATTENDEE_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { getAll: false }
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default attendeeEvent;
