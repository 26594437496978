/* eslint-disable no-undef */
//import validate from "containers/Company/components/validate";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal";
//import { SubmissionError } from "redux-form";
import EditForm from "../../components/editForm";
import {
	editBudget,
	setValue
} from "../../../Lead/containers/Detail/ReportBudget/actions";
import { toStringCurrency } from "helpers/string";
import { upload } from "redux/actions/upload";

function Edit({ load }) {
	const dispatch = useDispatch();

	const { loading, item, showEditForm } = useSelector(s => s.budget);

	if (!item) return null;

	const onSubmit = async values => {
	
		const data = {
			...values,
			value: values.value.match(/\d/g).join(""),
			document: values.description,
			files: []
		};

		for (const file of values.files || []) {
			const fileUrl = await new Promise((resolve, reject) => {
				dispatch(
					upload("files", file, (err, url) => {
						if (err) {
							console.error("Erro no upload:", error);
							alert("Erro no upload: " + JSON.stringify(error));
							reject(err);
						} else {
							console.log("upload ok");
							resolve(url);
						}
					})
				);
			});

			data.files.push(fileUrl);
		}
		

		dispatch(
			editBudget(item._id, data, err => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					closeModal();
					load && load();
				}
			})
		);
	};

	const closeModal = () => {
		dispatch(setValue({ showEditForm: false }));
	};

	const initialValues = {
		description: item.description,
		value: toStringCurrency(item.value / 100.0)
	};

	return (
		<Modal
			toggle={closeModal}
			isOpen={showEditForm}
			size="xl"
			title="Enviar orçamento"
		>
			<EditForm
				closeModal={closeModal}
				initialValues={initialValues}
				onSubmit={onSubmit}
				loading={loading}
			/>
		</Modal>
	);
}

export default Edit;
