import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";
import _ from "lodash";

export const GET_PRODUCTS = "[PRODUCTS REPORT] GET_PRODUCTS";
export const GET_PRODUCTS_ERROR = "[PRODUCTS REPORT] GET_PRODUCTS_ERROR";
export const GET_PRODUCTS_SUCCESS = "[PRODUCTS REPORT] GET_PRODUCTS_SUCCESS";

export const IMPORT_PRODUCTS = "[PRODUCTS REPORT] IMPORT_PRODUCTS";
export const IMPORT_PRODUCTS_ERROR = "[PRODUCTS REPORT] IMPORT_PRODUCTS_ERROR";
export const IMPORT_PRODUCTS_SUCCESS =
	"[PRODUCTS REPORT] IMPORT_PRODUCTS_SUCCESS";

export const SET_VALUE = "[PRODUCTS REPORT] SET_VALUE";
export const CLEAR_VALUES = "[PRODUCTS REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getProducts(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PRODUCTS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/product?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
				"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_PRODUCTS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_PRODUCTS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_PRODUCTS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function importProducts(data, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: IMPORT_PRODUCTS
		});

		try {
			const { login } = getState();

			const chunks = _.chunk(data, 100);

			for (const chunk of chunks) {
				await new Promise(res => {
					api
						.sendPost(`/product/import`, chunk, {
							Authorization: "Bearer " + login.token
						})
						.then(response => response.json())
						.then(result => {
							console.log(result);
							if (!result.isValid) {
								const errors = getErrors(result.errors);

								callback && callback(errors);

								dispatch({
									type: IMPORT_PRODUCTS_ERROR,
									payload: errors
								});
							}

							res();
						});
				});
			}

			dispatch({
				type: IMPORT_PRODUCTS_SUCCESS
			});

			callback && callback(null, null);
		} catch (error) {
			console.log(error);
			callback && callback(error);

			return dispatch({
				type: IMPORT_PRODUCTS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_PRODUCTS_EDIT = "[PRODUCTS EDIT] EDIT_PRODUCTS_EDIT";
export const EDIT_PRODUCTS_EDIT_ERROR =
	"[PRODUCTS EDIT] EDIT_PRODUCTS_EDIT_ERROR";
export const EDIT_PRODUCTS_EDIT_SUCCESS =
	"[PRODUCTS EDIT] EDIT_PRODUCTS_EDIT_SUCCESS";

export function editProduct(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_PRODUCTS_EDIT
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/product/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_PRODUCTS_EDIT_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_PRODUCTS_EDIT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_PRODUCTS_EDIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const DELETE_PRODUCTS_DELETE =
	"[PRODUCTS DELETE] DELETE_PRODUCTS_DELETE";
export const DELETE_PRODUCTS_DELETE_ERROR =
	"[PRODUCTS DELETE] DELETE_PRODUCTS_DELETE_ERROR";
export const DELETE_PRODUCTS_DELETE_SUCCESS =
	"[PRODUCTS DELETE] DELETE_PRODUCTS_DELETE_SUCCESS";

export function deleteProduct(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_PRODUCTS_DELETE
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/product/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_PRODUCTS_DELETE_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_PRODUCTS_DELETE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_PRODUCTS_DELETE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
