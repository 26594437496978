import React from "react";
import CreateBudgetForm from "../../components/create";
import { useDispatch, useSelector } from "react-redux";
import { createBudgets, clearValues } from "./actions";
import { reset } from "redux-form";
import { useEffect } from "react";
import { upload } from "redux/actions/upload";

import validate from "../../components/validate";

function Create({ user, company, history }) {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearValues());
	}, []);

	const { loading } = useSelector(s => s.budgetCreate);

	const onSubmit = async values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			console.log(errors);
			throw new SubmissionError(errors);
		}

		const data = {
			description: values.description,
			value: values.value.match(/\d/g).join(""),
			user: user._id,
			company: company._id || company,
			files: []
		};

		for (const file of values.files || []) {
			const fileUrl = await new Promise((resolve, reject) => {
				dispatch(
					upload("files", file, (err, url) => {
						if (err) {
							console.error("Erro no upload:", error);
							alert("Erro no upload: " + JSON.stringify(error));
							reject(err);
						} else {
							console.log("upload ok");
							resolve(url);
						}
					})
				);
			});

			data.files.push(fileUrl);
		}

		dispatch(
			createBudgets(data, (err, budget) => {
				if (err) {
					console.log(err);
					alert(err && JSON.stringify(err));
				} else {
					dispatch(reset("budget_create"));
					history.push("/budget/" + budget._id);
				}
			})
		);
	};

	return <CreateBudgetForm onSubmit={onSubmit} loading={loading} />;
}

export default Create;
