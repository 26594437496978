import React, { useRef } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import UploadFile from "components/ReduxForm/UploadFile";
import validate from "./validate";
import ls from "Localization";
import approvedStatus from "models/approvedStatus";
import AlertComponent from "shared/components/Alert";

const responsibilityForm = ({
	handleSubmit,
	reset,
	loading,
	responsibilityFile
}) => {
	const form = useRef(null);

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<p className="mb-3">
							Baixe o termo de responsabilidade, leia e envie assinado usando a
							opção abaixo.
						</p>
						<a
							href="https://construirai.s3.amazonaws.com/files/Termo+de+responsabilidade+-+Feira+Construir+A%C3%AD.pdf"
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-secondary mb-3"
						>
							Baixar Termo de Responsabilidade
						</a>

						<div className="card__title">
							<h5 className="bold-text">Envie o Termo Assinado!</h5>
						</div>

						<div className="form__form-group-field">
							<Field
								name="responsibilityFile"
								component={UploadFile}
								type="file"
								accept="application/pdf"
								label="Carregar Termo Assinado"
							/>
						</div>

						<br />
						<AlertComponent
							color="info"
							className="alert--bordered"
							icon
							hideDismiss
						>
							<p>
								<span className="bold-text">
									{responsibilityFile
										? `Termo enviado | Status: ${
												ls[approvedStatus[responsibilityFile.status]]
										  }`
										: "Termo não enviado"}
								</span>
							</p>
						</AlertComponent>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Enviar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "edit_responsibility", // Certifique-se de que o nome do formulário é único
	validate
})(responsibilityForm);
