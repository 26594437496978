import states from "assets/states.json";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { SubmissionError, reset } from "redux-form";
import CreateUserForm from "../../components/createForm";
import { clearValues, createCashback } from "./actions";

import validate from "../../components/validate";

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.cashbackCreate);

	useEffect(() => {
		dispatch(clearValues());
	}, []);

	const onSubmit = async values => {
		const errors = validate(values);
		
		if (Object.keys(errors).length > 0) {
			console.log(errors);
			throw new SubmissionError(errors);
		}

		const data = {
			...values,
			user: values.user,
			value: values.value.match(/\d/g).join(""),
			status: 4
		};

		dispatch(
			createCashback(data, (err, model) => {
				if (err) {
					console.log(err);
					alert(err && JSON.stringify(err));
				} else {
					dispatch(reset("cashback_create"));
					history.push(`/cashback/${model._id}`);
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Adicionar Cashback</h5>
							</div>
							<CreateUserForm
								onSubmit={onSubmit}
								loading={loading}
								states={states}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
