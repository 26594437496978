import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";

import { clearValues, getBuilder } from "./actions";

import Edit from "../../../Company/containers/Builder/Edit";
import DetailsBuilder from "../../components/detail";
import Create from "../Create";
import { MdEdit } from "react-icons/md";
import TooltipComponent from "components/TooltipComponent";
import { setValue } from "containers/Company/containers/Report/actions";

function Details({ match: { params } }) {
	const dispatch = useDispatch();
	const { item, loading } = useSelector(state => state.builderDetails);

	const id = params?.id;

	const load = useCallback(() => {
		if (!id) return;

		dispatch(getBuilder(params?.id));
	}, [id, dispatch]);

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, [dispatch, load]);

	const handleEditClick = () => {
		console.log("item: ", item);

		dispatch(
			setValue({
				showEditForm: true,
				item
			})
		);
	};

	if (!item?.builder && !loading.get) return <Create />;

	return (
		<>
			<Container className="dashboard">
				<Row>
					<Col xs={12}>
						<Card style={{ height: "unset" }}>
							<CardBody>
								<Row className="justify-content-between align-items-center">
									<div className="card__title">
										<h5 className="bold-text">Sua Montadora</h5>
										{loading.get && (
											<Spinner type="grow" size="sm" color="dark" />
										)}
									</div>
									<div>
										<Button
											color="primary"
											onClick={() => handleEditClick()}
											id="edit-builder"
										>
											Anexar documentos
										</Button>
										<TooltipComponent
											title="Anexar documentos"
											target="edit-builder"
										/>
									</div>
								</Row>
								<DetailsBuilder item={item} loading={loading} />
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Edit load={load} />
			</Container>
		</>
	);
}

export default Details;
