import { CEP, CNPJ, PERCENTAGE2, PHONE } from "components/NumberFormat";
import Input from "components/ReduxForm/Input";
import UploadFile from "components/ReduxForm/UploadFile";
import React, { useRef, useState } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "shared/components/form/Select";
import validate from "./validate";
import TextArea from "components/ReduxForm/TextArea";

const createForm = ({ states, handleSubmit, handleCNPJBlur, reset, isUploading, ...props }) => {
	const form = useRef(null);
	const [zipCodeLoading, setZipCodeLoading] = useState(false);

	const handleZipCodeChange = zipCode => {
		if (zipCode && zipCode.length === 8) {
			setZipCodeLoading(true);
			fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
				.then(res => res.json())
				.then(json => {
					if (json) {
						props.change("address.street", json.logradouro);
						props.change("address.complement", json.complemento);
						props.change("address.neighborhood", json.bairro);
						props.change("address.city", json.localidade);
						props.change(
							"address.state",
							states.find(c => c.value === json.uf)
						);
					}
					setZipCodeLoading(false);
				})
				.catch(err => {
					console.log(err);
					setZipCodeLoading(false);
				});
		}
	};

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">Razão Social</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Ex.: Construir Aí LTDA"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">Nome</span>
						<div className="form__form-group-field">
							<Field
								name="socialName"
								component={Input}
								type="text"
								placeholder="Ex.: Construir Aí"
							/>
						</div>
					</Col>

					<Col xs={12} sm={3} md={2} className="form__form-group">
						<span className="form__form-group-label">Segmento</span>
						<div className="form__form-group-field">
							<Field
								name="segment"
								component={Input}
								type="text"
								placeholder="Ex.: Construção"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">CNPJ</span>
						<div className="form__form-group-field">
							<Field
								name="document"
								component={CNPJ}
								type="text"
								placeholder="Ex.: 00.000.000/0000-00"
								onBlur={handleCNPJBlur}
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">E-mail</span>
						<div className="form__form-group-field">
							<Field
								name="email"
								component={Input}
								type="email"
								placeholder="Ex.: exemplo@exemplo.com"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">Telefone</span>
						<div className="form__form-group-field">
							<Field
								name="phoneNumber"
								component={PHONE}
								type="text"
								placeholder="(12) 3456-7890"
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Sobre</span>
						<div className="form__form-group-field">
							<Field
								name="about"
								component={TextArea}
								type="text"
								placeholder="Descreva aqui informações relevantes sobre o prestador de serviço"
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={4} md={3}>
						<div className="form__form-group">
							<span className="form__form-group-label">CEP</span>
							<div className="form__form-group-field">
								<Field
									name="address.zipCode"
									placeholder="Ex.: 00000-000"
									component={CEP}
									onChange={handleZipCodeChange}
								/>
							</div>
						</div>
					</Col>
					<Col xs={12} md={7} className="form__form-group">
						<span className="form__form-group-label">Logradouro</span>
						<div className="form__form-group-field">
							<Field
								name="address.street"
								component={Input}
								type="text"
								placeholder="Ex.: Rua Governador Celso Ramos"
							/>
						</div>
					</Col>
					<Col xs={6} md={3} lg={2} className="form__form-group">
						<span className="form__form-group-label">Número</span>
						<div className="form__form-group-field">
							<Field
								name="address.number"
								component={Input}
								type="text"
								placeholder="Ex.: 20"
							/>
						</div>
					</Col>
					<Col xs={6} md={4} lg={3}>
						<div className="form__form-group">
							<span className="form__form-group-label">Complemento</span>
							<div className="form__form-group-field">
								<Field
									name="address.complement"
									placeholder="Ex.: Sala 1"
									component={Input}
								/>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={6} md={3} className="form__form-group">
						<span className="form__form-group-label">Bairro</span>
						<div className="form__form-group-field">
							<Field
								name="address.neighborhood"
								component={Input}
								type="text"
								placeholder="Ex.: Centro"
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} md={3} className="form__form-group">
						<span className="form__form-group-label">Cidade</span>
						<div className="form__form-group-field">
							<Field
								name="address.city"
								component={Input}
								type="text"
								placeholder="Ex.: Florianópolis"
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} md={3} className="form__form-group">
						<span className="form__form-group-label">Estado</span>
						<div className="form__form-group-field">
							<Field
								placeholder="Selecione"
								name="address.state"
								component={renderSelectField}
								type="text"
								options={states}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={6} lg={4} className="form__form-group">
						<span className="form__form-group-label">Logo</span>
						<div className="form__form-group-field">
							<Field
								name="logoFile"
								component={UploadFile}
								accept="image/png,image/jpeg"
							/>
						</div>
					</Col>
					<Col xs={12} md={6} lg={4} className="form__form-group">
						<span className="form__form-group-label">Banner</span>
						<div className="form__form-group-field">
							<Field
								name="bannerFile"
								component={UploadFile}
								accept="image/png,image/jpeg"
							/>
						</div>
					</Col>
					<Col xs={12} md={6} lg={4} className="form__form-group">
						<span className="form__form-group-label">Catalogo</span>
						<div className="form__form-group-field">
							<Field
								name="catalogFile"
								component={UploadFile}
								accept="application/pdf"
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={isUploading}>
					{isUploading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "service_provider_create",
	validate
})(createForm);
