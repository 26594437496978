import React, { PureComponent, useState, useEffect, useMemo } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse, Badge } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import { logoutUser } from "redux/actions/login";
import { useDispatch, useSelector } from "react-redux";
import { MdNotifications } from "react-icons/md";
import {
	getNotifications,
	setValue,
	editNotification
} from "redux/actions/notification";
import dayjs from "dayjs";

export default function TopBarNotification() {
	const dispatch = useDispatch();

	const [collapse, setCollapse] = useState(false);
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);

	const state = useSelector(c => c.notification);
	const { loading } = useSelector(c => c.notification);

	useEffect(() => {
		// const interval = setInterval(() => {
		dispatch(getNotifications(0, 100));
		// }, 500);
		// return () => clearInterval(interval);
	}, []);
	useMemo(() => {
		const newData = state.items.filter(c => c.status === 0);
		setData(newData);
		setCount(newData.length);
	}, [state]);

	const toggle = () => {
		setCollapse(e => !e);
	};

	const handleCheckedRead = values => {
		dispatch(
			editNotification(
				values.id,
				{
					description: values.description,
					Status: 1,
					eventLink: values.eventLink
				},
				(err, id) => {
					if (err) {
						throw new SubmissionError(err);
					} else {
						dispatch(getNotifications(0, 100));
						setCount(count - 1);
					}
				}
			)
		);
	};
	return (
		<div className="topbar__collapse">
			<button type="button" className="topbar__avatar" onClick={toggle}>
				<MdNotifications className="topbar__notification__icon" size={12} />
				<span className={count ? "topbar__link-count" : "desactive"}>
					{count > 99 ? 99 + "+" : count}
				</span>
			</button>
			{collapse && (
				<button type="button" className="topbar__back" onClick={toggle} />
			)}
			<Collapse isOpen={collapse} className="topbar__collapse-content">
				<div className="topbar__menu">
					{/* <TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
					<TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" />
					<div className="topbar__menu-divider" /> */}

					{loading ? (
						<div className="topbar__link-title">
							<span
								className="spinner-border spinner-border-sm "
								role="status"
								aria-hidden="true"
							></span>
						</div>
					) : data.length > 0 ? (
						data.map(c => (
							<div
								className="topbar__collapse-item"
								key={c._id}
								onClick={() => handleCheckedRead(c)}
							>
								<p className="topbar__collapse-message">
									<span className="topbar__collapse-name">{c.title}</span>
								</p>
								<p className="topbar__collapse-message">{c.body}</p>
								<p className="topbar__collapse-date">
									{dayjs(c.createdAt).format("HH:mm")}
								</p>
							</div>
						))
					) : (
						<p className="topbar__link-title">Nenhuma Notificação</p>
					)}
				</div>
			</Collapse>
		</div>
	);
}
