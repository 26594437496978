import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import { isMobileOnly } from "react-device-detect";
import PropTypes from "prop-types";
import dayjs from "dayjs";

class DateTimePickerField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			startDate: null
		};
	}

	handleChange = date => {
		const { onChange } = this.props;
		this.setState({
			startDate: date
		});
		onChange(date);
	};

	componentDidMount() {
		const { value } = this.props;

		if (value) {
			this.setState({ startDate: dayjs(value).toDate() });
		}
	}

	render() {
		const { startDate } = this.state;
		const { placeholderText } = this.props;

		return (
			<div className="date-picker">
				<DatePicker
					timeFormat="HH:mm"
					className="form__form-group-datepicker"
					selected={startDate}
					onChange={this.handleChange}
					showTimeSelect
					timeIntervals={15}
					dateFormat="dd/MM/yyyy - HH:mm"
					dropDownMode="select"
					withPortal={isMobileOnly}
					placeholderText={placeholderText}
				/>
			</div>
		);
	}
}

const renderDateTimePickerField = props => {
	const { input, placeholderText } = props;

	return <DateTimePickerField placeholderText={placeholderText} {...input} />;
};

renderDateTimePickerField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string
	}).isRequired
};

export default renderDateTimePickerField;
