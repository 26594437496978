import { validateEmail } from "helpers/email";
import { CPFValidation } from "helpers/validators";

const validate = values => {
	const errors = {};

	// const idPattern = /^[0-9a-fA-F]{24}$/; // Padrão para validar um ObjectId do MongoDB

	// if (!values.identification) {
	// 	errors["identification"] = "Informe seu ID, e-mail ou CPF.";
	// } else if (idPattern.test(values.identification)) {
	// 	// Se passar nessa condição, é um ID válido e não precisamos fazer mais nada.
	// } else if (CPFValidation(values.identification)) {
	// 	// Se passar nessa condição, é um CPF válido e não precisamos fazer mais nada.
	// } else if (validateEmail(values.identification)) {
	// 	// Se passar nessa condição, é um e-mail válido e não precisamos fazer mais nada.
	// } else {
	// 	errors["identification"] = "Informe um ID, e-mail ou CPF válido.";
	// }

	return errors;
};

export default validate;
