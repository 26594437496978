import React from "react";
import { Card, Row, Col, Container, Spinner } from "reactstrap";
import dayjs from "dayjs";
import ls from "Localization";
import userStatus from "models/userStatus";
import { CPF } from "components/NumberFormat";

const UserDetail = ({ client, loading }) => {
	if (!client) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Col>
						<Row>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Data criação: </strong>
									<br />
									<span>
										{client.createdAt
											? dayjs(client.createdAt).format(ls.dateTimeFormat)
											: "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Nome: </strong>
									<br />
									<span>
										{client.firstName ? client.firstName : "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Sobrenome: </strong>
									<br />
									<span>
										{client.lastName ? client.lastName : "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								{client.document ? (
									<CPF
										displayType="text"
										value={client.document}
										renderText={v => {
											return (
												<p>
													<strong>CPF: </strong>
													<br />
													<span>{v}</span>
												</p>
											);
										}}
									/>
								) : (
									<p>
										<strong>CPF: </strong>
										<br />
										<span>Não definido</span>
									</p>
								)}
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Telefone: </strong>
									<br />
									<span>
										{client.nationalPhoneNumber
											? client.nationalPhoneNumber
											: "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>E-mail: </strong>
									<br />
									<span>{client.email ? client.email : "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Expositor: </strong>
									<br />
									<span>
										{client.company ? client.company.name : "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Profissão: </strong>
									<br />
									<span>
										{client.profession ? client.profession : "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>{ls.role}: </strong>
									<br />
									<span>{client.role ? ls[client.role] : "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Status: </strong>
									<br />
									<span>{ls[userStatus[client.status]]}</span>
								</p>
							</Col>

							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Telefone: </strong>
									<br />
									<span>
										{client.telephone ? client.telephone : "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Objetivo: </strong>
									<br />
									<span>{client.goal ? client.goal : "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Como soube da feira: </strong>
									<br />
									<span>
										{client.knowAbout ? client.knowAbout : "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Newsletters: </strong>
									<br />
									<span>{client.subscribe ? "Sim" : "Não definido"}</span>
								</p>
							</Col>
						</Row>
					</Col>
				</Container>
			</Card>
		</div>
	);
};

export default UserDetail;
