import states from "assets/states.json";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { SubmissionError, reset } from "redux-form";
import { createConsumption } from "./actions";

import CreateConsumptionItem from "../../components/createForm";
 
function Create({ history }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.cashbackCreate);

	const onSubmit = async values => {
		// const errors = validate(values);

		// if (Object.keys(errors).length > 0) {
		// 	console.log(errors);
		// 	throw new SubmissionError(errors);
		// }

		const data = {
			...values
		};

		dispatch(
			createConsumption(data, (err, model) => {
				if (err) {
					console.log(err);
					alert(err && JSON.stringify(err));
				} else {
					dispatch(reset("create_consumption_item"));
					history.push(`/consumption/item`);
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Adicionar item de consumo</h5>
							</div>
							<CreateConsumptionItem
								onSubmit={onSubmit}
								loading={loading}
								states={states}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
