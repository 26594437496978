import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_USERS = "[USERS REPORT] GET_USERS";
export const GET_USERS_ERROR = "[USERS REPORT] GET_USERS_ERROR";
export const GET_USERS_SUCCESS = "[USERS REPORT] GET_USERS_SUCCESS";

export const PRIZE_DRAW = "[USERS REPORT] PRIZE_DRAW";
export const PRIZE_DRAW_ERROR = "[USERS REPORT] PRIZE_DRAW_ERROR";
export const PRIZE_DRAW_SUCCESS = "[USERS REPORT] PRIZE_DRAW_SUCCESS";

export const SET_VALUE = "[USERS REPORT] SET_VALUE";
export const CLEAR_VALUES = "[USERS REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getUsers(skip, take, filter, sort, desc, toExport, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_USERS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/user?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_USERS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_USERS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USERS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function prizeDraw(prize, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: PRIZE_DRAW
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(
				`/user/prize/draw`,
				{ prize },
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: PRIZE_DRAW_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: PRIZE_DRAW_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: PRIZE_DRAW_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const DELETE_USER = "[USER DELETE] DELETE_USER";
export const DELETE_USER_ERROR = "[USER DELETE] DELETE_USER_ERROR";
export const DELETE_USER_SUCCESS = "[USER DELETE] DELETE_USER_SUCCESS";

export function deleteUser(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_USER
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/user/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_USER_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_USER_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_USER_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const SEND_WELCOME_MAIL = "[USER DELETE] SEND_WELCOME_MAIL";
export const SEND_WELCOME_MAIL_ERROR = "[USER DELETE] SEND_WELCOME_MAIL_ERROR";
export const SEND_WELCOME_MAIL_SUCCESS =
	"[USER DELETE] SEND_WELCOME_MAIL_SUCCESS";

export function sendWelcomePassword(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: SEND_WELCOME_MAIL
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/user/${id}/reset`, null, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: SEND_WELCOME_MAIL_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: SEND_WELCOME_MAIL_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: SEND_WELCOME_MAIL_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_USER = "[USER EDIT] EDIT_USER";
export const EDIT_USER_ERROR = "[USER EDIT] EDIT_USER_ERROR";
export const EDIT_USER_SUCCESS = "[USER EDIT] EDIT_USER_SUCCESS";

export function editUser(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_USER
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/user/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_USER_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_USER_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_USER_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const IMPORT_USERS = "[USERS IMPORT] IMPORT_USERS";
export const IMPORT_USERS_SUCCESS = "[USERS IMPORT] IMPORT_USERS_SUCCESS";
export const IMPORT_USERS_ERROR = "[USERS IMPORT] IMPORT_USERS_ERROR";

export function importUsers(importData, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_USERS });

		try {
			const { login } = getState();

			let response = null;

			const chunk = _.chunk(importData, 100);

			for (let i = 0; i < chunk.length; i++) {
				response = await api.sendPost("/user/import", chunk[i], {
					Authorization: "Bearer " + login.token
				});
			}

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_USERS_SUCCESS,
					payload: result.message // Supondo que a resposta tenha uma mensagem de sucesso
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: IMPORT_USERS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			dispatch({
				type: IMPORT_USERS_ERROR,
				payload: { error: "Connection error" }
			});
		}
	};
}
