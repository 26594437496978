import React, { PureComponent, useState } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import { logoutUser } from "redux/actions/login";
import { useDispatch, useSelector } from "react-redux";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default function TopbarProfile() {
	const dispatch = useDispatch();

	const [collapse, setCollapse] = useState(false);

	const { user } = useSelector(c => c.login);

	const toggle = () => {
		setCollapse(e => !e);
	};

	const handleLogOut = () => {
		dispatch(logoutUser());
	};

	return (
		<div className="topbar__profile">
			<button type="button" className="topbar__avatar" onClick={toggle}>
				<img className="topbar__avatar-img" src={Ava} alt="avatar" />
				<p className="topbar__avatar-name">{user ? user.fullName : ""}</p>
				<DownIcon className="topbar__icon" />
			</button>
			{collapse && (
				<button type="button" className="topbar__back" onClick={toggle} />
			)}
			<Collapse isOpen={collapse} className="topbar__menu-wrap">
				<div className="topbar__menu">
					<TopbarMenuLink
						title="Alterar Senha"
						icon="lock"
						path="/ChangePassword"
					></TopbarMenuLink>
					{/* <TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
					<TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" /> */}
					<div className="topbar__menu-divider" />

					<TopbarMenuLink
						title="Sair"
						icon="exit"
						path="/Login"
						onClick={handleLogOut}
					/>
				</div>
			</Collapse>
		</div>
	);
}
