import { PHONE } from "components/NumberFormat";
import GlobalModal from "components/Modal";
import * as api from "../../../services/api";
import Input from "components/ReduxForm/Input";
import { useSelector } from "react-redux";
import React, { useRef, useState } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import InputAutoComplete from "components/ReduxForm/InputAutoComplete";

const createForm = ({ handleSubmit, reset, loading, ...props }) => {
	const form = useRef(null);
	const [CnpjLoading, setCnpjLoading] = useState(false);
	const [cnpjFound, setCnpjFound] = useState(false);
	const [builder, setBuilder] = useState(null);
	const login = useSelector(s => s.login);

	const useReset = () => {
		reset();
		setCnpjFound(false);
	};

	const useBuilder = e => {
		e.preventDefault();
		setCnpjLoading(true);
		api
			.sendPost(
				`/builder/tag`,
				{
					id: builder._id
				},
				{
					Authorization: "Bearer " + login.token
				}
			)
			.then(res => res.json())
			.then(model => {
				if (model.isValid) {
					location.reload();
				}
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setCnpjLoading(false);
			});
	};

	const handleCNPJChange = cnpjDocument => {
		setCnpjLoading(true);
		api
			.sendGet(
				`/company?filters%5Bpublic%5D=true&filters%5BcompanyType%5D=M&take=1&filters%5Bdocument%5D=${cnpjDocument.replace(
					/[^\d]+/g
				)}`,
				{
					Authorization: "Bearer " + login.token
				}
			)
			.then(res => res.json())
			.then(data => {
				const { model } = data;
				if (model.length == 1) {
					const companyData = model[0];
					setBuilder(companyData);
					setCnpjFound(true);
					props.change("name", companyData.name);
					props.change("socialName", companyData.socialName);
				}
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setCnpjLoading(false);
			});
	};

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<GlobalModal
				isOpen={cnpjFound}
				title={"Montadora encontrada"}
				toggle={setCnpjFound}
			>
				<span>Utilizar dados da montadora com o mesmo CNPJ?</span>
				<ButtonToolbar className="form__button-toolbar">
					<Button
						color="primary"
						type="button"
						disabled={loading}
						onClick={useBuilder}
					>
						{loading ? (
							<span
								className="spinner-border spinner-border-sm"
								role="status"
								aria-hidden="true"
							/>
						) : (
							"Sim"
						)}
					</Button>
					<Button type="button" onClick={useReset}>
						Utilizar outro CNPJ
					</Button>
				</ButtonToolbar>
			</GlobalModal>
			<Container>
				<Row>
					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">CNPJ</span>
						<div className="form__form-group-field">
							<Field
								component={InputAutoComplete}
								name="document"
								customFormat="##.###.###/####-##"
								type="text"
								placeholder="Ex.: 00.000.000/0000-00"
								fetchSuggestions={handleCNPJChange}
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">Razão Social</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Ex.: Construir Aí LTDA"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">Nome Fantasia</span>
						<div className="form__form-group-field">
							<Field
								name="socialName"
								component={Input}
								type="text"
								placeholder="Ex.: Construir Aí"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">
							E-mail do Responsável
						</span>
						<div className="form__form-group-field">
							<Field
								name="email"
								component={Input}
								type="email"
								placeholder="Ex.: exemplo@exemplo.com"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">Telefone</span>
						<div className="form__form-group-field">
							<Field
								name="phoneNumber"
								component={PHONE}
								type="text"
								placeholder="(12) 3456-7890"
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "company_create",
	validate
})(createForm);
