import * as api from "services/api";
import { getErrors } from "helpers/error";
import { readFileAsArrayBuffer } from "helpers/files";

export const UPLOAD = "UPLOAD";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_ERROR = "UPLOAD_ERROR";

export const SET_VALUE = "[UPLOAD] SET_VALUE";
export const CLEAR_VALUES = "[UPLOAD] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function upload(folder, file, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPLOAD
		});

		console.log(folder, file)
		try {
			const { login } = getState();

			let response = await api.sendPost(
				`/upload`,
				{
					tenant_id: folder,
					content_type: file.type,
					filename: file.name
				},
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.url) {
				let buffer = await readFileAsArrayBuffer(file);
				let bytes = new Uint8Array(buffer);

				response = await fetch(result.url, {
					method: "PUT",
					body: bytes,
					headers: {
						"content-type": file.type
					}
				});

				if (response.ok) {
					dispatch({
						type: UPLOAD_SUCCESS
					});

					callback && callback(null, result.url.split("?")[0]);
				} else {
					const text = await response.text();

					callback && callback(text, null);

					dispatch({
						type: UPLOAD_ERROR,
						payload: {
							error: text
						}
					});
				}
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: UPLOAD_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error, null);

			return dispatch({
				type: UPLOAD_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
