import * as api from "../../../../../services/api";
import { getErrors } from "../../../../../helpers/error";
//import _ from "lodash";

export const GET_BUDGET = "[BUDGET REPORT] GET_BUDGET";
export const GET_BUDGET_ERROR = "[BUDGET REPORT] GET_BUDGET_ERROR";
export const GET_BUDGET_SUCCESS = "[BUDGET REPORT] GET_BUDGET_SUCCESS";

export const SET_VALUE = "[BUDGET REPORT] SET_VALUE";
export const CLEAR_VALUES = "[BUDGET REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getBudget(skip, take, filter, sort, desc, toExport, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_BUDGET
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/budget?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_BUDGET_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_BUDGET_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_BUDGET_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_BUDGET_EDIT = "[BUDGET EDIT] EDIT_BUDGET_EDIT";
export const EDIT_BUDGET_EDIT_ERROR = "[BUDGET EDIT] EDIT_BUDGET_EDIT_ERROR";
export const EDIT_BUDGET_EDIT_SUCCESS =
	"[BUDGET EDIT] EDIT_BUDGET_EDIT_SUCCESS";

export function editBudget(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_BUDGET_EDIT
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/budget/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_BUDGET_EDIT_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_BUDGET_EDIT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_BUDGET_EDIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const DELETE_BUDGET_DELETE = "[BUDGET DELETE] DELETE_BUDGET_DELETE";
export const DELETE_BUDGET_DELETE_ERROR =
	"[BUDGET DELETE] DELETE_BUDGET_DELETE_ERROR";
export const DELETE_BUDGET_DELETE_SUCCESS =
	"[BUDGET DELETE] DELETE_BUDGET_DELETE_SUCCESS";

export function deleteBudget(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_BUDGET_DELETE
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/budget/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_BUDGET_DELETE_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_BUDGET_DELETE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_BUDGET_DELETE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
