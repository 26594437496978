import * as actions from "./actions";

const initialState = {
	errors: {},
	emailOrCpfVerification: {
        data: "",
        available: null,
        userData: null
    },
    verifyLoading: false,
	loading: false,
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.VERIFY_DATA: {
			return {
				...state,
				verifyLoading: true,
				emailOrCpfVerification: initialState.emailOrCpfVerification
			};
		}
		case actions.VERIFY_DATA_SUCCESS: {
			return {
				...state,
				verifyLoading: false,
				emailOrCpfVerification: {
					data: payload.emailOrCpf,
					available: true,
					userData: null
				}
			};
		}
		case actions.VERIFY_DATA_ERROR: {
			return {
				...state,
				verifyLoading: false,
				emailOrCpfVerification: {
					data: payload.emailOrCpf,
					available: false,
					userData: payload.data
				},
				errors: { ...state.errors, verify: payload.error }
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};

		default: {
			return state;
		}
	}
};

export default reducer;
