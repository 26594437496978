import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { reset } from "redux-form";
import CreateUserForm from "../../components/CreateForm";
import { createNews } from "./actions";
import { upload } from "redux/actions/upload";

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.newsCreate);

	const onSubmit = async values => {
		const data = {
			title: values.title,
			description: values.description,
			video: values.video
		};

		if (values.bannerFile && values.bannerFile[0]) {
			try {
				data.banner = await new Promise((res, rej) =>
					dispatch(
						upload("event", values.bannerFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				return;
			}
		}

		dispatch(
			createNews(data, (err, news) => {
				if (err) {
					console.log(err);
					alert(err && JSON.stringify(err));
				} else {
					dispatch(reset("news_create"));
					history.push("/news/" + news._id);
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Cadastrar Noticia</h5>
							</div>
							<CreateUserForm onSubmit={onSubmit} loading={loading} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
export default Create;
