const validate = values => {
	const errors = {};

	const requiredFields = ["value", "description"];

	for (let field of requiredFields) {
		if (!values[field]) errors[field] = `Campo obrigatório`;
	}

	const value =
		typeof values.value === "string" ? values.value : values.value?.toFixed(2);

	if (Number(value?.match(/\d/g)?.join("") || 0) < 500) {
		errors.value = "O valor mínimo para orçamento é R$ 5,00";
	}

	return errors;
};

export default validate;
