import * as actions from "./actions";

const initialState = {
	errors: {},
	item: {
		code: "",
		name: "",
		description: "",
		costPrice: 0,
		pricePolicy: "",
		modelProduct: "",
		brand: "",
		manufacturer: "",
		origin: "",
		inmetroNumber: "",
		imported: false,
		customsCode: "",
		category: ""
	},
	loading: false
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.CREATE_PRODUCTS: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CREATE_PRODUCTS_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CREATE_PRODUCTS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: false
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
