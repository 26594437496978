import * as actions from "./actions";

const initialState = {
	loading: {
		get: false,
		edit: false,
		status: false
	},
	item: null
};

const companyDetails = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_CLIENT: {
			return {
				...state,
				loading: { get: true }
			};
		}
		case actions.GET_CLIENT_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: { get: false }
			};
		}
		case actions.GET_CLIENT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { get: false }
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default companyDetails;
