import React from "react";
import { reduxForm } from "redux-form";
import { Button, ButtonToolbar, Container, Col, Row } from "reactstrap";
import PropTypes from "prop-types";

const DeleteForm = ({ handleSubmit, closeModal, initialValues }) => {
	const { title } = initialValues;
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={6} md={12} className="form__form-group">
						<span className="form__form-group-label">
							Deseja Realmente Excluir:
						</span>
						<div className="form__form-group-field">
							<h5>{title}</h5>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="cancel" type="submit">
					Excluir
				</Button>
				<Button type="button" color="secondary" onClick={closeModal}>
					Cancelar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "news_delete"
})(DeleteForm);

DeleteForm.propTypes = {
	handleSubmit: PropTypes.func,
	closeModal: PropTypes.func
};
