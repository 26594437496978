import React, { useRef } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import Input from "../../../components/ReduxForm/Input";
import validate from "./validate";

const createForm = ({ handleSubmit, reset, loading }) => {
	const form = useRef(null);

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col
						xs={12}
						sm={12}
						md={6}
						lg={4}
						xl={3}
						className="form__form-group"
					>
						<span className="form__form-group-label">Nome</span>
						<div className="form__form-group-field">
							<Field name="name" component={Input} type="text" />
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "product_category_create",
	validate
})(createForm);
