import React, { useState, useRef } from "react";
import { reduxForm } from "redux-form";
import validate from "./validate";
import OptionsPage from "./OptionsPage";
import EmailPage from "./EmailPage";
import DocumentPage from "./DocumentPage";
import QrCodePage from "./QrCodePage";
import ReaderPage from "./ReaderPage";

const SelfServiceForm = ({ onSubmit, page, setPage }) => {
	const form = useRef(null);

	console.log("Current page:", page);

	const previousPage = () => {
		setPage(1);
	};

	return (
		<div>
			{page === 1 && <OptionsPage handleSubmit={setPage} />}
			{page === "email" && (
				<EmailPage ref={form} previousPage={previousPage} onSubmit={onSubmit} />
			)}
			{page === "document" && (
				<DocumentPage
					ref={form}
					previousPage={previousPage}
					onSubmit={onSubmit}
				/>
			)}
			{page === "qrCode" && (
				<QrCodePage onSubmit={onSubmit} previousPage={previousPage} />
			)}
			{page === "reader" && (
				<ReaderPage
					ref={form}
					previousPage={previousPage}
					onSubmit={onSubmit}
				/>
			)}
		</div>
	);
};

// class SelfServiceForm extends PureComponent {
// 	static propTypes = {
// 		handleSubmit: PropTypes.func.isRequired
// 	};

// 	constructor() {
// 		super();
//     this.setPage = this.setPage.bind(this)
//     this.previousPage = this.previousPage.bind(this)
//     this.state = {
//         page: 1
//       }
//     }
//     setPage = (page) => {
//       this.setState({ page: page })
//     }
//     previousPage() {
//       this.setState({ page: 1 })
//     }

// 	render() {
//     const { onSubmit } = this.props
//     const { page } = this.state
//     return (<div>
//         {page === 1 && <OptionsPage handleSubmit={this.setPage}/>}
//         {page === "email" && <EmailPage previousPage={this.previousPage} handleSubmit={onSubmit}/>}
//         {page === "document" && <DocumentPage previousPage={this.previousPage} handleSubmit={onSubmit}/>}
//       </div>
//     )
// 	}
// }

export default SelfServiceForm;
