import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "components/Modal";
import DeleteForm from "../../components/deleteForm";
import { deleteProduct, setValue } from "../Report/actions";

function Delete({ load }) {
	const dispatch = useDispatch();
	const { showDeleteForm: item } = useSelector(s => s.products);

	if (!item) return null;

	const onSubmit = () => {
		dispatch(
			deleteProduct(item._id, err => {
				if (err) {
					console.log(err);
				} else {
					load && load();
					closeModal();
				}
			})
		);
	};

	const closeModal = () => {
		dispatch(setValue({ showDeleteForm: false }));
	};

	const initialValues = {
		name: item.name
	};

	return (
		<Modal toggle={closeModal} isOpen={Boolean(item)} title="Excluir Política">
			<DeleteForm
				closeModal={closeModal}
				initialValues={initialValues}
				onSubmit={onSubmit}
			/>
		</Modal>
	);
}

export default Delete;
