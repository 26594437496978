import * as api from "../../services/api";
import { getErrors } from "../../helpers/error";

export const GET_LEAD_DASHBOARD = "[LEAD DASHBOARD] GET_LEAD_DASHBOARD";
export const GET_LEAD_DASHBOARD_ERROR =
	"[LEAD DASHBOARD] GET_LEAD_DASHBOARD_ERROR";
export const GET_LEAD_DASHBOARD_SUCCESS =
	"[LEAD DASHBOARD] GET_LEAD_DASHBOARD_SUCCESS";

export const SET_VALUE = "[LEAD DASHBOARD] SET_VALUE";
export const CLEAR_VALUES = "[LEAD DASHBOARD] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getLeadDashboard() {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LEAD_DASHBOARD
		});

		try {
			const { login } = getState();

			let response = await api.sendGet("/lead/dashboard", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_LEAD_DASHBOARD_SUCCESS,
					payload: result.model
				});

			} else {
				dispatch({
					type: GET_LEAD_DASHBOARD_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			dispatch({
				type: GET_LEAD_DASHBOARD_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
