import TextArea from "components/ReduxForm/TextArea";
import Input from "components/ReduxForm/Input";
import React, { useRef } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import UploadFile from "components/ReduxForm/UploadFile";

//import renderSelectField from "shared/components/form/Select";

const createForm = ({ handleSubmit, reset, loading }) => {
	const form = useRef(null);

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Titulo</span>
						<div className="form__form-group-field">
							<Field
								name="title"
								component={Input}
								type="text"
								placeholder="Ex.: Nova versão do react18"
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field
								name="description"
								component={TextArea}
								type="text"
								placeholder="Ex.: Hoje as 18:00 saiu a nova versão do react 18 que veio trazendo...."
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={6} className="form__form-group">
						<span className="form__form-group-label">Banner</span>
						<div className="form__form-group-field">
							<Field
								name="bannerFile"
								component={UploadFile}
								accept="image/png,image/jpeg"
							/>
						</div>
					</Col>

					<Col xs={12} md={6} className="form__form-group">
						<span className="form__form-group-label">Video</span>
						<div className="form__form-group-field">
							<Field
								name="video"
								component={Input}
								type="url"
								placeholder="Video"
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "news_create"
})(createForm);
