import React from "react";
import { Route } from "react-router-dom";
import Index from "./index";

const routes = () => {
	return (
		<React.Fragment>
			<Route exact path="/register" component={Index} />
		</React.Fragment>
	);
};

export default routes;
