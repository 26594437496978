import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_BUILDERS = "[BUILDERS REPORT] GET_BUILDERS";
export const GET_BUILDERS_ERROR = "[BUILDERS REPORT] GET_BUILDERS_ERROR";
export const GET_BUILDERS_SUCCESS = "[BUILDERS REPORT] GET_BUILDERS_SUCCESS";

export const SET_VALUE = "[BUILDERS REPORT] SET_VALUE";
export const CLEAR_VALUES = "[BUILDERS REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getBuilders(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_BUILDERS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/company?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					""}&filters[companyType]=M`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_BUILDERS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_BUILDERS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_BUILDERS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const DELETE_BUILDERS = "[BUILDERS DELETE] DELETE_BUILDERS";
export const DELETE_BUILDERS_ERROR = "[BUILDERS DELETE] DELETE_BUILDERS_ERROR";
export const DELETE_BUILDERS_SUCCESS =
	"[BUILDERS DELETE] DELETE_BUILDERS_SUCCESS";

export function deleteClient(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_BUILDERS
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/company/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_BUILDERS_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_BUILDERS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_BUILDERS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_BUILDERS = "[BUILDERS EDIT] EDIT_BUILDERS";
export const EDIT_BUILDERS_ERROR = "[BUILDERS EDIT] EDIT_BUILDERS_ERROR";
export const EDIT_BUILDERS_SUCCESS = "[BUILDERS EDIT] EDIT_BUILDERS_SUCCESS";

export function editClient(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_BUILDERS
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/company/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_BUILDERS_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_BUILDERS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_BUILDERS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
