/* eslint-disable no-undef */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal";
import EditForm from "../../components/EditForm";
import { upload } from "redux/actions/upload";
import { editNews, setValue } from "../Report/actions";

function Edit({ load }) {
	const dispatch = useDispatch();

	const { loading, item, showEditForm } = useSelector(s => s.news);

	if (!item) return null;

	const onSubmit = async values => {
		const data = {
			...values,
			title: values.title,
			description: values.description,
			video: values.video,
			bannerFile: undefined
		};

		if (values.bannerFile && values.bannerFile[0]) {
			try {
				data.banner = await new Promise((res, rej) =>
					dispatch(
						upload("event", values.bannerFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				return;
			}
		}

		dispatch(
			editNews(item._id, data, err => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					closeModal();
					load && load();
				}
			})
		);
	};

	const closeModal = () => {
		dispatch(setValue({ showEditForm: false }));
	};

	const initialValues = {
		title: item.title,
		description: item.description,
		banner: item.banner,
		video: item.video
	};

	return (
		<Modal
			toggle={closeModal}
			isOpen={showEditForm}
			size="xl"
			title="Editar Cliente"
		>
			<EditForm
				closeModal={closeModal}
				initialValues={initialValues}
				onSubmit={onSubmit}
				loading={loading}
			/>
		</Modal>
	);
}

export default Edit;
