import * as actions from "./actions";

let initialState = {
	loading: {},
	errors: {}
};

export const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CHANGE_PASSWORD:
			return {
				...state,
				errors: initialState.errors,
				loading: { ...state.loading, change: true }
			};
		case actions.CHANGE_PASSWORD_SUCCESS:
			return {
				...initialState,
				loading: { ...state.loading, change: false },
				token: payload
			};
		case actions.CHANGE_PASSWORD_FAILED:
			return {
				...state,
				loading: { ...state.loading, change: false },
				errors: payload
			};
		case actions.SET_VALUE:
			if (payload.loading) {
				payload.loading = { ...state.loading, ...payload.loading };
			}
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default:
			return state;
	}
};

export default reducer;
