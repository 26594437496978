import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Row, Col } from "reactstrap";

let fetchDataDebounced = null;

const BuilderProfile30 = () => {
	const [builder, setBuilder] = useState(null);
	const dispatch = useDispatch();

	const login = useSelector(s => s.login);

	useEffect(() => {
		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}
	
		fetchDataDebounced = _.debounce(() => {
			// dispatch(
			// 	actions.getBuilders(
			// 		page * pageSize,
			// 		pageSize,
			// 		filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
			// 		sort.id,
			// 		sort.desc,
			// 		toExport,
			// 		callback
			// 	)
			// );
		}, 500);

		fetchDataDebounced();
	}, [login]);


	const handleContact = () => {
		console.log("Contato com a montadora");
	};

	return (
		<Container>
			<Row>
				<Col>
					{builder ? (
						<>
							<h1>{builder.name} - 30</h1>
							<div className="gallery">
								{builder.images.map((image, index) => (
									<img key={index} src={image} alt={`Imagem ${index + 1}`} />
								))}
							</div>
							<Button color="primary" onClick={handleContact}>
								Entrar em Contato
							</Button>
						</>
					) : (
						<p>Carregando informações da montadora...</p>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default BuilderProfile30;
