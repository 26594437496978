import React from "react";

const getIcon = icon => {
	if (!icon) return "";

	if (typeof icon === "string")
		return <span className={`sidebar__link-icon lnr lnr-${icon}`} />;

	return <span className={`sidebar__link-icon`}>{icon}</span>;
};

export default getIcon;
