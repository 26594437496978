export function toStringCurrency(value) {
	return Number(value || 0).toLocaleString("pt-BR", {
		currency: "BRL",
		currencyDisplay: "symbol",
		style: "currency",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
}

export function toStringPercentage(value = 0, value2 = 100, decimals = 2) {
	let v = (value * 100) / value2;
	if (isNaN(v)) v = 0;
	return `${v.toFixed(decimals)}%`;
}

export function stringSort(a, b, desc) {
	// force null and undefined to the bottom
	a = a === null || a === undefined ? "" : a;
	b = b === null || b === undefined ? "" : b;
	// force any string values to lowercase
	a = typeof a === "string" ? a.toLowerCase() : a;
	b = typeof b === "string" ? b.toLowerCase() : b;
	// Return either 1 or -1 to indicate a sort priority
	if (a > b) {
		return 1;
	}
	if (a < b) {
		return -1;
	}
	// returning 0, undefined or any falsey value will use subsequent sorts or
	// the index as a tiebreaker
	return 0;
}

export function removeDiacritics(string) {
	return String(string)
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "");
}
