import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";

import {
	clearValues,
	getClient,
	updateResponsibilityStatus,
	updateFileStatus
} from "./actions";
import { getBuilderClient, setValue } from "../Report/actions";

import Edit from "../Edit";
import DetailsCompany from "../../components/detail";
import Builder from "../../components/builder";
import Consumption from "containers/Builder/containers/Consumption";
import { isInRole } from "helpers/auth";
import userRole from "models/userRole";

import Users from "containers/User/containers/Report";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();

	const { user } = useSelector(state => state.login);

	const { item, loading } = useSelector(state => state.companyDetails);
	const { builder, loading: loadingReport } = useSelector(
		state => state.company
	);

	const load = () => {
		dispatch(getClient(id));
		dispatch(getBuilderClient(id));
	};

	const changeResponsibilityStatus = status => {
		dispatch(
			updateResponsibilityStatus(item._id, status, () => {
				console.log("Responsibility status updated");
				load();
			})
		);
	};

	const handleChangeFileStatus = (file, status, reason) => {
		dispatch(
			updateFileStatus(builder._id, file, status, reason, () => {
				console.log("File status updated");
				load();
			})
		);
	};

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, [dispatch]);

	const handleEditClick = () => {
		dispatch(
			setValue({
				showEditForm: true,
				item
			})
		);
	};

	const isAdmin = useMemo(() => {
		return isInRole(user, [userRole.ADMIN]);
	}, [user]);

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do Expositor</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
								{isAdmin && (
									<div>
										<Button onClick={handleEditClick} color="primary">
											Editar
										</Button>
									</div>
								)}
							</Row>
							<DetailsCompany
								company={item}
								loading={loading}
								isAdmin={isAdmin}
								changeResponsibilityStatus={changeResponsibilityStatus}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Users companyId={item._id} />
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes da montadora</h5>
									{loading.getBuilder && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
							</Row>
							<Builder
								item={builder}
								loading={loadingReport}
								changeFileStatus={handleChangeFileStatus}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Consumption companyId={item._id} />
				</Col>
			</Row>
			<Edit load={load} />
		</Container>
	);
}

export default Details;
