import React from "react";
import { Card, Row, Col, Container, Spinner } from "reactstrap";
import dayjs from "dayjs";
import ls from "Localization";
import { toStringCurrency } from "helpers/string";

const ProductDetail = ({ product, loading }) => {
	if (!product) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Row>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Data criação: </strong>
								<br />
								<span>
									{product.createdAt
										? dayjs(product.createdAt).format(ls.dateTimeFormat)
										: "Não definido"}
								</span>
							</p>
						</Col>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Código: </strong>
								<br />
								<span>{product.code ? product.code : "Não definido"}</span>
							</p>
						</Col>

						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Nome: </strong>
								<br />
								<span>{product.name ? product.name : "Não definido"}</span>
							</p>
						</Col>
					</Row>

					<Row>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Política de Preços: </strong>
								<br />
								<span>
									{product.pricePolicy
										? product.pricePolicy.name
										: "Não definido"}
								</span>
							</p>
						</Col>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Preço: </strong>
								<br />
								<span>
									{product.costPrice
										? toStringCurrency(product.costPrice / 100)
										: "Não definido"}
								</span>
							</p>
						</Col>

						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Modelo: </strong>
								<br />
								<span>
									{product.modelProduct ? product.modelProduct : "Não definido"}
								</span>
							</p>
						</Col>
					</Row>

					<Row>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Marca: </strong>
								<br />
								<span>{product.brand ? product.brand : "Não definido"}</span>
							</p>
						</Col>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Fabricante: </strong>
								<br />
								<span>
									{product.manufacturer ? product.manufacturer : "Não definido"}
								</span>
							</p>
						</Col>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Origem: </strong>
								<br />
								<span>{product.origin ? product.origin : "Não definido"}</span>
							</p>
						</Col>
					</Row>

					<Row>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Código Inmetro: </strong>
								<br />
								<span>
									{product.inmetroNumber
										? product.inmetroNumber
										: "Não definido"}
								</span>
							</p>
						</Col>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>É Importado? </strong>
								<br />
								<span>{product.imported ? "Sim" : "Não"}</span>
							</p>
						</Col>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Código Alfandegário: </strong>
								<br />
								<span>
									{product.customsCode ? product.customsCode : "Não definido"}
								</span>
							</p>
						</Col>

						<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
							<p>
								<strong>Categoria: </strong>
								<br />
								<span>
									{product.category ? product.category.name : "Não definido"}
								</span>
							</p>
						</Col>
					</Row>

					<Row>
						<Col className="mt-3" xs={12}>
							<p>
								<strong>Descrição: </strong>
								<br />
								<span>{product ? product.description : "Não definido"}</span>
							</p>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default ProductDetail;
