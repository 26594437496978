import React, { useState } from "react";
import { reset } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { findUser, checkInOut } from "./actions";
import _ from "lodash";
import SelfServiceForm from "./components/SelfServiceForm";
import Alert from "../../shared/components/Alert";
import actions from "redux/actions";
import { generateUserTag } from "helpers/tag";

const SelfService = ({ match }) => {
	const dispatch = useDispatch();

	const { type, event } = match?.params || {};

	const [error, setError] = useState("");
	const [page, setPage] = useState(1);
	const [data, setData] = useState(null);

	const { message } = useSelector(s => s.login);

	const handleGenerateQrCode = async data => {
		await generateUserTag(data, true);

		if (!["reader"].includes(page)) setPage(1);

		setError("");
	};

	const handleDismiss = () => {
		dispatch(actions.login.setValue({ message: "" }));
	};

	const onSubmit = data => {
		console.log("Data: ", data);
		try {
			const payload =
				(data.document ? data.document.match(/\d/g)?.join("") : "") ||
				data.email ||
				data.result;

			console.log("Payload: ", payload);

			if (["in", "out"].includes(type.toLowerCase())) {
				dispatch(
					checkInOut(event, payload, type, (err, result) => {
						dispatch(reset("self_service_form"));
						if (err) {
							setError(err.default || err.error || err.message || err);

							dispatch(actions.login.setValue({ message: "Não encontrado" }));
						} else {
							console.log("Processing result: ", result);

							setData(result?.user);
						}
					})
				);
			} else {
				dispatch(
					findUser(payload, (err, result) => {
						dispatch(reset("self_service_form"));
						if (err) {
							setError(err.default || err.error || err.message || err);

							setTimeout(() => {
								handleReload();
							}, 5000);
						} else {
							console.log("Processing result: ", result);

							if (result && result._id) {
								handleGenerateQrCode(result);
							}
						}
					})
				);
			}
		} catch (err) {
			console.error("There was an error processing the submission: ", err);
		}
	};

	const handleReload = () => {
		location.reload();
	};

	return (
		<div className="account">
			{message && (
				<div
					style={{
						position: "absolute",
						right: 16,
						top: 16
					}}
				>
					<Alert
						color="info"
						className="alert--bordered"
						icon
						onDismiss={handleDismiss}
					>
						<p>
							<span className="bold-text">{message}</span>
						</p>
					</Alert>
				</div>
			)}
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__logo__head">
						<div className="account__logo" />
					</div>

					{error ? (
						<div>
							<h3 className="account__title">{error}</h3>
							<button
								type="button"
								className="btn btn-primary account__btn account__btn--small"
								onClick={() => {
									handleReload();
								}}
							>
								Voltar
							</button>
						</div>
					) : (
						<SelfServiceForm
							onSubmit={onSubmit}
							page={page}
							setPage={setPage}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SelfService;
