import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_PRODUCTS = "[PRODUCTS DETAIL] GET_PRODUCTS";
export const GET_PRODUCTS_ERROR = "[PRODUCTS DETAIL] GET_PRODUCTS_ERROR";
export const GET_PRODUCTS_SUCCESS = "[PRODUCTS DETAIL] GET_PRODUCTS_SUCCESS";

export const SET_VALUE = "[PRODUCTS DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[PRODUCTS DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getProduct(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PRODUCTS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/product/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PRODUCTS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_PRODUCTS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_PRODUCTS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
