import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";

import { clearValues, getCashback } from "./actions";

import DetailsCashback from "../../components/detail";
import { FaRegCopy } from "react-icons/fa";
import { isInRole } from "helpers/auth";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();
	const { item, loading } = useSelector(state => state.cashbackDetails);
	const { user } = useSelector(state => state.login);

	const load = () => {
		dispatch(
			getCashback(id, error => {
				console.log(error);
			})
		);
	};

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, [dispatch]);

	// const handleEditClick = () => {
	// 	dispatch(
	// 		setValue({
	// 			showEditForm: true,
	// 			item
	// 		})
	// 	);
	// };

	const handleCopyClick = () => {
		window.open(
			`${process.env.REACT_APP_PAYMENT_URL}/cashback/request/${item._id}`
		);
	};

	const isAdmin = useMemo(() => {
		return isInRole(user, ["admin"]);
	}, [user]);

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do Cashback</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
								<div>
									<ButtonGroup>
										<Button onClick={handleCopyClick} color="secondary">
											<FaRegCopy name="FaRegCopy" /> Link/recibo pagamento
										</Button>
										{/* <Button onClick={handleEditClick} color="primary">
											Editar
										</Button> */}
									</ButtonGroup>
								</div>
							</Row>
							<DetailsCashback
								cashbackDetails={item}
								loading={loading}
								isAdmin={isAdmin}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>

			{/* <Edit load={load} /> */}
		</Container>
	);
}

export default Details;
