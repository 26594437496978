import React from "react";
import { Route, Switch } from "react-router-dom";
import Report from "./containers/Report";
import Detail from "./containers/Detail";
import Consumption from "./containers/Consumption";
import CreateConsumption from "./containers/Consumption/Create";
import BuilderProfile18 from "./containers/BuilderOficial/18";
import BuilderProfile36 from "./containers/BuilderOficial/36";
import BuilderProfile45 from "./containers/BuilderOficial/45";
import BuilderProfile54 from "./containers/BuilderOficial/54";
import BuilderSafe from "./containers/BuilderOficial/Safe";
import BuilderTrip from "./containers/BuilderOficial/Trip";
import BuilderGraphic from "./containers/BuilderOficial/Graphic";
import BuilderMarketing from "./containers/BuilderOficial/Marketing";
import BuilderArchitect from "./containers/BuilderOficial/Architect";
import BuilderProfile22_5 from "./containers/BuilderOficial/22.5";
import BuilderProfile27 from "./containers/BuilderOficial/27";
import BuilderProfile30 from "./containers/BuilderOficial/30";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/builder" component={Report} />
				<Route exact path="/builder/consumption" component={Consumption} />
				<Route exact path="/builder/consumption/add" component={CreateConsumption} />
				<Route exact path="/builder/official/18" component={BuilderProfile18} />
				<Route exact path="/builder/official/22-5" component={BuilderProfile22_5} />
				<Route exact path="/builder/official/27" component={BuilderProfile27} />
				<Route exact path="/builder/official/30" component={BuilderProfile30} />
				<Route exact path="/builder/official/36" component={BuilderProfile36} />
				<Route exact path="/builder/official/45" component={BuilderProfile45} />
				<Route exact path="/builder/official/54" component={BuilderProfile54} />
				<Route exact path="/builder/safe" component={BuilderSafe} />
				<Route exact path="/builder/trip" component={BuilderTrip} />
				<Route exact path="/builder/marketing" component={BuilderMarketing} />
				<Route exact path="/builder/architect" component={BuilderArchitect} />
				<Route exact path="/builder/graphic" component={BuilderGraphic} />
				<Route exact path="/builder/:id" component={Detail} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
