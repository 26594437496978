import React, { useState } from "react";
import { Collapse } from "reactstrap";

import TopbarMenuLink from "./TopbarMenuLink";

export default function TopbarStore() {
	const [collapse, setCollapse] = useState(false);

	const toggle = () => {
		setCollapse(e => !e);
	};

	return (
		<div className="topbar__profile">
			{collapse && (
				<button type="button" className="topbar__back" onClick={toggle} />
			)}
			<Collapse isOpen={collapse} className="topbar__menu-wrap">
				<div className="topbar__menu">
					<TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
					<TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" />
					<div className="topbar__menu-divider" />
				</div>
			</Collapse>
		</div>
	);
}
