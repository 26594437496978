import TextArea from "components/ReduxForm/TextArea";
import React from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import { MONEY } from "components/NumberFormat";
import UploadFile from "components/ReduxForm/UploadFile";

const createForm = ({ handleSubmit, reset, loading }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field
								name="description"
								component={TextArea}
								type="text"
								autoFocus
								placeholder="Ex.: Compra de 200m² de madeira..."
							/>
						</div>
					</Col>

					<Col xs={12} md={6} lg={4} xl={3} className="form__form-group">
						<span className="form__form-group-label">Valor</span>
						<div className="form__form-group-field">
							<Field
								name="value"
								component={MONEY}
								type="text"
								placeholder="Ex.: 500,00"
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Anexar Arquivos</span>
						<div className="form__form-group-field">
							<Field
								name="files"
								component={UploadFile}
								type="file"
								multiple
							/>
						</div>
						<small className="form-text text-muted">
							Aceita formatos de imagem e PDF.
						</small>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Enviar orçamento"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "budget_create",
	validate
})(createForm);
