import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";
import _ from "lodash";

export const GET_CASHBACKS = "[CASHBACKS REPORT] GET_CASHBACKS";
export const GET_CASHBACKS_ERROR =
	"[CASHBACKS REPORT] GET_CASHBACKS_ERROR";
export const GET_CASHBACKS_SUCCESS =
	"[CASHBACKS REPORT] GET_CASHBACKS_SUCCESS";

export const SET_VALUE = "[CASHBACKS REPORT] SET_VALUE";
export const CLEAR_VALUES = "[CASHBACKS REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getCashbacks(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CASHBACKS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/cashback?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_CASHBACKS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_CASHBACKS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_CASHBACKS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_CASHBACKS_EDIT =
	"[CASHBACKS EDIT] EDIT_CASHBACKS_EDIT";
export const EDIT_CASHBACKS_EDIT_ERROR =
	"[CASHBACKS EDIT] EDIT_CASHBACKS_EDIT_ERROR";
export const EDIT_CASHBACKS_EDIT_SUCCESS =
	"[CASHBACKS EDIT] EDIT_CASHBACKS_EDIT_SUCCESS";

export function editCashback(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_CASHBACKS_EDIT
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/cashback/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_CASHBACKS_EDIT_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_CASHBACKS_EDIT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_CASHBACKS_EDIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const DELETE_CASHBACKS_DELETE =
	"[CASHBACKS DELETE] DELETE_CASHBACKS_DELETE";
export const DELETE_CASHBACKS_DELETE_ERROR =
	"[CASHBACKS DELETE] DELETE_CASHBACKS_DELETE_ERROR";
export const DELETE_CASHBACKS_DELETE_SUCCESS =
	"[CASHBACKS DELETE] DELETE_CASHBACKS_DELETE_SUCCESS";

export function deleteCashback(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_CASHBACKS_DELETE
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/cashback/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_CASHBACKS_DELETE_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_CASHBACKS_DELETE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_CASHBACKS_DELETE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
