import { applyMiddleware, compose, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "localforage";
import reducers from "./reducers";
import thunk from "redux-thunk";
import LogRocket from "logrocket";

import { jwt } from "./middlewares/jwt";

const persistConfig = {
	key: "root",
	storage,
	blacklist: []
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers =
	typeof window === "object" && window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]
		? window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]({})
		: compose;

const enhancer = composeEnhancers(
	applyMiddleware(
		jwt,
		thunk,
		LogRocket.reduxMiddleware({
			actionSanitizer: action => {
				if (action.payload) {
					if (action.payload.password) action.payload.password = null;
					if (action.payload.login) {
						if (action.payload.login.password)
							action.payload.login.password = null;
						// if (action.payload.login.token) action.payload.login.token = null;
					}
				}

				return action;
			}
			// stateSanitizer: state => {
			//   state = { ...state };
			//   if (state.login) {
			//     if (state.login.password) state.login.password = null;
			//     if (state.login.token) state.login.token = null;
			//   }

			//   return state;
			// }
		})
	)
);

export default () => {
	let store = createStore(persistedReducer, enhancer);
	let persistor = persistStore(store);
	return { store, persistor };
};
