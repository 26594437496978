import { CNPJ } from "components/NumberFormat";
import ReportTable from "components/Report";
import dayjs from "dayjs";
import ls from "Localization";
import _ from "lodash";
import React, { useRef } from "react";
import { MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import Delete from "../Delete";
import Edit from "../Builder/Edit";
import * as actions from "../Report/actions";
import DateSelectFilter from "components/Report/DateSelectorFilter";
import DetailsBuilder from "./Detail";

let fetchDataDebounced = null;

function Report({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.company);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];

		const createdAt = filtered.find(c => c.id === "createdAt");

		if (createdAt?.value) {
			filtered = filtered.filter(c => c.id !== "createdAt");

			if (createdAt?.value.startDate) {
				filtered.push({
					id: "startDate",
					value: createdAt.value.startDate
				});
			}

			if (createdAt?.value.endDate)
				filtered.push({
					id: "endDate",
					value: createdAt.value.endDate
				});
		}

		filtered = filtered.filter(c => c.id !== "companyType");

		filtered.push({
			id: "companyType",
			value: "E"
		});

		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getCompanies(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	const handleDeleteClick = data => {
		dispatch(
			actions.setValue({
				showDeleteForm: data
			})
		);
	};

	const handleEditClick = data => {
		console.log(data);
		dispatch(
			actions.getBuilderClient(data._id, (err, data) => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					dispatch(
						actions.setValue({
							showEditForm: true,
							item: data
						})
					);
				}
			})
		);
	};

	const handleShowClick = data => {
		console.log(data);
		dispatch(
			actions.getBuilderClient(data._id, (err, data2) => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					dispatch(
						actions.setValue({
							showDetailForm: true,
							item: { ...data2, company: data }
						})
					);
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title="Expositores"
						tableRef={table}
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						// onRowClicked={row => history.push(`/company/` + row._id)}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateTimeFormat)
										: "",
								width: 120,
								Filter: p => <DateSelectFilter {...p} />
							},
							{
								Header: "Razão Social",
								accessor: "name"
							},
							{
								Header: "Nome",
								accessor: "socialName",
								width: 150
							},
							{
								Header: "CNPJ",
								id: "document",
								accessor: c =>
									c.document ? (
										<CNPJ displayType="text" value={c.document} />
									) : (
										"Não definido"
									),
								width: 150
							},
							{
								Header: "Telefone",
								id: "phoneNumber",
								accessor: c =>
									c.nationalPhoneNumber
										? c.nationalPhoneNumber
										: "Não definido",
								width: 120
							},
							{
								Header: "E-mail",
								accessor: "email",
								width: 150
							},
							{
								Header: "Ações",
								width: 200,
								filterable: false,
								id: "actions",
								Cell: props => {
									return (
										<ButtonToolbar>
											<ButtonGroup className="btn-group--icons" dir="ltr">
												<Button
													color="primary"
													onClick={() => handleShowClick(props.original)}
												>
													<FaEye style={{ margin: 0 }} />
												</Button>
												<Button
													color="secondary"
													onClick={() => handleEditClick(props.original)}
												>
													<MdEdit style={{ margin: 0 }} /> Anexar documentos
												</Button>

												{/* <Button
													color="cancel"
													onClick={() => handleDeleteClick(props.original)}
												>
													<MdDelete style={{ margin: 0 }} />
												</Button> */}
											</ButtonGroup>
										</ButtonToolbar>
									);
								}
							}
						]}
					/>
				</Col>
			</Row>
			<Delete load={load} />
			<Edit load={load} />
			<DetailsBuilder />
		</Container>
	);
}

export default Report;
