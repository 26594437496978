import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Button,
	Spinner
} from "reactstrap";
import { getUser, clearValues, changeUserStatus } from "./actions";
import { setValue } from "../Report/actions";

import DetailsPerson from "../../components/detail";
import DetailsPersonCompany from "../../components/detailCompany";
import DetailsPersonAddress from "../../components/detailAddress";

import Edit from "../Edit";
import Report from "./Report";
import ProfessionalFiles from "./ProfessionalFiles";
import Lead from "containers/Lead/containers/Kanban";
import { isInRole } from "helpers/auth";
import userRole from "models/userRole";
import { generateUserTag } from "helpers/tag";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();

	const { item, loading } = useSelector(state => state.userDetails);
	const { user } = useSelector(state => state.login);

	const load = () => {
		dispatch(getUser(id));
	};

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, [dispatch]);

	const isAdmin = useMemo(() => {
		if (!user) return false;

		return isInRole(user, [userRole.ADMIN]);
	}, [user]);

	const isReceptionist = useMemo(() => {
		if (!user) return false;

		return isInRole(user, [userRole.RECEPTIONIST]);
	}, [user]);

	const handleEnable = () => {
		dispatch(
			changeUserStatus(id, 1, err => {
				if (err) {
					console.log(err);
				} else {
					dispatch(getUser(id));
				}
			})
		);
	};

	const handleDisable = () => {
		dispatch(
			changeUserStatus(id, 0, err => {
				if (err) {
					console.log(err);
				} else {
					dispatch(getUser(id));
				}
			})
		);
	};

	const handleTag = () => {
		generateUserTag(item);
	};

	const handleEditClick = () => {
		dispatch(
			setValue({
				showEditForm: true,
				item
			})
		);
	};

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do usuário</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>

								<div>
									{(isAdmin || isReceptionist) && (
										<Button color="primary" onClick={handleTag}>
											Imprimir Tag
										</Button>
									)}
									{item && item.status !== 1 && (
										<Button color="primary" onClick={handleEnable}>
											Ativar
										</Button>
									)}
									{item && item.status === 1 && (
										<Button color="danger" onClick={handleDisable}>
											Desativar
										</Button>
									)}
									<Button onClick={handleEditClick} color="primary">
										Editar
									</Button>
								</div>
							</Row>
							<DetailsPerson client={item} loading={loading} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Empresa</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
							</Row>
							<DetailsPersonCompany client={item} loading={loading} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			{item.professionalFiles?.find(c => c.url) && (
				<Row>
					<Col xs={12}>
						<Card style={{ height: "unset" }}>
							<CardBody>
								<ProfessionalFiles
									items={item.professionalFiles.filter(c => c.url)}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Endereço</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
							</Row>
							<DetailsPersonAddress client={item} loading={loading} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Lead userId={id} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Report userId={id} />
				</Col>
			</Row>
			<Edit load={load} />
		</Container>
	);
}

export default Details;
