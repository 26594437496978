import { CNPJ } from "components/NumberFormat";
import ReportTable from "components/Report";
import dayjs from "dayjs";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import Delete from "../Delete";
import Edit from "../Edit";
import * as actions from "./actions";
import DateSelectFilter from "components/Report/DateSelectorFilter";
import { isInRole } from "helpers/auth";
import userRole from "models/userRole";
import { FiLink } from "react-icons/fi";
import TooltipComponent from "components/TooltipComponent";
import Swal from "sweetalert2";

let fetchDataDebounced = null;

function Report({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.company);
	const { user } = useSelector(state => state.login);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];

		const createdAt = filtered.find(c => c.id === "createdAt");

		if (createdAt?.value) {
			filtered = filtered.filter(c => c.id !== "createdAt");

			if (createdAt?.value.startDate) {
				filtered.push({
					id: "startDate",
					value: createdAt.value.startDate
				});
			}

			if (createdAt?.value.endDate)
				filtered.push({
					id: "endDate",
					value: createdAt.value.endDate
				});
		}

		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getCompanies(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	const handleDeleteClick = data => {
		dispatch(
			actions.setValue({
				showDeleteForm: data
			})
		);
	};

	const handleEditClick = data => {
		dispatch(
			actions.setValue({
				showEditForm: true,
				item: data
			})
		);
	};

	const isAdmin = isInRole(user, [userRole.ADMIN]);

	const columns = useMemo(() => {
		return [
			{
				Header: "Data criação",
				id: "createdAt",
				accessor: c =>
					c.createdAt ? dayjs(c.createdAt).format(ls.dateTimeFormat) : "",
				width: 120,
				Filter: p => <DateSelectFilter {...p} />
			},
			{
				Header: "Razão Social",
				accessor: "name"
			},
			{
				Header: "Nome",
				accessor: "socialName",
				width: 150
			},
			{
				Header: "CNPJ",
				id: "document",
				accessor: c =>
					c.document ? (
						<CNPJ displayType="text" value={c.document} />
					) : (
						"Não definido"
					),
				width: 150
			},
			{
				Header: "Telefone",
				id: "phoneNumber",
				accessor: c =>
					c.nationalPhoneNumber ? c.nationalPhoneNumber : "Não definido",
				width: 120
			},
			{
				Header: "E-mail",
				accessor: "email",
				width: 150
			},
			{
				Header: "Ações",
				width: 80,
				filterable: false,
				id: "actions",
				Cell: props => {
					if (!isAdmin) return null;

					return (
						<ButtonToolbar>
							<ButtonGroup className="btn-group--icons" dir="ltr">
								<Button
									color="primary"
									onClick={() => handleEditClick(props.original)}
								>
									<MdEdit style={{ margin: 0 }} />
								</Button>

								<Button
									color="cancel"
									onClick={() => handleDeleteClick(props.original)}
								>
									<MdDelete style={{ margin: 0 }} />
								</Button>
							</ButtonGroup>
						</ButtonToolbar>
					);
				}
			}
		];
	}, [isAdmin]);

	const handleGenerateLink = useCallback(() => {
		const expositorId = user.company._id;

		const urlGenerate = `${process.env.REACT_APP_APP_URL}/cadastro/provider/${expositorId}`;

		navigator.clipboard.writeText(urlGenerate);

		Swal.fire({
			title: "Link copiado!",
			html: urlGenerate,
			icon: "success",
			confirmButtonText: "Ok"
		});
	}, [user.company?._id]);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title="Prestadores de Serviço"
						tableRef={table}
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						onRowClicked={row => history.push(`/ServiceProvider/` + row._id)}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={columns}
						headerRightComponent={
							isAdmin ? null : (
								<>
									<Button
										color="primary"
										id="generate-link"
										onClick={handleGenerateLink}
										size="md"
									>
										<FiLink />
									</Button>
									<TooltipComponent
										title="Gerar link de cadastro"
										target="generate-link"
									/>
								</>
							)
						}
					/>
				</Col>
			</Row>
			<Delete load={load} />
			<Edit load={load} />
		</Container>
	);
}

export default Report;
