import React from "react";
import ReportBudget from "../../../Lead/containers/Detail/ReportBudget";

function Report() {
	return (
		<div>
			<ReportBudget showUser />
		</div>
	);
}

export default Report;
