import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_CASHBACK = "[CASHBACK DETAIL] GET_CASHBACK";
export const GET_CASHBACK_ERROR = "[CASHBACK DETAIL] GET_CASHBACK_ERROR";
export const GET_CASHBACK_SUCCESS = "[CASHBACK DETAIL] GET_CASHBACK_SUCCESS";

export const SET_VALUE = "[CASHBACK DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[CASHBACK DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getCashback(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CASHBACK
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/cashback/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CASHBACK_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_CASHBACK_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_CASHBACK_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
