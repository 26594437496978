import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const CREATE_CASHBACK = "[CASHBACK CREATE] CREATE_CASHBACK";
export const CREATE_CASHBACK_ERROR = "[CASHBACK CREATE] CREATE_CASHBACK_ERROR";
export const CREATE_CASHBACK_SUCCESS =
	"[CASHBACK CREATE] CREATE_CASHBACK_SUCCESS";

export const SET_VALUE = "[CASHBACK CREATE] SET_VALUE";
export const CLEAR_VALUES = "[CASHBACK CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createCashback(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_CASHBACK
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/cashback`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_CASHBACK_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: CREATE_CASHBACK_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error, null);

			return dispatch({
				type: CREATE_CASHBACK_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
