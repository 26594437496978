import React from "react";
import { Button, Row } from "reactstrap";
import viagensImage from "../../../../../assets/graphic.png";

const BuilderTrip = () => {
	const handleContact = () => {
		window.open(
			"https://graficarocha.com.br/landing-page-autores-scads/?utm_source=construir&utm_medium=campain",
			"_blank"
		);
	};

	return (
		<Row>
			<div style={{ position: "relative", width: "100%", marginTop: -40 }}>
				<img
					src={viagensImage}
					alt="Viagens"
					style={{ width: "100%", height: "auto" }}
				/>
				<Button
					style={{
						position: "absolute",
						bottom: -30,
						left: 0,
						right: 0,
						padding: "18px 100px",
						fontSize: "1.2em"
					}}
					color="primary"
					onClick={handleContact}
				>
					Saiba mais
				</Button>
			</div>
		</Row>
	);
};

export default BuilderTrip;
