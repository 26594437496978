import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";

import { clearValues, getBudget } from "./actions";
import { setValue } from "containers/Lead/containers/Detail/ReportBudget/actions";

import DetailsBudget from "../../components/detail";
import Edit from "../Edit";
import Report from "./ReportProduct";
import { FaRegCopy } from "react-icons/fa";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();
	const { item, loading } = useSelector(state => state.budgetDetails);

	const load = () => {
		dispatch(
			getBudget(id, error => {
				console.log(error);
			})
		);
	};

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, [dispatch]);

	const handleEditClick = () => {
		dispatch(
			setValue({
				showEditForm: true,
				item
			})
		);
	};

	const handleCopyClick = () => {
		navigator.clipboard
			.writeText(`${process.env.REACT_APP_PAYMENT_URL}/budget/request/${id}`)
			.then(() => alert("Link copiado!"));
	};

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do Orçamento</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
								<div>
									<ButtonGroup>
										<Button onClick={handleCopyClick} color="secondary">
											<FaRegCopy name="FaRegCopy" /> Link pagamento
										</Button>
										<Button onClick={handleEditClick} color="primary">
											Enviar orçamento
										</Button>
									</ButtonGroup>
								</div>
							</Row>
							<DetailsBudget budgetDetails={item} loading={loading} />
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Report />
			<Edit load={load} />
		</Container>
	);
}

export default Details;
