import React from "react";
import { Card, Row, Col, Container, Spinner } from "reactstrap";

const UserDetailCompany = ({ client, loading }) => {
	if (!client) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Col>
						<Row>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Nome da empresa: </strong>
									<br />
									<span>
										{client.companyName ? client.companyName : "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Razão social: </strong>
									<br />
									<span>
										{client.companySocialName
											? client.companySocialName
											: "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>CNPJ: </strong>
									<br />
									<span>
										{client.companyDocument
											? client.companyDocument
											: "Não definido"}
									</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Setor: </strong>
									<br />
									<span>{client.sector ? client.sector : "Não definido"}</span>
								</p>
							</Col>
						</Row>
					</Col>
				</Container>
			</Card>
		</div>
	);
};

export default UserDetailCompany;
