import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import { CPF } from "components/NumberFormat";

const DocumentPage = props => {
	const { handleSubmit, pristine, previousPage, submitting, ref } = props;
	return (
		<form className="form" ref={ref} onSubmit={handleSubmit}>
			<div className="form__form-group">
				<span className="form__form-group-label">CPF</span>
				<div className="form__form-group-field">
					<Field
						name="document"
						type="text"
						autoFocus={true}
						component={CPF}
						autoComplete="off"
					/>
				</div>
			</div>
			<div className="account__btn__group">
				<button
					type="button"
					className="btn btn-primary account__btn account__btn--small"
					onClick={previousPage}
				>
					Voltar
				</button>
				<button
					type="submit"
					className="btn btn-primary account__btn account__btn--small"
					disabled={pristine || submitting}
				>
					Enviar
				</button>
			</div>
		</form>
	);
};
export default reduxForm({
	form: "self_service_form", //Form name is same
	validate
})(DocumentPage);
