const validate = values => {
	const errors = {};
	const fields = ["code", "name", "costPrice", "category", "pricePolicy"];

	for (let field of fields) {
		if (!values[field]) errors[field] = `Campo obrigatório`;
	}

	return errors;
};

export default validate;
