import React from "react";

export default function UploadFile({
	input: { value: omitValue, onChange, ...inputProps },
	meta: omitMeta,
	...props
}) {
	const handleChange = e => {
		onChange(e.target.files);
	};

	return (
		<input type="file" {...inputProps} {...props} onChange={handleChange} />
	);
}
