import React, { PureComponent } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { isMobileOnly } from "react-device-detect";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import ptBr from "date-fns/locale/pt-BR";

registerLocale("pt-BR", ptBr);

class DatePickerField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);

		let date = props.value ? dayjs(props.value) : null;
		let startDate = null;

		if (date && date.isValid()) {
			startDate = date.toDate();
		}

		this.state = {
			startDate
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) {
		const { onChange } = this.props;
		this.setState({
			startDate: date
		});
		onChange(date);
	}

	render() {
		const { startDate } = this.state;

		const { datePickerProps } = this.props;

		return (
			<div className="date-picker">
				<DatePicker
					placeholderText="01/01/2001"
					className="form__form-group-datepicker"
					selected={startDate}
					onChange={this.handleChange}
					dateFormat="dd/MM/yyyy"
					locale="pt-BR"
					dropDownMode="select"
					showYearDropdown={true}
					popperPlacement="center"
					withPortal={isMobileOnly}
					{...datePickerProps}
				/>
			</div>
		);
	}
}

const renderDatePickerField = props => {
	const { input, meta, className, datePickerProps } = props;
	return (
		<div className={`form__form-group-input-wrap ${className}`}>
			<DatePickerField {...input} datePickerProps={datePickerProps || {}} />
			{meta.touched && meta.error && (
				<span className="form__form-group-error">{meta.error}</span>
			)}
		</div>
	);
};

renderDatePickerField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string
	}).isRequired
};

export default renderDatePickerField;
