import React, { useState, useEffect, useCallback } from "react";
import { reduxForm } from "redux-form";
import validate from "./validate";
import { QrReader } from "react-qr-reader";
import _ from "lodash";
import { Spinner } from "reactstrap";

const QRCodePage = props => {
	const [stopRead, setStopRead] = useState(false);
	const { previousPage } = props;

	const handleScanDebounced = useCallback(
		_.debounce(resultText => {
			console.log("Result:", resultText);

			const qrData = { result: resultText };
			props.onSubmit(qrData);
		}, 1000),
		[]
	);

	useEffect(() => {
		return () => {
			if (handleScanDebounced) {
				handleScanDebounced.cancel();
			}
		};
	}, [handleScanDebounced]);

	const handleScan = result => {
		if (result?.text && !stopRead) {
			setStopRead(true);
			handleScanDebounced(result.text);
		}
	};

	if (stopRead) {
		handleScanDebounced.flush();
		return (
			<div
				className="form"
				style={{
					width: "350px",
					height: "300px",
					padding: "20px",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					boxSizing: "border-box"
				}}
			>
				<Spinner type="grow" size="sm" color="dark" />
			</div>
		);
	}

	return (
		<div
			className="form"
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				boxSizing: "border-box"
			}}
		>
			<h3 className="form__title" style={{ marginBottom: "6px" }}>
				Escaneie o QR-Code
			</h3>
			<div
				className="form__qr-reader"
				style={{
					display: "flex",
					justifyContent: "center"
				}}
			>
				<QrReader
					delay={300}
					onResult={handleScan}
					ViewFinder={({ width, height }) => (
						<div
							style={{
								width: "320px"
							}}
						/>
					)}
				/>
			</div>
			<div
				className="account__btn__group"
				style={{
					display: "flex",
					justifyContent: "space-around",
					width: "100%",
					maxWidth: "300px"
				}}
			>
				<button
					type="button"
					className="btn btn-primary account__btn account__btn--small"
					onClick={previousPage}
					style={{ flexGrow: 1, margin: "0 10px" }}
				>
					Voltar
				</button>
			</div>
		</div>
	);
};

export default reduxForm({
	form: "self_service_form",
	validate
})(QRCodePage);
