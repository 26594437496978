import QRCode from "qrcode";
import { toPng } from "html-to-image";

export async function generateUserTag(data, close) {
	const response = await QRCode.toDataURL(data._id, {
		width: 250,
		margin: 2
	});

	if (!data.status || !data.company?.status || data.company?.deleted) {
		data.company = null;
	}

	let child = document.createRange().createContextualFragment(`    <div
		id="qr"
		style="
		  height: 500px;
		  width: 800px;
		  padding: 30px;
		  background-color: rgb(255, 255, 255);
		  display: flex;
		  flex-direction: column;
		  justify-content: space-evenly;
		"
	  >
		<div style="width: 730px; height: 90px; align-items: center; display: flex">
		  <span
			style="
			  width: 100%;
			  line-height: 100%;
			  font-size: ${(() => {
					let name = String(data.fullName || "");
					if (name.length < 20) return "3.5";
					return "3.25";
				})()}rem;
			  font-weight: 500;
			  white-space: nowrap;
			  overflow: hidden;
			  text-overflow: ellipsis;
			  text-align: center;
			"
		  >
			${(() => {
				let name = String(data.fullName || "");
				const len = name.length;

				if (len > 20) {
					const names = name.split(" ");

					const first = names.shift();
					const last = names.pop();

					const meddle = names
						.map(c => c[0])
						.join(" ")
						.toUpperCase();

					name = `${first} ${meddle} ${last}`;
				}

				return name;
			})()}
		  </span>
		</div>
		<div
		  style="
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 730px
		  "
		>
		  <div style="flex: 1">
			<div
			  style="
			  font-size: ${(() => {
					let name = String(
						data.company?.socialName ||
							data.company?.name ||
							data.companyName ||
							""
					);

					let names = name.split(" ");

					names = names.slice(0, 3);

					name = names.join(" ");

					if (name.length <= 10) return "3";
					if (name.length <= 15) return "2.75";
					if (name.length <= 20) return "2.5";
					if (name.length <= 30) return "2.25";
					return "2";
				})()}rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 600px;
				text-align: center;
			  "
			>
			  ${(() => {
					let name = String(
						data.company?.socialName ||
							data.company?.name ||
							data.companyName ||
							""
					);

					let names = name.split(" ");

					names = names.slice(0, 3);

					name = names.join(" ");

					// const len = name.length;

					// if (len > 15) {
					// 	const names = name.split(" ");

					// 	const first = names.shift();
					// 	const last = names.pop();

					// 	if (last) {
					// 		name = `${first.substring(0, 3)}. ${last}`;
					// 	} else {
					// 		name = first.substring(0, 14);
					// 	}
					// }

					return name;
				})()}
			</div>
			<div
			  style="
				font-size: ${(() => {
					let name = String(
						data.company?.name
							? ["M", "S"].includes(data.company?.companyType)
								? data.profession || data.sector || ""
								: "Expositor"
							: data.profession || data.sector || "Visitante"
					);

					if (name.length <= 10) return "3";
					if (name.length <= 15) return "2.75";
					if (name.length <= 20) return "2.5";
					if (name.length <= 30) return "2.25";
					return "2";
				})()}rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 600px;
				text-align: center;
			  "
			>
			  ${(() => {
					let name = String(
						data.company?.name
							? ["M", "S"].includes(data.company?.companyType)
								? data.profession || data.sector || ""
								: "Expositor"
							: data.profession || data.sector || "Visitante"
					);

					// const len = name.length;

					// if (len > 10) {
					// 	const names = name.split(" ");

					// 	const first = names.shift();
					// 	const last = names.pop();

					// 	if (last) {
					// 		name = `${first.substring(0, 3)}. ${last}`;
					// 	} else {
					// 		name = first.substring(0, 14);
					// 	}
					// }

					return name;
				})()}
			</div>
		  </div>
		  <img
		  	id="qr-img"
			// style="height: 250px; width: 250px"
			src="${response}"
		  />
		</div>
	  </div>`);

	document.body.appendChild(child);

	document.querySelector("#qr-img").onload = () => {
		setTimeout(() => {
			toPng(document.querySelector("#qr"), {}).then(dataUrl => {
				const pdf = new window.jspdf.jsPDF("l", "mm", [80, 50]);

				pdf.addImage(dataUrl, "png", 0, 0, 80, 50);

				if (/Android/i.test(navigator.userAgent)) {
					pdf.save(data._id + ".pdf");
				} else {
					pdf.autoPrint();

					// Creates a PDF and opens it in a new browser tab.
					const pdfURL = pdf.output("bloburl");

					const c = window.open(pdfURL, "_blank");

					document.body.removeChild(document.querySelector("#qr"));

					if (close) {
						setTimeout(() => {
							console.log("Closing PDF URL: ", close);

							if (c) c.close();
						}, 2000);
					}
				}
			});
		}, 100);
	};
}
