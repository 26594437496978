import actions from "../../actions";

const initialState = {
	className: "theme-light"
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.theme.CHANGE_THEME_TO_DARK:
			return { className: "theme-dark" };
		case actions.theme.CHANGE_THEME_TO_LIGHT:
			return { className: "theme-light" };
		default:
			return state;
	}
}
