import ls from "Localization";
import _, { isArray } from "lodash";

export function getErrors(errors) {
	const toReturn = {};
	for (const error of _.toPairs(errors)) {
		if (["_keys", "_values"].includes(error[0])) continue;

		toReturn[error[0]] = isArray(error[1])
			? error[1].map(c => ls[c] || c).join()
			: ls[error[1]] || error[1];
	}

	return toReturn;
}
