import React, { useRef, useState } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import Input from "../../../components/ReduxForm/Input";
import validate from "./validate";
import EyeIcon from "mdi-react/EyeIcon";

const changeForm = ({ handleSubmit, loading }) => {
	const form = useRef(null);
	const [showPassword, setPassword] = useState(false);

	const handleShowPassword = e => {
		e.preventDefault();
		setPassword(!showPassword);
	};

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={6} md={4} lg={3}>
						<div className="form__form-group">
							<span className="form__form-group-label">Nova Senha</span>
							<div className="form__form-group-field">
								<Field
									name="password"
									component={Input}
									type={showPassword ? "text" : "password"}
								/>
								<button
									className={`form__form-group-button${
										showPassword ? " active" : ""
									}`}
									onClick={e => handleShowPassword(e)}
									type="button"
								>
									<EyeIcon />
								</button>
							</div>
						</div>
						<div className="form__form-group form__form-group--forgot">
							<span className="form__form-group-label">Confirmar Senha</span>
							<div className="form__form-group-field">
								<Field
									name="confirmPassword"
									component={Input}
									type={showPassword ? "text" : "password"}
								/>
							</div>
						</div>
						<ButtonToolbar className="form__button-toolbar">
							<Button color="primary" type="submit" disabled={loading}>
								{loading ? (
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									/>
								) : (
									"Alterar"
								)}
							</Button>
						</ButtonToolbar>
					</Col>
				</Row>
			</Container>
		</form>
	);
};

export default reduxForm({
	form: "change_password",
	validate
})(changeForm);
