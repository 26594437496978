import validate from "containers/ProductCategory/components/validate";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { reset, SubmissionError } from "redux-form";
import CreateForm from "../../components/createForm";
import { createProductCategory } from "./actions";

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.productCategoryCreate);

	const onSubmit = values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		const data = {
			name: values.name
		};

		dispatch(
			createProductCategory(data, err => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					dispatch(reset("product_category_create"));
					history.push("/productCategory");
				}
			})
		);
	};

	const initialValues = {};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">
									Cadastrar nova categoria de produto
								</h5>
							</div>
							<CreateForm
								onSubmit={onSubmit}
								loading={loading}
								initialValues={initialValues}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
