import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Spinner,
	Button
} from "reactstrap";

import { getProduct, clearValues } from "./actions";
import { setValue } from "../Report/actions";

import DetailsProduct from "../../components/detail";
import Edit from "../Edit";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();
	const { item, loading } = useSelector(state => state.productDetails);

	const load = () => {
		if (!id) return;
		dispatch(getProduct(id));
	};

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, [dispatch]);

	const handleEditClick = () => {
		dispatch(
			setValue({
				showEditForm: true,
				item
			})
		);
	};

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do Produto</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
								<div>
									<Button onClick={handleEditClick} color="primary">
										Editar
									</Button>
								</div>
							</Row>
							<DetailsProduct product={item} loading={loading} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Edit load={load} />
		</Container>
	);
}

export default Details;
