// FormThree.js
import React, { useState } from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import validate from "./validate";
import renderSelectField from "shared/components/form/Select";
import Input from "../../../../components/ReduxForm/Input";
import userGenders from "models/userGenders";
import ls from "Localization";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import UploadFile from "components/ReduxForm/UploadFile";
import { CNPJ } from "components/NumberFormat";

const genderOptions = Object.keys(userGenders).map(c => ({
	value: c,
	label: ls[userGenders[c]]
}));

const FormThree = props => {
	const {
		handleSubmit,
		pristine,
		submitting,
		previousPage,
		onFormThreeSubmit,
		type
	} = props;

	const state = useSelector(state => state);

	const selector = formValueSelector("expositor_form");

	const loading = selector(state, "loading");

	const handleNextClick = async event => {
		event.preventDefault();
		handleSubmit(onFormThreeSubmit);
	};

	return (
		<div>
			<form className="form" onSubmit={handleNextClick}>
				<div className="form__form-group">
					{type === "provider" ? (
						<>
							<span className="form__form-group-label">Nome da empresa</span>
							<div className="form__form-group-field">
								<Field
									name="companyName"
									component={Input}
									type="text"
									placeholder="Nome da sua empresa(opcional)"
								/>
							</div>
							<br />
							<span className="form__form-group-label">CNPJ da Empresa</span>
							<div className="form__form-group-field">
								<Field
									name="companyDocument"
									component={CNPJ}
									type="text"
									placeholder="CNPJ da sua empresa(opcional)"
								/>
							</div>
							<br />
						</>
					) : (
						<>
							<span className="form__form-group-label">Profissão/Cargo</span>
							<div className="form__form-group-field">
								<Field
									name="profession"
									component={Input}
									type="text"
									placeholder="Sua profissão/cargo"
								/>
							</div>
							<br />
							<span className="form__form-group-label">Sexo</span>
							<div className="form__form-group-field">
								<Field
									name="gender"
									component={renderSelectField}
									options={genderOptions}
									type="text"
									placeholder="Selecione"
								/>
							</div>
							<br />
						</>
					)}

					{type === "builder" && (
						<>
							<span className="form__form-group-label">Documentação</span>
							<div className="form__form-group-field">
								<Field
									name="professionalFiles"
									component={UploadFile}
									type="file"
									multiple
								/>
							</div>
							<br />
							<span className="bold-text">
								*É obrigatório o envio de certificado que comprove a habilitação
								do profissional em trabalho em altura e/ou elétrica. Caso não
								haja comprovação, o profissional não poderá executar o serviço,
								além de ser penalizado com multa, conforme especificado no Termo
								de Responsabilidade, assinado pela montadora.
							</span>
							<br />
						</>
					)}
				</div>

				{/* Campo oculto para enviar o status como 'ativo' */}
				{/* <Field name="status" component="input" type="hidden" value="ativo" /> */}

				<div className="account__btn__group">
					<button
						type="button"
						className="btn btn-primary account__btn account__btn--small"
						onClick={previousPage}
					>
						Voltar
					</button>
					<button
						type="submit"
						className="btn btn-primary account__btn account__btn--small"
						disabled={pristine || submitting || loading}
					>
						{loading ? (
							<Spinner type="grow" size="sm" color="dark" />
						) : (
							"Cadastrar"
						)}
					</button>
				</div>
			</form>
		</div>
	);
};

export default reduxForm({
	form: "expositor_form",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate: v => validate(v, 3),
	initialValues: {
		status: "ativo"
	}
})(FormThree);
