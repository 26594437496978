import validate from "containers/ProductCategory/components/validate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { reset, SubmissionError } from "redux-form";
import EditForm from "../../components/editForm";
import { editProductCategory, getProductCategory } from "./actions";

function Edit({
	history,
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();

	const [initializing, setInitializing] = useState(true);

	const { loading, item } = useSelector(s => s.productCategoryEdit);

	const onSubmit = values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		const data = {
			name: values.name
		};

		dispatch(
			editProductCategory(id, data, err => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					dispatch(reset("product_category_create"));
					history.push("/productCategory");
				}
			})
		);
	};

	useEffect(() => {
		dispatch(
			getProductCategory(id, err => {
				if (err) {
					console.log(err);
				} else {
					setInitializing(false);
				}
			})
		);
	}, [id]);

	if (!item || initializing) return null;

	const initialValues = { name: item.name };

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Editar categoria de produto</h5>
							</div>
							<EditForm
								onSubmit={onSubmit}
								loading={loading}
								initialValues={initialValues}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Edit;
