import React, { useRef } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Tooltip
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import dayjs from "dayjs";
import ls from "Localization";
import Delete from "../Delete";
import Edit from "../Edit";
import { MdRemoveRedEye } from "react-icons/md";
import { toStringCurrency } from "helpers/string";
import paymentMethod from "models/paymentMethod";
import transactionStatus from "models/transactionStatus";
import DateSelectFilter from "components/Report/DateSelectorFilter";

let fetchDataDebounced = null;

function Report({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.transaction);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];

		const createdAt = filtered.find(c => c.id === "createdAt");

		if (createdAt?.value) {
			filtered = filtered.filter(c => c.id !== "createdAt");

			if (createdAt?.value.startDate) {
				filtered.push({
					id: "startDate",
					value: createdAt.value.startDate
				});
			}

			if (createdAt?.value.endDate)
				filtered.push({
					id: "endDate",
					value: createdAt.value.endDate
				});
		}

		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getTransactions(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	const handleOpenClick = item => {
		window.open(
			`${process.env.REACT_APP_PAYMENT_URL}/${item.resource}/request/${item.resourceId}`
		);
	};

	// const handleEditClick = item => {
	// 	dispatch(
	// 		actions.setValue({
	// 			showEditForm: true,
	// 			item
	// 		})
	// 	);
	// };

	// const handleDeleteClick = data => {
	// 	dispatch(
	// 		actions.setValue({
	// 			showDeleteForm: data
	// 		})
	// 	);
	// };

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Pagamentos"
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						onRowClicked={row => history.push(`/transaction/` + row._id)}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateTimeFormat)
										: "",
								width: 140,
								Filter: p => <DateSelectFilter {...p} />
							},
							{
								Header: "Cliente",
								id: "user",
								accessor: c => c.user.fullName
							},
							{
								Header: "Empresa",
								id: "company",
								accessor: c => c.company.name
							},
							{
								Header: "Método de pagamento",
								id: "paymentMethod",
								accessor: c => ls[paymentMethod[c.paymentMethod]]
							},
							{
								Header: "Valor",
								id: "value",
								accessor: c => toStringCurrency(c.value / 100),
								width: 120
							},
							{
								Header: "Status",
								id: "status",
								accessor: c => ls[transactionStatus[c.status]],
								width: 140,
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(transactionStatus).map(status => (
												<option key={status} value={status}>
													{ls[transactionStatus[status]]}
												</option>
											))}
										</select>
									);
								}
							},
							{
								Header: "Ações",
								width: 80,
								filterable: false,
								id: "actions",
								Cell: props => {
									return (
										<ButtonToolbar>
											<ButtonGroup className="btn-group--icons" dir="ltr">
												<Button
													color="primary"
													onClick={() => handleOpenClick(props.original)}
												>
													<MdRemoveRedEye style={{ margin: 0 }} />
												</Button>
												{/* <Button
													color="primary"
													onClick={() => handleEditClick(props.original)}
												>
													<MdEdit style={{ margin: 0 }} />
												</Button>

												<Button
													color="cancel"
													onClick={() => handleDeleteClick(props.original)}
												>
													<MdDelete style={{ margin: 0 }} />
												</Button> */}
											</ButtonGroup>
										</ButtonToolbar>
									);
								}
							}
						]}
					/>
				</Col>
			</Row>
			<Delete load={load} />
			<Edit load={load} />
		</Container>
	);
}

export default Report;
