import states from "assets/states.json";
import validate from "containers/Company/components/validate";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { reset, SubmissionError } from "redux-form";
import { upload } from "redux/actions/upload";
import CreateUserForm from "../../components/createForm";
import { createBuilder } from "./actions";

function Create() {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.companyCreate);

	const onSubmit = async values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			console.log(errors);
			throw new SubmissionError(errors);
		}

		const data = {
			...values,
			document: String(values.document || "")
				.match(/\d/g)
				.join(""),
			cashbackFee: String(values.cashbackFee || "0")
				.match(/\d/g)
				.join(""),
			phoneNumber: values.phoneNumber
				? `+55${values.phoneNumber.match(/\d/g).join("")}`
				: undefined,
			address: values.address
				? {
						...values.address,
						state: values.address.state.value,
						country: "BR"
				  }
				: undefined,
			companyType: "M",
			logoFile: undefined,
			bannerFile: undefined,
			catalogFile: undefined
		};

		if (values.logoFile && values.logoFile[0]) {
			try {
				data.logo = await new Promise((res, rej) =>
					dispatch(
						upload("company", values.logoFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				alert(error);

				return;
			}
		}

		if (values.bannerFile && values.bannerFile[0]) {
			try {
				data.banner = await new Promise((res, rej) =>
					dispatch(
						upload("company", values.bannerFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				alert(error);

				return;
			}
		}

		if (values.catalogFile && values.catalogFile[0]) {
			try {
				data.catalog = await new Promise((res, rej) =>
					dispatch(
						upload("company", values.catalogFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				alert(error);

				return;
			}
		}

		dispatch(
			createBuilder(data, err => {
				if (err) {
					console.log(err);
					alert(err && JSON.stringify(err));
				} else {
					dispatch(reset("builder_create"));
					location.reload();
				}
			})
		);

	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Cadastrar Montadora</h5>
							</div>
							<CreateUserForm
								onSubmit={onSubmit}
								loading={loading}
								states={states}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
