import * as api from "../../../services/api";
import Input from "components/ReduxForm/Input";
import { useSelector } from "react-redux";
import React, { useRef, useState } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "../../Builder/components/validate";

const CreateConsumptionItem = ({ handleSubmit, reset, loading, items, ...props }) => {
	const form = useRef(null);

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
				
					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">Nome</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Ex.: canhão de luz"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} className="form__form-group">
						<span className="form__form-group-label">Consumo(kva)</span>
						<div className="form__form-group-field">
							<Field
								name="consumption"
								component={Input}
								type="number"
								placeholder="Ex.: 0.01"
								min={0.01}
								step={0.01}
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "create_consumption_item",
	validate
})(CreateConsumptionItem);
