import React from "react";
import { Card, Row, Col, Container, Spinner } from "reactstrap";

const UserDetailAddress = ({ client, loading }) => {
	if (!client) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Col>
						<Row>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Rua: </strong>
									<br />
									<span>{client?.address?.street || "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Número: </strong>
									<br />
									<span>{client?.address?.number || "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Complemento: </strong>
									<br />
									<span>{client?.address?.complement || "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Bairro: </strong>
									<br />
									<span>{client?.address?.neighborhood || "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Cidade: </strong>
									<br />
									<span>{client?.address?.city || "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Estado: </strong>
									<br />
									<span>{client?.address?.state || "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>País: </strong>
									<br />
									<span>{client?.address?.country || "Não definido"}</span>
								</p>
							</Col>
							<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
								<p>
									<strong>Cep: </strong>
									<br />
									<span>{client?.address?.zipCode || "Não definido"}</span>
								</p>
							</Col>
						</Row>
					</Col>
				</Container>
			</Card>
		</div>
	);
};

export default UserDetailAddress;
