import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-trello";
import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Col,
	Row,
	Spinner
} from "reactstrap";
import { getDetailsLead, clearValues } from "./actions";
import { useState } from "react";
import Modal from "../../../../components/Modal";
import CreateBudget from "../Create";
import Report from "./ReportBudget";

import DetailsUser from "../../components/detail";
import { IoLogoWhatsapp } from "react-icons/io";
function DetailsLead({
	match: {
		params: { id }
	},
	history
}) {
	const dispatch = useDispatch();
	const { item, loading } = useSelector(state => state.leadDetails);

	const [showModal, setShowModal] = useState(false);

	const load = () => {
		dispatch(getDetailsLead(id));
	};

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, [dispatch]);

	const closeModal = () => {
		setShowModal(false);
	};

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do Lead</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>
								{item?.user?.phoneNumber && (
									<div>
										<ButtonGroup>
											<Button color="primary">
												<a
													target="_blank"
													rel="noreferrer"
													href={`https://api.whatsapp.com/send?phone=${item.user.phoneNumber}`}
													style={{ color: "white" }}
												>
													<IoLogoWhatsapp /> Contato
												</a>
											</Button>
										</ButtonGroup>
									</div>
								)}
							</Row>
							<DetailsUser
								client={item.user}
								loading={loading}
								info={item.info}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Report user={item.user} setShowModal={setShowModal} />
			<Modal
				toggle={closeModal}
				isOpen={showModal}
				size="xl"
				title="Novo orçamento"
			>
				<CreateBudget
					user={item.user}
					company={item.company}
					history={history}
				/>
			</Modal>
		</Container>
	);
}

export default DetailsLead;
