import React from "react";
import PropTypes from "prop-types";

const TextArea = ({
	input,
	// min,
	// step,
	placeholder,
	type,
	meta: { touched, error }
}) => (
	<div className="form__form-group-input-wrap">
		<textarea {...input} placeholder={placeholder} type={type} />
		{touched && error && (
			<span className="form__form-group-error">{error}</span>
		)}
	</div>
);

TextArea.propTypes = {
	textarea: PropTypes.shape().isRequired,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string
	})
};

TextArea.defaultProps = {
	placeholder: "",
	meta: null,
	type: "text"
};

export default TextArea;
