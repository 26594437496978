const validate = values => {
	const errors = {};

	const requiredFields = ["title", "description"];

	console.log(values);

	for (let field of requiredFields) {
		if (!values[field]) errors[field] = `Campo obrigatório`;
	}

	return errors;
};

export default validate;
