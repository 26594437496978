import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";

export default class TopbarMenuLinks extends PureComponent {
	static propTypes = {
		title: PropTypes.string.isRequired,
		icon: PropTypes.string.isRequired,
		path: PropTypes.string.isRequired
	};

	render() {
		const { title, icon, path, read, id, badge, ...rest } = this.props;
		// const res = read ? read.findIndex(item => item === id) >= 0 : "";
		return (
			<Link
				className={badge ? "topbar__link space-between" : "topbar__link"}
				to={path}
				{...rest}
			>
				{icon ? <span className={`topbar__link-icon lnr lnr-${icon}`} /> : ""}
				<p
					className={
						read === 1 ? "topbar__link-title" : "topbar__link-title-read"
					}
				>
					{title}
				</p>
				{badge ? (
					<span
						className={read === 1 ? "desactive" : "topbar__badge"}
						color="secondary"
					></span>
				) : (
					""
				)}
			</Link>
		);
	}
}
