import Input from "components/ReduxForm/Input";
import React, { useRef, useState } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validateConsumption";
import renderSelectField from "shared/components/form/Select";

const CreateConsumption = ({
	handleSubmit,
	reset,
	loading,
	productOptions,
	change
}) => {
	const form = useRef(null);
	const [productName, setProductName] = useState("");

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Produto</span>
						<div className="form__form-group-field">
							<Field
								name="product"
								component={renderSelectField}
								type="text"
								options={productOptions}
								placeholder="Selecione um produto"
								onChange={prod => {
									setProductName(prod.value);
									change("consumption", prod.consumption || "");
								}}
							/>
						</div>
					</Col>

					{productName === "add" && (
						<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
							<span className="form__form-group-label">Nome do Produto</span>
							<div className="form__form-group-field">
								<Field
									name="productName"
									component={Input}
									type="text"
									placeholder="Ex.: canhão de luz"
								/>
							</div>
						</Col>
					)}

					{productName && (
						<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
							<span className="form__form-group-label">Consumo(kva)</span>
							<div className="form__form-group-field">
								<Field
									name="consumption"
									component={Input}
									type="number"
									placeholder="Ex.: 0.01"
									min={0.01}
									step={0.01}
									disabled={productName !== "add"}
								/>
							</div>
						</Col>
					)}

					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Quantidade</span>
						<div className="form__form-group-field">
							<Field
								name="quantity"
								component={Input}
								type="number"
								placeholder="100"
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "create_consumption",
	validate
})(CreateConsumption);
