import validate from "containers/Product/components/validate";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { SubmissionError } from "redux-form";
import { getProductCategories } from "../../../ProductCategory/containers/Report/actions";
import CreateProductForm from "../../components/createForm";
import { createProduct, setValue, clearValues } from "./actions";

let findCategoriesDebounced = null;

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading, item } = useSelector(s => s.productCreate);

	const initialValues = useMemo(() => {
		if (!item) return {};

		return {
			code: item.code,
			name: item.name,
			description: item.description,
			costPrice: item.costPrice,
			modelProduct: item.modelProduct,
			brand: item.brand,
			manufacturer: item.manufacturer,
			origin: item.origin,
			inmetroNumber: item.inmetroNumber,
			imported: item.imported,
			customsCode: item.customsCode,
			category: item.category?.value === "add" ? "" : item.category
		};
	}, [item]);

	const onSubmit = values => {
		const errors = validate(values);
		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		const data = {
			...values,
			costPrice: values.costPrice.match(/\d/g).join(""),
			category: values.category.value,
			manufacturer: values.manufacturer
		};

		dispatch(
			createProduct(data, (err, product) => {
				if (err) {
					console.log(err);
					alert(err);
				} else {
					dispatch(clearValues());
					console.log(product._id);
					history.push("/product/" + product._id);
				}
			})
		);
	};

	const onChange = values => {
		if (values.reset) {
			dispatch(setValue({ item: {} }));
		}

		if (values.category) {
			if (values.category.value === "add") {
				dispatch(setValue({ item: values }));
				history.push("/productCategory/new");
			}
		}

		if (values.categorySearch) {
			handleFindCategory(values.categorySearch);
		}
	};

	useEffect(() => {
		handleFindCategory();
	}, [dispatch]);

	const handleFindCategory = category => {
		if (findCategoriesDebounced) {
			findCategoriesDebounced.cancel();
		}

		findCategoriesDebounced = _.debounce(
			() =>
				dispatch(
					getProductCategories(
						0,
						10,
						`&filters[name]=${category}`,
						"createdAt",
						true,
						false,
						err => {
							if (err)
								alert(
									"Não foi possível carregar as Categorias, erro: " +
										typeof err ===
										"string"
										? err
										: JSON.stringify(err)
								);
						}
					)
				),
			500
		);

		findCategoriesDebounced();
	};

	const { items: categories } = useSelector(s => s.productCategories);

	const categoriesOptions = [
		{ value: "add", label: "Adicionar nova" },
		...categories.map(c => ({
			value: c._id,
			label: c.name
		}))
	];

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Cadastrar novo produto</h5>
							</div>
							<CreateProductForm
								onSubmit={onSubmit}
								loading={loading}
								categoriesOptions={categoriesOptions}
								onChange={onChange}
								initialValues={initialValues}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
