import * as api from "services/api";
import { getErrors } from "helpers/error";

export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER = "REGISTER";

export const SET_VALUE = "[REGISTER] SET_VALUE";
export const CLEAR_VALUES = "[REGISTER] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function submitRegister(data, callback) {
	return async dispatch => {
		dispatch({
			type: REGISTER
		});

		try {
			let response = await api.sendPost("/user", data);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: REGISTER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: REGISTER_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			const errors = {
				error: "Connection error"
			};

			callback && callback(errors);

			return dispatch({
				type: REGISTER_ERROR,
				payload: errors
			});
		}
	};
}
