import React from "react";
import { Route, Switch } from "react-router-dom";
import Create from "./containers/Create";
import Edit from "./containers/Edit";
import Report from "./containers/Report";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/productCategory" component={Report} />
				<Route exact path="/productCategory/new" component={Create} />
				<Route exact path="/productCategory/:id/edit" component={Edit} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
