import states from "assets/states.json";
import validate from "containers/Company/components/validate";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { reset, SubmissionError } from "redux-form";
import { upload } from "redux/actions/upload";
import CreateUserForm from "../../components/createForm";
import { verifyUser } from "containers/User/containers/Create/actions";
import { createCompany } from "./actions";
import { setValue } from "../Report/actions";
import Edit from "../Edit";

const CnpjWarning = ({ isVisible, onClick, data }) => {
	if (!isVisible) return null;

	return (
		<div class="alert alert-warning" role="alert">
			Atenção! Este CNPJ já está cadastrado no sistema.{"  "}
			<a onClick={() => onClick(data)} href="#" class="alert-link">
				{" "}
				Clique aqui para editar.
			</a>
		</div>
	);
};

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.companyCreate);

	const [showCnpjWarning, setShowCnpjWarning] = useState(false);
	const [userData, setUserData] = useState({});

	const onSubmit = async values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			console.log(errors);
			throw new SubmissionError(errors);
		}

		const data = {
			...values,
			document: String(values.document || "")
				.match(/\d/g)
				.join(""),
			cashbackFee: String(values.cashbackFee || "0")
				.match(/\d/g)
				.join(""),
			phoneNumber: values.phoneNumber
				? `+55${values.phoneNumber.match(/\d/g).join("")}`
				: undefined,
			address: values.address
				? {
						...values.address,
						state: values.address.state.value,
						country: "BR"
				  }
				: undefined,
			companyType: "E",
			logoFile: undefined,
			bannerFile: undefined,
			catalogFile: undefined
		};

		if (values.logoFile && values.logoFile[0]) {
			try {
				data.logo = await new Promise((res, rej) =>
					dispatch(
						upload("company", values.logoFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				alert(error);

				return;
			}
		}

		if (values.bannerFile && values.bannerFile[0]) {
			try {
				data.banner = await new Promise((res, rej) =>
					dispatch(
						upload("company", values.bannerFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				alert(error);

				return;
			}
		}

		if (values.catalogFile && values.catalogFile[0]) {
			try {
				data.catalog = await new Promise((res, rej) =>
					dispatch(
						upload("company", values.catalogFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				alert(error);

				return;
			}
		}

		dispatch(
			createCompany(data, (err, client) => {
				if (err) {
					console.log(err);
					alert(err && JSON.stringify(err));
				} else {
					dispatch(reset("company_create"));
					history.push("/company/" + client._id);
				}
			})
		);
	};

	const handleVerification = event => {
		const value = event.currentTarget.value.trim();

		const isCnpj = value.length === 18;

		if (isCnpj) {
			_.debounce(() => {
				dispatch(
					verifyUser(null, null, value, (err, available, data) => {
						if (err) {
							console.log("Erro ao verificar CNPJ:", err.message);
							setShowCnpjWarning(false);
						} else if (!available) {
							setShowCnpjWarning(true);
							setUserData(data);
						} else {
							setShowCnpjWarning(false);
						}
					})
				);
			}, 800)();
		} else {
			setShowCnpjWarning(false);
		}
	};

	const handleEditClick = data => {
		dispatch(
			setValue({
				showEditForm: true,
				item: data
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CnpjWarning
							isVisible={showCnpjWarning}
							onClick={handleEditClick}
							data={userData}
						/>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Cadastrar Expositor</h5>
							</div>
							<CreateUserForm
								onSubmit={onSubmit}
								loading={loading}
								states={states}
								handleCNPJBlur={handleVerification}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Edit load={loading} />
		</Container>
	);
}

export default Create;
