const validate = values => {
	const errors = {};

	const requiredFields = ["password", "confirmPassword"];

	for (let field of requiredFields) {
		if (!values[field]) errors[field] = `Campo obrigatório`;
	}

	if (String(values["password"]) !== String(values["confirmPassword"])) {
		errors["confirmPassword"] = "As senhas não batem";
	}

	return errors;
};

export default validate;
