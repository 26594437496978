import { validateEmail } from "helpers/email";
import { CPFValidation, dateValidation } from "helpers/validators";
import { isValidPhoneNumber } from "libphonenumber-js/min";
import { store } from "../../App/App";

export const validate = values => {
	const state = store.getState();
	const { user } = state.login;

	const errors = {};
	const requiredFields = ["firstName", "lastName", "email", "role", "status"];

	if (user.role == "RECEPTIONIST") {
		requiredFields.push(...["document", "phoneNumber", "birthDate", "gender", "profession"]);
	}

	for (let field of requiredFields) {
		if (!values[field]) errors[field] = `Campo obrigatório`;
	}

	if (values.document) {
		if (!CPFValidation(String(values["document"]))) {
			errors["document"] = `Campo Cpf Inválido`;
		}
	}

	if (!validateEmail(String(values["email"]))) {
		errors["email"] = `Campo Email Inválido`;
	}

	if (values.birthDate) {
		if (!dateValidation(values["birthDate"])) {
			errors["birthDate"] = `Campo Data de Aniversário Inválido`;
		}
	}

	if (values.phoneNumber) {
		let number = values.phoneNumber?.match(/\d/g)?.join("");

		if (number.length < 10 || !isValidPhoneNumber(number, "BR")) {
			errors["phoneNumber"] = `Campo Telefone Inválido`;
		}
	}

	return errors;
};

export default validate;
