import ReportTable from "components/Report";
import dayjs from "dayjs";
import ls from "Localization";
import _ from "lodash";
import userProfessions from "models/userProfessions";
import React, { useRef } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import DateSelectFilter from "../../../../components/Report/DateSelectorFilter";
import * as actions from "./actions";
import { dateSort } from "components/Report/helpers";

let fetchDataDebounced = null;

function Report({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.lead);
	const { user } = useSelector(s => s.login);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];

		const createdAt = filtered.find(c => c.id === "createdAt");

		if (createdAt?.value) {
			filtered = filtered.filter(c => c.id !== "createdAt");

			if (createdAt?.value.startDate) {
				filtered.push({
					id: "startDate",
					value: createdAt.value.startDate
				});
			}

			if (createdAt?.value.endDate)
				filtered.push({
					id: "endDate",
					value: createdAt.value.endDate
				});
		}

		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getLead(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const columns = useMemo(() => {
		return [
			{
				Header: "Data de Criação",
				id: "createdAt",
				accessor: c =>
					c.createdAt ? dayjs(c.createdAt).format(ls.dateTimeFormat) : "",
				show: true,
				sortMethod: dateSort,
				width: 150,
				Filter: p => <DateSelectFilter {...p} />
			},
			{
				Header: "Nome",
				id: "name",
				accessor: c => c.user?.fullName
			},
			{
				Header: "E-mail",
				id: "email",
				accessor: c => c.user?.email,
				width: 200
			},
			{
				Header: "Profissão",
				id: "profession",
				accessor: c =>
					userProfessions[c.user?.profession] || c.user?.profession,
				width: 200
			},
			{
				Header: "Empresa",
				id: "companyName",
				accessor: c => c.user?.companyName,
				width: 200
			},
			{
				Header: "Celular",
				id: "phoneNumber",
				accessor: c =>
					c.user?.nationalPhoneNumber
						? c.user.nationalPhoneNumber
						: "Não definido",
				width: 120
			},
			{
				Header: "Cidade/UF",
				id: "city",
				accessor: c =>
					c.user?.address?.city
						? `${c.user.address.city.trim()}/${c.user.address.state.trim()}`
						: "",
				width: 200
			},
			{
				Header: "Informações",
				id: "info",
				accessor: c => c.info
			},
			user.role?.toLowerCase() !== "user"
				? {
						Header: "Criado por",
						id: "createdBy",
						accessor: c => c.createdBy?.fullName || "Não definido",
						width: 120
				  }
				: null,
			user.role?.toLowerCase() === "admin"
				? {
						Header: "Empresa",
						id: "company",
						accessor: c => c.company?.name || "Não definido",
						width: 120
				  }
				: null
		].filter(c => c);
	}, [user]);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title="Leads"
						tableRef={table}
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable={false}
						onRowClicked={row => history.push(`/lead/` + row._id)}
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={columns}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Report;
