import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import Input from "../../../../components/ReduxForm/Input";

const FormOne = props => {
	const {
		handleSubmit,
		pristine,
		submitting,
		valid,
		onFormOneSubmit,
		reset
	} = props;

	const handleNextClick = e => {
		e.preventDefault()

		if (!valid) {
			return;
		}

		handleSubmit(onFormOneSubmit);
	};

	return (
		<form className="form" onSubmit={handleNextClick}>
			<div className="form__form-group">
				<span className="form__form-group-label">Nome</span>
				<div className="form__form-group-field">
					<Field
						name="name"
						type="text"
						placeholder="Seu Nome"
						autoFocus={true}
						component={Input}
						key="firstName"
					/>
				</div>
				<br />
				<span className="form__form-group-label">Sobrenome</span>
				<div className="form__form-group-field">
					<Field
						name="lastname"
						type="text"
						placeholder="Seu Sobrenome"
						component={Input}
						key="lastName"
					/>
				</div>
				<br />
				<span className="form__form-group-label">E-mail</span>
				<div className="form__form-group-field">
					<Field
						name="email"
						type="email"
						placeholder="Seu e-mail"
						component={Input}
						key="email"
					/>
				</div>
			</div>
			<div className="account__btn__group">
				<button
					type="button"
					className="btn btn-primary account__btn account__btn--small"
					onClick={reset}
				>
					Limpar
				</button>
				<button
					type="submit"
					className="btn btn-primary account__btn account__btn--small"
					disabled={pristine || submitting}
				>
					Próximo
				</button>
			</div>
		</form>
	);
};

export default reduxForm({
	form: "expositor_form",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate: (v) => validate(v, 1)
})(FormOne);
