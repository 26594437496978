import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, CardBody, CardTitle, CardText, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PlayerProvider, { PlayerContext } from "./PlayerProvider";
import { useLocation } from "react-router-dom";
import * as actions from "../Report/actions";
import _ from "lodash";
import ReactPlayer from "react-player";

function VideoCard({ video }) {
	const { play, pause, isPlaying, playing } = useContext(PlayerContext);
	const videoRef = useRef(null);

	useEffect(() => {
		if (videoRef.current) {
			const player = videoRef.current.getInternalPlayer();
			if (player) {
				if (isPlaying(video._id)) {
					player.playVideo && player.playVideo();
				} else {
					player.pauseVideo && player.pauseVideo();
				}
			}
		}
	}, [video._id, videoRef.current, playing]);

	return (
		<div style={{}}>
			<ReactPlayer
				ref={videoRef}
				url={video.link}
				className="react-player"
				width="500px"
				height="380px"
				controls
				playing={isPlaying(video._id)}
				onPlay={() => play(video._id)}
				// onPause={() => pause(video._id)}
				onEnded={() => pause(video._id)}
			/>
			<CardBody style={{}}>
				{" "}
				<CardTitle
					tag="h5"
					className="mb-2"
					style={{ fontWeight: "bold", fontSize: "18px" }}
				>
					{video.title}
				</CardTitle>
				<CardText>{video.description}</CardText>
			</CardBody>
		</div>
	);
}

function ShowVideo({ history }) {
	const dispatch = useDispatch();
	const players = useRef(null);
	const location = useLocation();

	const state = useSelector(s => s.video);

	const handleFetchData = () => {
		dispatch(actions.getVideos(0, 10, "", "createdAt", false, false, null));
	};

	useEffect(() => {
		handleFetchData();
	}, [dispatch]);

	const filteredVideos = state.items.filter(video => {
		if (location.pathname === "/cursos/user/sales") {
			return video.status === 1; // Vídeos de 'Sales'
		} else {
			return video.status === 0; // Vídeos de 'App'
		}
	});

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<img
						src={require("../../../../assets/02CF77A44F8C050B3CF2700AF3420389.jpeg")}
						alt="Palestra"
						style={{ width: "100%", height: "auto" }}
					/>
				</Col>
			</Row>
			<Row>
				<PlayerProvider>
					{filteredVideos.map(video => (
						<Col
							style={{ marginBottom: "20px" }}
							md={8}
							lg={6}
							xl={4}
							className="d-flex align-items-center"
							key={video._id}
						>
							<VideoCard video={video} />
						</Col>
					))}
				</PlayerProvider>
			</Row>
		</Container>
	);
}

export default ShowVideo;
