import React, { useState, useEffect, useCallback } from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import _ from "lodash";
import Input from "components/ReduxForm/Input";

const ReaderPage = props => {
	const { handleSubmit, pristine, previousPage, submitting, ref } = props;

	return (
		<form className="form" ref={ref} onSubmit={handleSubmit}>
			<div className="form__form-group">
				<span className="form__form-group-label">
					Leia o código de barras ou QR Code
				</span>
				<div className="form__form-group-field">
					<Field
						name="result"
						type="text"
						autoFocus={true}
						component={Input}
						autoComplete="off"
						onBlur={() => {
							setTimeout(() => {
								document.getElementsByName("result")[0]?.focus();
							}, 1000);
						}}
					/>
				</div>
			</div>
			<div className="account__btn__group">
				<button
					type="button"
					className="btn btn-primary account__btn account__btn--small"
					onClick={previousPage}
				>
					Voltar
				</button>
				<button
					type="submit"
					className="btn btn-primary account__btn account__btn--small"
					disabled={pristine || submitting}
				>
					Enviar
				</button>
			</div>
		</form>
	);
};

export default reduxForm({
	form: "self_service_form",
	validate
})(ReaderPage);
