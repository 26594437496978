import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row, Spinner } from "reactstrap";

const CashbackDetail = ({ cashbackDetails, loading, isAdmin }) => {
	if (!cashbackDetails) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{cashbackDetails.createdAt
												? dayjs(cashbackDetails.createdAt).format(
														ls.dateTimeFormat
												  )
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Descrição: </strong>
										<br />
										<span>
											{cashbackDetails.description
												? cashbackDetails.description
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Valor: </strong>
										<br />
										<span>
											{cashbackDetails.value
												? toStringCurrency(cashbackDetails.value / 100.0)
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Lead: </strong>
										<br />
										<span>
											{cashbackDetails.user ? (
												<Link to={`/user/${cashbackDetails.user._id}`}>
													{cashbackDetails.user.fullName}
												</Link>
											) : (
												"Não definido"
											)}
										</span>
									</p>
								</Col>
								{isAdmin && (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>Expositor: </strong>
											<br />
											<span>
												{cashbackDetails.company ? (
													<Link to={`/company/${cashbackDetails.company._id}`}>
														{cashbackDetails.company.name}
													</Link>
												) : (
													"Não definido"
												)}
											</span>
										</p>
									</Col>
								)}
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Vendedor: </strong>
										<br />
										<span>
											{cashbackDetails.createdBy ? (
												<Link to={`/user/${cashbackDetails.createdBy._id}`}>
													{cashbackDetails.createdBy.fullName}
												</Link>
											) : (
												"Não definido"
											)}
										</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default CashbackDetail;
