import React, { useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../ReportAttendeeEvent/actions";
import _ from "lodash";
import dayjs from "dayjs";
import ls from "Localization";
import { useHistory } from "react-router";
import { useMemo } from "react";
import eventAttendeeStatus from "../../../../../models/eventAttendeeStatus";

let fetchDataDebounced = null;

function Report({ id }) {
	const dispatch = useDispatch();
	const table = useRef(null);
	const history = useHistory();

	const state = useSelector(s => s.attendeeEvent);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		filtered = [...filtered];

		filtered.push({ id: "event", value: id });

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getAttendeeEvent(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	// const load = () =>
	// 	handleFetchData(table.current ? table.current.state : undefined);

	// const handleEditClick = item => {
	// 	dispatch(
	// 		actions.setValue({
	// 			showEditForm: true,
	// 			item
	// 		})
	// 	);
	// };

	// const handleDeleteClick = item => {
	// 	dispatch(
	// 		actions.setValue({
	// 			showDeleteForm: item
	// 		})
	// 	);
	// };

	// const handleShowModal = () => {
	// 	setShowModal(true);
	// };

	const columns = useMemo(() => {
		return [
			{
				Header: "Data criação",
				id: "createdAt",
				accessor: c =>
					c.createdAt ? dayjs(c.createdAt).format(ls.dateTimeFormat) : "",
				width: 210
			},
			{
				Header: "Usuário",
				id: "user",
				accessor: c => c.user?.fullName
			},
			{
				Header: "E-mail",
				id: "email",
				accessor: c => c.user?.email || "Não informado"
			},
			{
				Header: "Telefone",
				id: "phone",
				accessor: c => c.user?.nationalPhoneNumber || "Não informado"
			},
			{
				Header: "Profissão",
				id: "profession",
				accessor: c => c.user?.profession || "Não informado"
			},
			{
				Header: "Check in",
				id: "checkInDate",
				filterable: false,
				accessor: c =>
					c.checkInDate
						? dayjs(c.checkInDate).format(ls.dateTimeFormat)
						: "Não definido",
				width: 150
			},
			{
				Header: "Status",
				id: "status",
				accessor: c => ls[eventAttendeeStatus[c.status]],
				width: 100,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(eventAttendeeStatus).map(c => (
							<option key={c} value={c}>
								{ls[eventAttendeeStatus[c]]}
							</option>
						))}
					</select>
				)
			}
			// {
			// 	Header: "Ações",
			// 	width: 80,
			// 	filterable: false,
			// 	id: "actions",
			// 	Cell: props => {
			// 		return (
			// 			<ButtonToolbar>
			// 				<ButtonGroup className="btn-group--icons" dir="ltr">
			// 					<Button
			// 						color="primary"
			// 						onClick={() => handleEditClick(props.original)}
			// 					>
			// 						<MdEdit style={{ margin: 0 }} />
			// 					</Button>

			// 					<Button
			// 						color="cancel"
			// 						onClick={() => handleDeleteClick(props.original)}
			// 					>
			// 						<MdDelete style={{ margin: 0 }} />
			// 					</Button>
			// 				</ButtonGroup>
			// 			</ButtonToolbar>
			// 		);
			// 	}
			// }
		];
	});

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Participantes"
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						onRowClicked={row => history.push(`/user/` + row._id)}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={columns}
					/>
				</Col>
			</Row>
			{/* <Delete load={load} />
			<Edit load={load} /> */}
		</Container>
	);
}

export default Report;
