import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import _ from "lodash";

import { editProduct, setValue } from "../Report/actions";

import Modal from "components/Modal";
import validate from "containers/Product/components/validate";
import { getProductCategories } from "containers/ProductCategory/containers/Report/actions";
import EditForm from "../../components/editForm";

let findPoliciesDebounced = null;
let findCategoriesDebounced = null;

function Edit({ load }) {
	const dispatch = useDispatch();
	const { showEditForm, item } = useSelector(s => s.products);
	const { items: categories } = useSelector(s => s.productCategories);

	const handleFindCategory = category => {
		if (findCategoriesDebounced) {
			findCategoriesDebounced.cancel();
		}
		if (category) {
			findCategoriesDebounced = _.debounce(
				() =>
					dispatch(
						getProductCategories(
							0,
							10,
							`&filters[name]=${category}`,
							"createdAt",
							true,
							false,
							err => {
								if (err)
									alert(
										"Não foi possível carregar as Categorias, erro: " +
											typeof err ===
											"string"
											? err
											: JSON.stringify(err)
									);
							}
						)
					),
				500
			);

			findCategoriesDebounced();
		} else {
			dispatch(getProductCategories());
		}
	};

	useEffect(() => {
		handleFindCategory();
	}, [dispatch]);

	if (!item) return null;

	const onSubmit = values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			console.log(errors);
			throw new SubmissionError(errors);
		}

		const data = {
			...values,
			costPrice:
				typeof values.costPrice === "string"
					? values.costPrice.match(/\d/g).join("")
					: values.costPrice * 100.0,
			category: values.category.value,
			manufacturer: values.manufacturer
		};

		dispatch(
			editProduct(item._id, data, err => {
				if (err) {
					console.log(err);
				} else {
					load && load();
					closeModal();
				}
			})
		);
	};

	const closeModal = () => {
		dispatch(setValue({ showEditForm: false }));
	};

	const onChange = values => {
		if (values.category) {
			if (values.category.value === "add") history.push("/productCategory/new");
		}

		if (values.categorySearch) {
			handleFindCategory(values.categorySearch);
		}
	};

	const categoriesOptions = [
		{ value: "add", label: "Adicionar nova" },
		...categories.map(c => ({
			value: c._id,
			label: c.name
		}))
	];

	const initialValues = {
		code: item.code,
		name: item.name,
		description: item.description,
		costPrice: item.costPrice / 100,
		modelProduct: item.modelProduct,
		brand: item.brand,
		manufacturer: item.manufacturer,
		origin: item.origin,
		inmetroNumber: item.inmetroNumber,
		imported: item.imported,
		customsCode: item.customsCode,
		category: categoriesOptions.find(c => c.value === item.category._id)
	};

	return (
		<Modal
			toggle={closeModal}
			isOpen={showEditForm}
			size="xl"
			title="Editar Produto"
		>
			<EditForm
				closeModal={closeModal}
				initialValues={initialValues}
				onSubmit={onSubmit}
				categoriesOptions={categoriesOptions}
				onChange={onChange}
			/>
		</Modal>
	);
}

export default Edit;
