/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Board from "react-trello";
import { useDispatch, useSelector } from "react-redux";
import { editlead, getLead, setValue } from "../Report/actions";
import leadStatus from "models/leadStatus";
import ls from "Localization";
import * as actions from "./actions";
import { useHistory } from "react-router-dom";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import dayjs from "dayjs";
import { getExcelData } from "components/Report/export";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import TooltipComponent from "components/TooltipComponent";
import { Button, ButtonGroup, ButtonToolbar } from "reactstrap";
import { FaRegFileExcel } from "react-icons/fa";
import userProfessions from "models/userProfessions";

function Lead({ userId }) {
	const dispatch = useDispatch();

	const history = useHistory();

	const [exportComponent, setExport] = useState(null);

	const { items } = useSelector(s => s.lead);
	const { user } = useSelector(s => s.login);
	const { items: lanes } = useSelector(s => s.lane);

	useEffect(() => {
		const filtered = [];
		if (userId) {
			filtered.push({ id: "createdBy", value: userId });
		}

		dispatch(
			getLead(
				0,
				10000,
				filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
				null,
				null,
				false
			)
		);
	}, [userId]);

	useEffect(() => {
		dispatch(actions.getLane(10000, null, false));
	}, []);

	const handleLeadsExport = useCallback(() => {
		dispatch(
			getLead(0, 1000, null, null, null, false, (err, data) => {
				if (err) {
					console.log(err);
				} else {
					const dataSet = getExcelData(
						[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateTimeFormat)
										: "",
								width: 120
							},
							{
								Header: "Nome",
								id: "name",
								accessor: c => c.user?.fullName
							},
							{
								Header: "E-mail",
								id: "email",
								accessor: c => c.user?.email,
								width: 200
							},
							{
								Header: "Profissão",
								id: "profession",
								accessor: c => userProfessions[c.user?.profession],
								width: 200
							},
							{
								Header: "Celular",
								id: "phoneNumber",
								accessor: c =>
									c.user?.nationalPhoneNumber
										? c.user.nationalPhoneNumber
										: "Não definido",
								width: 120
							},
							{
								Header: "Informações",
								id: "info",
								accessor: c => c.info
							},
							user.role?.toLowerCase() !== "user"
								? {
										Header: "Criado por",
										id: "createdBy",
										accessor: c => c.createdBy?.fullName || "Não definido",
										width: 120
								  }
								: null,
							user.role?.toLowerCase() === "admin"
								? {
										Header: "Empresa",
										id: "company",
										accessor: c => c.company?.name || "Não definido",
										width: 120
								  }
								: null
						].filter(c => c),
						data
					);

					if (dataSet)
						setExport(
							<ExcelFile
								filename={"Leads" + "_" + dayjs().format("YYYY-MM-DD-HH-mm-ss")}
								hideElement
							>
								<ExcelSheet dataSet={dataSet} name="Leads" />
							</ExcelFile>
						);

					setTimeout(() => {
						setExport(null);
					}, 1000);
				}
			})
		);
	}, []);

	const dataLead = useMemo(() => {
		const data = {
			lanes: []
		};

		for (let lane of lanes) {
			const statusItems = items.filter(
				c => String(c.status) === String(lane.position)
			);

			data.lanes.push({
				id: lane._id,
				title: ls[lane.title] || lane.title,
				label: String(statusItems.length),
				cards: statusItems.map(lead => ({
					id: lead._id,
					title: lead.user?.fullName || "Não definido",
					description:
						lead.info?.substr(0, 256) || lead.user?.email || "Não definido"
				}))
			});
		}

		return data;
	}, [lanes, items]);

	const handleDragEnd = useCallback(
		(cardId, sourceLaneId, targetLaneId) => {
			const lane = lanes.find(c => c._id === targetLaneId);

			dispatch(
				editlead(
					cardId,
					{
						status: lane.position
					},
					error => {
						if (error) {
							console.log(error);
						} else {
							const item = items.find(c => c._id === cardId);

							item.status = lane.position;

							dispatch(
								setValue({
									items: [...items]
								})
							);
						}
					}
				)
			);
		},
		[items]
	);

	const onLaneUpdate = useCallback(
		(laneId, data) => {
			dispatch(
				actions.editlane(laneId, data, error => {
					if (error) {
						console.log(error);
					} else {
						const lane = lanes.find(c => c._id === laneId);

						lane.title = data;

						dispatch(
							setValue({
								lanes: [...lanes]
							})
						);
					}
				})
			);
		},
		[items]
	);

	const onCardClick = (cardId, metadata, laneId) => {
		history.push(`/lead/` + cardId);
	};

	return (
		<>
			<div
				className="card__title"
				style={{
					marginBottom: 0,
					padding: 16,
					justifyContent: "space-between"
				}}
			>
				<h1>Leads</h1>
				<ButtonToolbar>
					<ButtonGroup className="btn-group--icons" dir="ltr">
						<Button
							color="primary"
							id="users-qr-export"
							onClick={handleLeadsExport}
							size="md"
						>
							<FaRegFileExcel />
						</Button>
						<TooltipComponent title="Exportar leads" target="users-qr-export" />
					</ButtonGroup>
				</ButtonToolbar>
			</div>
			<Board
				data={dataLead}
				draggable
				style={{ background: "#F2F4F7" }}
				handleDragEnd={handleDragEnd}
				laneDraggable={false}
				onCardClick={onCardClick}
				editLaneTitle
				onLaneUpdate={onLaneUpdate}
			/>
			{exportComponent}
		</>
	);
}

export default Lead;
