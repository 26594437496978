import React, { useEffect, useState } from "react";
import { Button, Row } from "reactstrap";
import Image1 from "../../../../../assets/marketing/01.png";
import Image2 from "../../../../../assets/marketing/02.png";
import Image3 from "../../../../../assets/marketing/03.png";
import Image4 from "../../../../../assets/marketing/04.png";
import Image5 from "../../../../../assets/marketing/05.png";

const BuilderMarketing = () => {
	const handleContact = () => {
		window.open(
			"https://api.whatsapp.com/send?phone=5547997066210&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20feita%20Construir%20Ai.",
			"_blank"
		);
	};

	return (
		<Row>
			<>
				{/* <Button color="primary" onClick={handleContact}>
					Requisitar cotação
				</Button> */}
				<div style={{ position: "relative", width: "100%", marginTop: -40 }}>
					<img src={Image1} alt="image1" />
					<img src={Image2} alt="image2" />
					<img src={Image3} alt="image3" />
					<img src={Image4} alt="image4" />
					<img src={Image5} alt="image5" />
					<Button
						style={{
							position: "absolute",
							bottom: -30,
							left: 0,
							right: 0,
							padding: "18px 100px", // espaço interno do botão
							fontSize: "1.2em" // tamanho da fonte no botão
						}}
						color="primary"
						onClick={handleContact}
					>
						Entrar em Contato
					</Button>
				</div>
			</>
		</Row>
	);
};

export default BuilderMarketing;
