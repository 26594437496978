import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_PRODUCT_CATEGORY =
	"[PRODUCT_CATEGORY DETAIL] GET_PRODUCT_CATEGORY";
export const GET_PRODUCT_CATEGORY_ERROR =
	"[PRODUCT_CATEGORY DETAIL] GET_PRODUCT_CATEGORY_ERROR";
export const GET_PRODUCT_CATEGORY_SUCCESS =
	"[PRODUCT_CATEGORY DETAIL] GET_PRODUCT_CATEGORY_SUCCESS";

export const EDIT_PRODUCT_CATEGORY =
	"[PRODUCT_CATEGORY EDIT] EDIT_PRODUCT_CATEGORY";
export const EDIT_PRODUCT_CATEGORY_ERROR =
	"[PRODUCT_CATEGORY EDIT] EDIT_PRODUCT_CATEGORY_ERROR";
export const EDIT_PRODUCT_CATEGORY_SUCCESS =
	"[PRODUCT_CATEGORY EDIT] EDIT_PRODUCT_CATEGORY_SUCCESS";

export const SET_VALUE = "[PRODUCT_CATEGORY EDIT] SET_VALUE";
export const CLEAR_VALUES = "[PRODUCT_CATEGORY EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getProductCategory(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PRODUCT_CATEGORY
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/productCategory/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PRODUCT_CATEGORY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_PRODUCT_CATEGORY_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_PRODUCT_CATEGORY_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function editProductCategory(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_PRODUCT_CATEGORY
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/productCategory/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_PRODUCT_CATEGORY_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				dispatch({
					type: EDIT_PRODUCT_CATEGORY_ERROR,
					payload: errors
				});
			}
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: EDIT_PRODUCT_CATEGORY_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
