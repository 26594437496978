/* eslint-disable prettier/prettier */
import React from "react";
import { Switch, Route } from "react-router-dom";
import Report from "./containers/Report";
import Create from "./containers/Create";
import Detail from "./containers/Detail";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/news" component={Report} />
				<Route exact path="/news/new" component={Create} />
				<Route exact path="/news/:id" component={Detail} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
