import React, { useRef } from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import Input from "../../../../components/ReduxForm/Input";
import UploadFile from "components/ReduxForm/UploadFile";
import renderDateTimePickerField from "shared/components/form/DateTimePicker";

import validate from "../validate";
import TextArea from "components/ReduxForm/TextArea";
import renderSelectField from "shared/components/form/Select";

const editForm = ({ handleSubmit, reset, load, typeOptions }) => {
	const form = useRef(null);

	return (
		<form ref={form} className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} md={8} className="form__form-group">
						<span className="form__form-group-label">Titulo</span>
						<div className="form__form-group-field">
							<Field
								name="title"
								component={Input}
								type="text"
								placeholder="Ex.: Reunião da empresa"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={2} className="form__form-group">
						<span className="form__form-group-label">Data de inicio</span>
						<div className="form__form-group-field">
							<Field
								name="when"
								component={renderDateTimePickerField}
								type="text"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={2} className="form__form-group">
						<span className="form__form-group-label">Data de termino</span>
						<div className="form__form-group-field">
							<Field
								name="until"
								component={renderDateTimePickerField}
								type="text"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Tipo do evento*</span>
						<div className="form__form-group-field">
							<Field
								name="type"
								component={renderSelectField}
								type="text"
								options={typeOptions}
								placeholder="Selecione uma opção"
								required
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={2} className="form__form-group">
						<span className="form__form-group-label">Capacidade</span>
						<div className="form__form-group-field">
							<Field name="capacity" component={Input} type="number" />
						</div>
					</Col>

					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field
								name="description"
								component={TextArea}
								placeholder="Ex.: uma pequena reunião pra falar sobre..."
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} className="form__form-group">
						<span className="form__form-group-label">Banner</span>
						<div className="form__form-group-field">
							<Field
								name="bannerFile"
								component={UploadFile}
								accept="image/png,image/jpeg"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} className="form__form-group">
						<span className="form__form-group-label">Video</span>
						<div className="form__form-group-field">
							<Field
								name="video"
								component={Input}
								type="url"
								placeholder="Ex.: https://videodoyoutube"
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={load && load.edit}>
					{load && load.edit ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "edit_event",
	validate
})(editForm);
