import React, { /*useCallback*/ useRef } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import dayjs from "dayjs";
import ls from "Localization";
import { MdDelete, MdEdit, MdAdd } from "react-icons/md";
import TooltipComponent from "components/TooltipComponent";
import budgetStatus from "models/budgetStatus";
import { useHistory } from "react-router";
import Delete from "containers/Budgets/containers/Delete";
import Edit from "containers/Budgets/containers/Edit";
import { toStringCurrency } from "helpers/string";

let fetchDataDebounced = null;

function Report({ setShowModal }) {
	const dispatch = useDispatch();
	const table = useRef(null);
	const history = useHistory();

	const state = useSelector(s => s.budget);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getBudget(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	const handleEditClick = item => {
		dispatch(
			actions.setValue({
				showEditForm: true,
				item
			})
		);
	};

	const handleDeleteClick = data => {
		dispatch(
			actions.setValue({
				showDeleteForm: data
			})
		);
	};

	const handleShowModal = () => {
		setShowModal(true);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Produtos"
						data={state.products || []}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						onRowClicked={row => history.push(`/budget/` + row._id)}
						headerRightComponent={
							<>
								{history.location.pathname === "/budget" ? (
									""
								) : (
									<>
										<ButtonToolbar>
											<ButtonGroup className="btn-group--icons" dir="ltr">
												<Button
													color="primary"
													id="product-import"
													onClick={handleShowModal}
													size="md"
												>
													<MdAdd style={{ margin: 0 }} />
												</Button>
												<TooltipComponent
													title="Novo orçamento"
													target="product-import"
												/>
											</ButtonGroup>
										</ButtonToolbar>
									</>
								)}
							</>
						}
						columns={[
							{
								Header: "Nome",
								id: "name",
								accessor: "c.product.name"
							},

							{
								Header: "Descrição",
								accessor: "c.product.description"
							},

							{
								Header: "Valor",
								id: "value",
								accessor: c => toStringCurrency(c.value / 100.0)
							},
							{
								Header: "Ações",
								width: 80,
								filterable: false,
								id: "actions",
								Cell: props => {
									return (
										<ButtonToolbar>
											<ButtonGroup className="btn-group--icons" dir="ltr">
												<Button
													color="primary"
													onClick={() => handleEditClick(props.original)}
												>
													<MdEdit style={{ margin: 0 }} />
												</Button>

												<Button
													color="cancel"
													onClick={() => handleDeleteClick(props.original)}
												>
													<MdDelete style={{ margin: 0 }} />
												</Button>
											</ButtonGroup>
										</ButtonToolbar>
									);
								}
							}
						]}
					/>
				</Col>
			</Row>
			<Delete load={load} />
			<Edit load={load} />
		</Container>
	);
}

export default Report;
