import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import validate from "containers/Company/components/validate";
import { useDispatch, useSelector } from "react-redux";
import ResponsibilityForm from "../../components/responsibilityForm";
import { upload } from "redux/actions/upload";
import { reset } from "redux-form";
import { editClient } from "../Report/actions";
import { getUserData } from "redux/actions/login";

const Responsibility = ({ history }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const { user } = useSelector(s => s.login);

	useEffect(() => {
		dispatch(getUserData());
	}, []);

	const onSubmit = async values => {
		setLoading(true);
		const errors = validate(values);

		// if (Object.keys(errors).length > 0) {
		// 	throw new SubmissionError(errors);
		// }

		const data = {
			...user.company,
			responsibilityFile: undefined
		};

		if (values.responsibilityFile && values.responsibilityFile.length > 0) {
			try {
				data.responsibilityFile = await new Promise((res, rej) =>
					dispatch(
						upload("company", values.responsibilityFile[0], (erro, url) => {
							if (erro) {
								rej(erro);
							} else {
								res(url);
							}
						})
					)
				);
			} catch (error) {
				console.log(error);

				alert(error);

				return;
			}
		}

		const id = user.company._id;

		dispatch(
			editClient(id, data, (err, model) => {
				if (err) {
					console.log(err);
					alert(err && JSON.stringify(err));
				} else {
					setLoading(false);
					dispatch(reset("edit_responsibility"));

					dispatch(getUserData(() => {}));
					alert("Termo de responsabilidade enviado com sucesso!");
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={11}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Termo de Responsabilidade</h5>
							</div>
							<ResponsibilityForm
								onSubmit={onSubmit}
								reset={reset}
								loading={loading}
								responsibilityFile={user.company.responsibilityFile}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Responsibility;
